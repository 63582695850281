import RootPlanning from '@/views/planning/RootPlanning'

export default [
  {
    path: '/planning',
    name: 'Planning',
    component: RootPlanning,
    meta: {
      requiresAuth: true,
      access: () => true
    }
  }
]
