<template>
  <ul class="name-filter flex flex-col items-center overflow-x-hidden">
    <li
      v-for="letter in alphabet"
      :key="letter"
      class="flex-center rounded-full transition-all duration-100"
      :class="{
        'text-dirt': !clickable.includes(letter),
        'hover:bg-snowball cursor-pointer': clickable.includes(letter)
      }"
      @click="letterClick(letter)"
    >
      {{ letter }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'LetterFilter',
  props: {
    clickable: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: () => ({
    alphabet: [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z'
    ]
  }),
  methods: {
    letterClick(letter) {
      this.$emit('letterClick', letter)
    }
  }
}
</script>

<style lang="scss">
.name-filter li {
  min-width: 1.5rem;
  min-height: 1.5rem;
}
</style>
