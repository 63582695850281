<template>
  <ValidationObserver
    ref="form"
    tag="form"
    v-slot="{ invalid }"
    autocomplete="off"
    @submit.prevent="formSubmit"
  >
    <Modal
      :title="$t('modals.time_range.title')"
      :desc="$t('modals.time_range.desc')"
      icon="clock"
    >
      <div
        class="flex justify-center md:justify-start items-center space-x-2 mt-5"
      >
        <h2 class="title-2 text-mine text-center md:text-left">
          {{ $t('modals.time_range.from').toLowerCase() }}
        </h2>

        <Input
          v-model="timeRanges[0].start"
          :placeholder="$t('modals.time_range.from_placeholder')"
          name="start"
          mode="large"
          mask="##:##"
          rules="required|length:5"
          :full-width="false"
          :center="true"
          @input="onStartInput"
        />

        <h2 class="title-2 text-mine text-center md:text-left">
          {{ $t('modals.time_range.to').toLowerCase() }}
        </h2>

        <Input
          v-model="timeRanges[0].end"
          :placeholder="$t('modals.time_range.to_placeholder')"
          name="end"
          mode="large"
          mask="##:##"
          rules="required|length:5"
          :full-width="false"
          :center="true"
          @keyup.native="onEndKeyup"
        />
      </div>

      <p v-if="error && submitted" class="text-opal text-15 mt-2">
        {{ error }}
      </p>

      <template #buttons>
        <!-- Submit -->
        <Button
          class="py-3 w-full sm:w-auto rounded-md ml-0 sm:ml-3"
          pill
          upper
          :disabled="invalid"
          :loading="loading"
          type="submit"
        >
          {{ $t('modals.time_range.confirm') }}
        </Button>

        <!-- Cancel -->
        <Button
          class="px-4 py-3 w-full sm:w-auto rounded-md mt-3 sm:mt-0"
          pill
          upper
          text="tire"
          bg="snow"
          hover="ice"
          @click="$emit('close')"
        >
          {{ $t('modals.time_range.cancel') }}
        </Button>
      </template>
    </Modal>
  </ValidationObserver>
</template>

<script>
import Modal from '@/components/Modal'
import Input from '@/components/Input'
import { mapState } from 'vuex'

export default {
  name: 'TimeRangeModal',
  components: {
    Modal,
    Input
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    submitted: false,
    error: '',
    timeRanges: [
      {
        start: '',
        end: ''
      }
    ]
  }),
  computed: {
    ...mapState('Breakpoints', ['isMobile'])
  },
  mounted() {
    const start = document.getElementById('start')
    const end = document.getElementById('end')

    start.setAttribute('size', start.placeholder.length)
    end.setAttribute('size', end.placeholder.length)
  },
  methods: {
    onStartInput(e) {
      if (e.length === 5) {
        document.getElementById('end').focus()
      }
    },
    onEndKeyup(e) {
      if ((e.key === 'Backspace' || e.key === 'Delete') && !e.target.value) {
        document.getElementById('start').focus()
      }
    },
    formSubmit() {
      this.submitted = true

      this.$refs.form.validate().then(success => {
        if (success) {
          this.$emit('confirm', { timeRanges: this.timeRanges })
        } else {
          this.error = this.$t('modals.time_range.fill_in_all_fields')
        }
      })
    }
  }
}
</script>
