<template>
  <div class="p-6 pt-14">
    <h2 class="title-2 mb-6">{{ $t('pages.templates.add_new.title') }}</h2>
    <p>{{ $t('pages.templates.add_new.desc') }}</p>

    <ValidationObserver
      ref="form"
      tag="form"
      v-slot="{ invalid }"
      @submit.prevent="addNewTemplate"
    >
      <Input
        v-model="newTemplate"
        class="mt-5"
        name="template"
        rules="required"
        :submitted="submitted"
        :placeholder="$t('pages.work_areas.add_new.input.placeholder')"
      />

      <Button
        class="py-2 mt-6"
        :class="invalid ? 'px-3' : 'px-4'"
        pill
        upper
        :loading="loading"
        :disabled="invalid"
        type="submit"
      >
        {{ $t('pages.work_areas.add_new.confirm') }}
      </Button>
    </ValidationObserver>
  </div>
</template>

<script>
import Input from '@/components/Input'

export default {
  name: 'AddTemplate',
  components: {
    Input
  },
  data: () => ({
    submitted: false,
    newTemplate: '',
    loading: false
  }),
  methods: {
    addNewTemplate() {
      this.submitted = true

      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading = true
          this.$store
            .dispatch('Templates/addNewTemplate', this.newTemplate)
            .then(() => {
              this.$toasted.success(this.$t('pages.templates.add_new.success'))
              this.$store.dispatch('Templates/fetchTemplates')
            })
            .finally(() => {
              this.submitted = false
              this.loading = false
              this.newTemplate = ''
            })
        }
      })
    }
  }
}
</script>
