<template>
  <div class="bg-snow overflow-y-scroll" :class="{ 'md:bg-ice': isAuth }">
    <div :class="{ page: isAuth }">
      <TopNav v-if="isAuth" :title="$t('pages.privacy.title')" />

      <div v-else>
        <BackButton bg="snow" hover="snow" back="Login" class="-ml-6">
          {{ $t('pages.privacy.back_to_login') }}
        </BackButton>

        <h1 class="title-1-bold md:bold-title sm:mt-6 mb-2 text-left">
          {{ $t('pages.privacy.title') }}
        </h1>
      </div>

      <div
        v-html="
          $t('privacy.policy', {
            organisationName: this.settings.organisationName,
            organisationEmail: this.settings.organisationEmail,
            organisationVatNumber: this.settings.organisationVatNumber,
            organisationAddress: this.settings.organisationAddress
          })
        "
      ></div>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/TopNav'
import BackButton from '@/components/BackButton'
import { mapState } from 'vuex'

export default {
  name: 'Privacy',
  components: {
    TopNav,
    BackButton
  },
  computed: {
    ...mapState('Settings', ['settings']),
    isAuth() {
      return this.$route.name === 'Privacy'
    }
  }
}
</script>
