export const namespaced = true

export const state = {
  navigation: false
}

export const mutations = {
  SET_NAVIGATION(state, payload) {
    state.navigation = payload
  }
}

export const actions = {
  setNavigation({ commit }, payload) {
    commit('SET_NAVIGATION', payload)
  }
}
