import store from '@/store'
import Users from '@/views/users/Users'
import User from '@/views/users/User'

export default [
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
      access: () => store.getters['Auth/getAccess']('user.list')
    }
  },
  {
    path: '/users/:id',
    name: 'User',
    component: User,
    meta: {
      requiresAuth: true,
      access: () => store.getters['Auth/getAccess']('user.list')
    }
  },
  {
    path: '/my-profile/:id',
    name: 'MyProfile',
    component: User,
    meta: {
      requiresAuth: true,
      access: () => true
    }
  }
]
