import axios from 'axios'
import api from '@/api'

export const namespaced = true

export const state = {
  categories: []
}

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  }
}

export const actions = {
  async fetchCategories({ commit }) {
    return axios.get(api.faq.list_categories).then(res => {
      commit('SET_CATEGORIES', res.data)
    })
  }
}
