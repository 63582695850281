<template>
  <div class="lg:flex space-y-10 lg:space-y-0 lg:space-x-16">
    <div class="w-full lg:w-5/12">
      <DatePicker
        v-model="date"
        ref="datePicker"
        class="borderless lg:p-3 lg:px-4"
        title-position="left"
        is-expanded
        :attributes="attrs"
        :first-day-of-week="2"
        :locale="{ id: 'nl', masks: { weekdays: 'WW' } }"
        is-required
        @update:to-page="e => (pageInfo = e)"
      />
    </div>

    <div class="w-full lg:w-7/12">
      <AvailabilityBox
        :availability="availability"
        :desc="dayjs(date).format('DD/MM/YYYY')"
        :createUrl="
          $api.availabilities.create_date.replace('{date}', reverseDate(date))
        "
        :updateUrl="
          $api.availabilities.edit_date.replace('{date}', reverseDate(date))
        "
        :createSuccessMessage="
          $t('pages.availabilities.specific.messages.success.created')
        "
        :updateSuccessMessage="
          $t('pages.availabilities.specific.messages.success.updated')
        "
        :createErrorMessage="
          $t('pages.availabilities.specific.messages.error.created')
        "
        :updateErrorMessage="
          $t('pages.availabilities.specific.messages.error.updated')
        "
        @availabilityUpdated="fetchMonth"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { reverseDate } from '@/scripts/dates'
import config from '../../../../tailwind.config'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import AvailabilityBox from '@/components/AvailabilityBox'

export default {
  name: 'SpecificAvailabilities',
  components: {
    DatePicker,
    AvailabilityBox
  },
  data: () => ({
    date: reverseDate(dayjs()),
    from: reverseDate(dayjs().startOf('month')),
    to: reverseDate(dayjs().endOf('month')),
    fetching: false,
    reverseDate,
    dayjs,

    monthData: [],
    pageInfo: null
  }),
  computed: {
    availability() {
      return this.monthData.find(el => el.date === reverseDate(this.date))
    },
    attrs() {
      const availableDates = this.monthData
        .filter(el => el.state === 'available')
        .map(el => el.date)

      const partialDates = this.monthData
        .filter(el => el.state === 'partial_available')
        .map(el => el.date)

      const unavailableDates = this.monthData
        .filter(el => el.state === 'unavailable')
        .map(el => el.date)

      return [
        {
          key: 'today',
          highlight: {
            contentClass: 'text-eiffel '
          },
          dates: reverseDate(dayjs())
        },
        {
          highlight: {
            style: {
              backgroundColor: config.theme.colors.eiffel
            }
          },
          dates: reverseDate(this.date)
        },
        {
          dot: {
            style: {
              backgroundColor: config.theme.colors.eiffel
            }
          },
          dates: availableDates
        },
        {
          dot: {
            style: {
              backgroundColor: config.theme.colors.eiffelLift
            }
          },
          dates: partialDates
        },
        {
          dot: {
            style: {
              backgroundColor: config.theme.colors.opal
            }
          },
          dates: unavailableDates
        }
      ]
    }
  },
  created() {
    this.fetchMonth()
  },
  mounted() {
    const leftArrow = this.$refs.datePicker.$children[0].$children[2].$el
    const rightArrow = this.$refs.datePicker.$children[0].$children[3].$el

    leftArrow.addEventListener('click', () => {
      this.pageChange()
    })

    rightArrow.addEventListener('click', () => {
      this.pageChange()
    })
  },
  methods: {
    pageChange() {
      const timeout = setTimeout(() => {
        const date = dayjs()
          .set('month', this.pageInfo.month - 1)
          .set('year', this.pageInfo.year)

        this.from = reverseDate(date.startOf('month'))
        this.to = reverseDate(date.endOf('month'))

        this.fetchMonth()
        clearTimeout(timeout)
      })
    },
    fetchMonth() {
      this.fetching = true

      const url = this.$api.availabilities.list_dates
        .replace('{from}', this.from)
        .replace('{to}', this.to)

      this.$http
        .get(url)
        .then(res => {
          this.monthData = res.data
        })
        .finally(() => {
          this.fetching = false
        })
    }
  }
}
</script>
