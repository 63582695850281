<template>
  <div class="background">
    <TopNav :title="$t('pages.hours.title')" />

    <div class="page">
      <div class="search-input">
        <div class="bg-snow md:p-4 md:px-5 rounded-xl">
          <div class="lg:flex flex-wrap items-center justify-between">
            <SearchInput
              :value="search"
              :placeholder="$t('pages.hours.search')"
              :items="usersMapped"
              keyToShow="fullName"
              keyToBind="id"
              bg="bg-ice"
              clearable
              @input="onSearch"
              @itemClick="itemClick"
            />

            <div
              class="md:flex items-center mt-4 lg:mt-0 md:space-x-6 space-y-4 md:space-y-0"
            >
              <div
                class="md:flex items-center space-y-2 md:space-y-0 space-x-0 md:space-x-6"
              >
                <p>{{ $t('pages.hours.from') }}</p>
                <Input
                  v-model="startDate"
                  name="startDate"
                  type="date"
                  :fullWidth="true"
                  @input="onDateSelect"
                />
              </div>

              <div
                class="md:flex items-center space-y-2 md:space-y-0 space-x-0 md:space-x-6"
              >
                <p>{{ $t('pages.hours.to') }}</p>
                <Input
                  v-model="endDate"
                  name="endDate"
                  type="date"
                  :fullWidth="true"
                  @input="onDateSelect"
                />
              </div>
            </div>
          </div>

          <p v-if="error" class="text-opal text-12 mt-4">{{ error }}</p>
        </div>

        <div v-if="!loading" class="mt-8 pb-10">
          <div
            v-if="worked && Object.keys(worked.durationPerDate).length"
            class="space-y-2"
          >
            <div
              v-for="(value, key, i) in worked.durationPerDate"
              :key="i"
              class="flex justify-between bg-snow md:px-4 py-1 rounded-lg hover:bg-snowball"
            >
              <div class="grid grid-cols-2 gap-3">
                <h2>
                  {{
                    dayjs(key)
                      .format('dddd')
                      .charAt(0)
                      .toUpperCase() +
                      dayjs(key)
                        .format('dddd')
                        .substr(1)
                  }}
                </h2>
                <h2>{{ dayjs(key).format('DD/MM/YYYY') }}</h2>
              </div>

              <p class="font-bold">
                {{ getTotalTime(value) }}
              </p>
            </div>

            <div class="flex justify-end items-center p-4 pt-8 space-x-6">
              <h3 class="title-3">{{ $t('pages.hours.total') }}</h3>
              <p class="font-bold">
                {{ getTotalTime(worked.total) }}
              </p>
            </div>
          </div>

          <p v-else class="text-void body text-center">
            {{ $t('pages.hours.none_found') }}
          </p>
        </div>

        <PageLoading
          v-else
          class="mt-8 px-4"
          :loading="loading"
          :noneFound="
            userId
              ? $t('pages.hours.none_found')
              : $t('pages.hours.start_search')
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { reverseDate } from '@/scripts/dates'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import toObject from 'dayjs/plugin/toObject'
import TopNav from '@/components/TopNav'
import Input from '@/components/Input'
import SearchInput from '@/components/SearchInput'
import duration from 'dayjs/plugin/duration'
import PageLoading from '@/components/PageLoading'

export default {
  name: 'Hours',
  components: {
    TopNav,
    Input,
    SearchInput,
    PageLoading
  },
  data: () => ({
    dayjs,
    search: '',
    userId: '',
    startDate: reverseDate(dayjs().startOf('month')),
    endDate: reverseDate(dayjs().endOf('month')),
    worked: null,
    error: '',
    loading: false
  }),
  computed: {
    ...mapState('Users', ['users']),
    ...mapState('Breakpoints', ['isMobile']),

    usersMapped() {
      if (this.search) {
        return this.users
          .map(el => {
            el.fullName = el.firstName + ' ' + el.lastName
            return el
          })
          .filter(
            el =>
              el.fullName.toLowerCase().includes(this.search.toLowerCase()) &&
              !el.archived
          )
      }
      return this.users
        .map(el => {
          el.fullName = el.firstName + ' ' + el.lastName
          return el
        })
        .filter(el => !el.archived)
    },

    datesValid() {
      return dayjs(this.startDate).isBefore(dayjs(this.endDate))
    }
  },
  created() {
    this.fetchUsers()
    dayjs.extend(duration)
    dayjs.extend(utc)
    dayjs.extend(toObject)
  },
  methods: {
    ...mapActions('Users', ['fetchUsers']),

    getTotalTime(seconds) {
      const hours = Math.floor(seconds / 60 / 60)
      const minutes = Math.floor(seconds / 60) - hours * 60

      const hoursFormatted = hours < 10 ? `0${hours}` : hours
      const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes

      return `${hoursFormatted}:${minutesFormatted}`
    },

    fetchHours(userId) {
      if (this.userId) {
        if (this.datesValid) {
          this.loading = true

          const url = this.$api.user.planned_time
            .replace('{id}', userId)
            .replace('{start}', this.startDate)
            .replace('{end}', this.endDate)

          this.$http
            .get(url)
            .then(res => {
              this.worked = res.data
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
    onSearch(search) {
      this.search = search
    },
    itemClick(userId) {
      if (userId) {
        this.userId = userId
        this.search = this.usersMapped.find(el => el.id === userId).fullName
        this.fetchHours(userId)
      }
    },
    onDateSelect() {
      this.fetchHours(this.userId)

      if (!this.datesValid) {
        this.error = this.$t('pages.hours.control_dates')
      }

      if (this.datesValid) {
        this.error = ''
      }
    }
  }
}
</script>

<style lang="scss">
.search-input ul.dropdown {
  max-height: 15rem;
}
</style>
