var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-snowball rounded-lg w-full overflow-hidden"},[_c('div',{staticClass:"flex cursor-pointer justify-between items-center p-3 px-5",class:[
      _vm.titleClass,
      {
        'flex-row': !_vm.hasButtons,
        'flex-col-reverse sm:flex-row': _vm.hasButtons
      }
    ]},[_c('div',{staticClass:"w-full",on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{class:{
        'flex items-center justify-between sm:justify-end w-full sm:w-auto sm:py-0 mb-3 sm:mb-0': _vm.hasButtons
      }},[(_vm.hasButtons)?_vm._t("buttons"):_vm._e(),(!_vm.hideArrow)?_c('button',{staticClass:"w-10 h-10 rounded-full flex items-center justify-center",class:{
          'hover:bg-track': !_vm.hideArrow
        },on:{"click":function($event){return _vm.$emit('click')}}},[_c(require('@/assets/chevron-down.svg'),{tag:"component",staticClass:"w-8 h-8 transform transition-all duration-200 ease-in-out",class:{
            'rotate-180 text-coal': _vm.open,
            'text-void': !_vm.open
          }})],1):_vm._e()],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open && _vm.dropdown),expression:"open && dropdown"}],staticClass:"dropdown px-5 pb-4",class:_vm.dropdownClass,attrs:{"id":_vm.title}},[(_vm.isHtml)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.dropdown)}}):_c('p',[_vm._v(_vm._s(_vm.dropdown))])])])}
var staticRenderFns = []

export { render, staticRenderFns }