import get from 'lodash.get'
import axios from 'axios'
import api from '@/api'
import jwtDecode from 'jwt-decode'

const token = localStorage.getItem('JWT_TOKEN')
const refreshToken = localStorage.getItem('REFRESH_TOKEN')
const permissions = JSON.parse(localStorage.getItem('PERMISSIONS'))

export const namespaced = true

export const state = {
  jwtToken: token || '',
  jwtData: (token && jwtDecode(token)) || null,
  refreshToken: refreshToken || '',
  me: null,
  permissions: permissions || null,
  appLoaded: false
}

export const getters = {
  getAccess(state) {
    return access => {
      return get(state.permissions, access)
    }
  }
}

export const mutations = {
  SET_JWT_TOKEN(state, token) {
    localStorage.setItem('JWT_TOKEN', token)
    state.jwtToken = token
  },
  SET_JWT_DATA(state, data) {
    state.jwtData = data
  },
  SET_REFRESH_TOKEN(state, token) {
    localStorage.setItem('REFRESH_TOKEN', token)
    state.refreshToken = token
  },
  CLEAR_AUTH_DATA(state) {
    localStorage.removeItem('JWT_TOKEN')
    localStorage.removeItem('REFRESH_TOKEN')
    localStorage.removeItem('PERMISSIONS')
    state.jwtToken = null
    state.jwtData = null
    state.refreshToken = null
    state.permissions = null
  },
  SET_ME_DATA(state, me) {
    state.me = me
  },
  SET_PERMISSIONS(state, permissions) {
    localStorage.setItem('PERMISSIONS', JSON.stringify(permissions))
    state.permissions = permissions
  },
  SET_APP_LOADED(state, payload) {
    state.appLoaded = payload
  }
}

export const actions = {
  setJwtToken({ commit }, token) {
    commit('SET_JWT_TOKEN', token)
    commit('SET_JWT_DATA', jwtDecode(token))
  },
  setRefreshToken({ commit }, token) {
    commit('SET_REFRESH_TOKEN', token)
  },
  logout({ commit }) {
    commit('CLEAR_AUTH_DATA')
  },
  refreshToken({ state, commit }) {
    commit('SET_APP_LOADED', true)

    return axios
      .post(api.auth.jwt_refresh, {
        refreshToken: state.refreshToken
      })
      .then(res => {
        commit('SET_JWT_TOKEN', res.data.token)
        commit('SET_JWT_DATA', jwtDecode(res.data.token))
        commit('SET_REFRESH_TOKEN', res.data.refreshToken)

        return res
      })
  },
  fetchMe({ commit }) {
    return axios.get(api.user.me).then(res => {
      commit('SET_ME_DATA', res.data)
      return res
    })
  },
  fetchPermissions({ commit }) {
    return axios.get(api.auth.permissions).then(res => {
      commit('SET_PERMISSIONS', res.data)
      return res.data
    })
  },
  setAppLoaded({ commit }, payload) {
    commit('SET_APP_LOADED', payload)
  }
}
