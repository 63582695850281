<template>
  <ValidationProvider
    tag="div"
    :name="name"
    :rules="rules"
    v-slot="{ errors }"
    mask="XXXX"
    class="input"
    :class="{
      'w-full': fullWidth
    }"
  >
    <label v-if="label" :for="name" class="font-normal text-12 block mb-2">
      {{ label }}
    </label>

    <div class="relative" v-click-outside="() => (dropdown = false)">
      <input
        :id="name"
        :value="items.length ? selected : value"
        :type="type"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :tabindex="tabindex"
        :disabled="disabled"
        v-mask="mask"
        v-focus="autoFocus"
        class="rounded-sm py-2 outline-none placeholder-void text-mine"
        :class="[
          inputClass,
          {
            'bg-ice focus:bg-eiffel focus:bg-opacity-10 px-3':
              mode === 'default',
            'title-2 text-mine': mode === 'large',
            'text-center': center,
            'w-full': fullWidth
          }
        ]"
        @input="input"
        @focus="focus"
      />

      <component
        v-if="items.length"
        :is="require('@/assets/chevron-down.svg')"
        class="w-6 h-6 text-void absolute bottom-0 m-auto right-2 cursor-pointer transform transition-all duration-150 ease-out top-0"
        :class="{
          'rotate-180': dropdown
        }"
        @click="dropdown = !dropdown"
      />

      <ul
        v-if="dropdown && items.length"
        class="dropdown overflow-y-auto absolute w-full p-0 rounded-b-lg bg-snow border border-snowball border-t-0 z-50 shadow-xl"
      >
        <div v-if="items.length">
          <li
            v-for="(item, i) of items"
            :key="i"
            class="border-b border-ice px-3 py-2 cursor-pointer hover:bg-eiffel hover:bg-opacity-10"
            @click="itemClick(item)"
          >
            <p v-if="item">{{ item[keyToShow] }}</p>
          </li>
        </div>

        <li
          v-else
          class="border-b border-ice px-3 py-2"
          @click="dropdown = false"
        >
          {{ $t('components.dropdown.no_options') }}
        </li>
      </ul>

      <div
        v-if="icon"
        class="absolute right-0 top-0 bottom-0 m-auto flex-center"
      >
        <button
          type="button"
          v-ripple
          class="focus:outline-none rounded-full w-10 h-10 flex-center"
          @click="$emit('iconClick')"
        >
          <component
            class="text-void"
            :class="{
              'w-6': mode === 'default',
              'w-8': mode === 'large'
            }"
            :is="require(`@/assets/${icon}.svg`)"
          />
        </button>
      </div>
    </div>

    <p
      v-if="errors[0] && submitted"
      class="text-opal mt-1"
      :class="{
        'text-12': mode === 'default',
        'text-15': mode === 'large'
      }"
    >
      {{ errors[0] }}
    </p>
  </ValidationProvider>
</template>

<script>
import { mask } from 'vue-the-mask'
import vClickOutside from 'v-click-outside'

export default {
  name: 'Input',
  directives: {
    mask: (el, binding) => {
      if (!binding.value) return
      mask(el, binding)
    },
    clickOutside: vClickOutside.directive
  },
  data: () => ({
    dropdown: false,
    selected: ''
  }),
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: () => ''
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    placeholder: {
      type: String,
      required: false,
      default: () => ''
    },
    type: {
      type: String,
      required: false,
      default: () => 'text'
    },
    icon: {
      type: String,
      required: false,
      default: () => ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: () => ''
    },
    tabindex: {
      type: String,
      required: false,
      default: () => ''
    },
    submitted: {
      type: Boolean,
      required: false,
      default: () => false
    },
    rules: {
      type: String,
      required: false,
      default: () => ''
    },
    mask: {
      type: String,
      required: false,
      default: () => ''
    },
    mode: {
      type: String,
      required: false,
      default: () => 'default'
    },
    autoFocus: {
      type: Boolean,
      required: false,
      default: () => false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    items: {
      type: [Array, Object],
      required: false,
      default: () => []
    },
    keyToShow: {
      type: String,
      required: false
    },
    keyToBind: {
      type: [String],
      required: false
    },
    center: {
      type: Boolean,
      required: false,
      default: () => false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: () => true
    },
    inputClass: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  watch: {
    value(e) {
      if (!e) {
        this.selected = ''
      }
    }
  },
  created() {
    if (this.value && this.items.length) {
      const item = this.items.find(el => el[this.keyToBind] === this.value)
      this.selected = item ? item[this.keyToShow] : ''
    }
  },
  methods: {
    input(e) {
      this.$emit('input', e.target.value)
    },
    focus() {
      this.dropdown = true
      this.$emit('focus')
    },
    itemClick(item) {
      this.dropdown = false
      this.selected = item[this.keyToShow]
      this.$emit('input', item[this.keyToBind])
    }
  }
}
</script>

<style lang="scss">
.input ul.dropdown {
  max-height: 15rem;
}
</style>
