<template>
  <div class="background">
    <TopNav
      :title="$t('pages.dashboard.title')"
      show-logo
      :subNav="subNav"
      show-hello
    />

    <div class="page">
      <div v-if="!loading" class="lg:flex flex-row-reverse justify-end">
        <!-- Bulletins -->
        <BulletinOverview
          class="overview w-full lg:w-6/12 xl:w-5/12 ml-0 lg:ml-8 lg:mt-0"
          :nbrToFetch="3"
        />

        <div class="w-full lg:w-6/12 xl:w-5/12 mt-8 lg:mt-0 space-y-8">
          <UpcomingShifts :upcomingShifts="upcomingShifts" />

          <EmptyShifts
            v-if="permissions.planningShift.managePlanning && hasEmptyShifts"
            :emptyShifts="emptyShifts"
          />
        </div>
      </div>

      <PageLoading
        v-else
        class="mt-10"
        align="text-left"
        :loading="loading"
        :noneFound="$t('pages.dashboard.nothing_on_dashboard')"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapState } from 'vuex'
import TopNav from '@/components/TopNav'
import BulletinOverview from '@/components/BulletinOverview'
import UpcomingShifts from '@/components/UpcomingShifts'
import EmptyShifts from '@/components/EmptyShifts'
import PageLoading from '@/components/PageLoading'

export default {
  name: 'Dashboard',
  components: {
    TopNav,
    BulletinOverview,
    UpcomingShifts,
    EmptyShifts,
    PageLoading
  },
  data() {
    return {
      dayjs,
      loading: true,
      upcomingShifts: {},
      emptyShifts: {},
      subNav: [
        {
          icon: 'arrow-right-small',
          iconColor: 'text-eiffel',
          label: this.$t('pages.dashboard.sub_nav.planning.label'),
          desc: this.$t('pages.dashboard.sub_nav.planning.desc'),
          to: 'Planning'
        },
        {
          icon: 'arrow-right-small',
          iconColor: 'text-eiffel',
          label: this.$t('pages.dashboard.sub_nav.availabilities.label'),
          desc: this.$t('pages.dashboard.sub_nav.availabilities.desc'),
          to: 'Availabilities'
        }
      ]
    }
  },
  computed: {
    ...mapState('Auth', ['me', 'permissions']),
    ...mapState('Bulletin', ['bulletins']),

    hasEmptyShifts() {
      return this.emptyShifts && !Array.isArray(this.emptyShifts.dayStatuses)
    }
  },
  async created() {
    if (!this.me) {
      await this.fetchMe()
      this.fetch()
    }

    if (this.me) {
      this.fetch()
    }
  },
  methods: {
    ...mapActions('Auth', ['fetchMe']),
    ...mapActions('Bulletin', ['fetchBulletins']),

    async fetch() {
      this.loading = true

      await this.fetchUpcomingShifts()

      if (this.permissions.planningShift.managePlanning) {
        await this.fetchEmptyShifts()
      }

      await this.fetchBulletins(3)

      this.loading = false
    },

    async fetchUpcomingShifts() {
      return this.$http
        .get(this.$api.planning.upcoming_shifts.replace('{id}', this.me.id), {
          params: {
            count: 5
          }
        })
        .then(res => {
          this.upcomingShifts = res.data
        })
    },

    async fetchEmptyShifts() {
      return this.$http
        .get(this.$api.planning.empty_shifts, { params: { count: 3 } })
        .then(res => {
          this.emptyShifts = res.data
        })
    }
  }
}
</script>

<style lang="scss">
.overview .box {
  @apply rounded-xl;
}
</style>
