<template>
  <div
    class="overflow-x-auto flex flex-col flex-1"
    :style="`max-width: calc(100vw - 36rem)`"
  >
    <div ref="planner" class="planner flex-1 overflow-y-auto">
      <DayPilotScheduler id="dp" :config="config" ref="scheduler" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { dateFromTime } from '@/scripts/dates'
import { DayPilot, DayPilotScheduler } from 'daypilot-pro-vue'
import cloneDeep from 'lodash.clonedeep'
import { mapState } from 'vuex'

const subtraction = 30

export default {
  name: 'Planner',
  components: {
    DayPilotScheduler
  },
  props: {
    rows: {
      type: Array,
      required: true
    },
    shift: {
      type: Object,
      required: true
    },
    shifts: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    canCreate: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    const self = this

    return {
      date: null,
      contextMenuIsOpen: false,
      config: {
        locale: 'nl-be',
        cellWidthSpec: 'Auto',
        rowMinHeight: 70,
        rowMarginBottom: 40,
        heightSpec: 'Parent100Pct',
        timeHeaders: [
          {
            groupBy: 'Hour',
            format: 'HH:mm'
          }
        ],
        scale: 'CellDuration',
        cellDuration: 30,
        days: 1,
        startDate: DayPilot.Date.today(),
        showNonBusiness: false,
        businessBeginsHour: 6,
        businessEndsHour: 21,
        businessWeekends: true,
        floatingEvents: false,
        eventHeight: 35,
        durationBarVisible: false,

        contextMenu: new DayPilot.Menu({
          theme: 'context_menu',
          items: [
            {
              text: self.$t('components.planner.context_menu.edit_shift'),
              onClick: args => {
                this.contextMenuIsOpen = false
                self.config.contextMenu.hide()
                this.editShift(args)
              }
            },
            {
              text: self.$t('components.planner.context_menu.fill_shift'),
              onClick: args => {
                this.contextMenuIsOpen = false
                self.config.contextMenu.hide()
                this.fillInShift(args)
              }
            },
            {
              text: self.$t('components.planner.context_menu.delete_shift'),
              onClick: args => {
                this.contextMenuIsOpen = false
                self.config.contextMenu.hide()
                self.$emit('shiftDeleted', args.source.e.data)
              }
            },
            {
              text: self.$t('components.planner.context_menu.clear_shift'),
              onClick: args => {
                this.contextMenuIsOpen = false
                self.config.contextMenu.hide()
                self.$emit('shiftCleared', args.source.e.data)
              }
            }
          ]
        }),

        /* Shift created */
        timeRangeSelectedHandling: self.canCreate ? 'Enabled' : 'Disabled',
        onTimeRangeSelected(args) {
          if (!self.contextMenuIsOpen) {
            self.shift.workArea = args.resource

            self.shift.startTime = dayjs(args.start)
              .subtract(subtraction, 'minute')
              .format('HH:mm')
            self.shift.endTime = dayjs(args.end)
              .subtract(subtraction, 'minute')
              .format('HH:mm')

            self.$emit('shiftCreateOrClick')
          }

          args.control.clearSelection()
          self.contextMenuIsOpen = false
        },

        /* Shift moved */
        eventMoveHandling: 'Update',
        onEventMoved(shift) {
          self.shiftsChanged(shift)
        },

        /* Shift resized */
        eventResizeHandling: 'Update',
        onEventResized(shift) {
          self.shiftsChanged(shift)
        },

        /* Shift click */
        eventClickHandling: 'Update',
        onEventClicked(args) {
          self.shouldShowContextMenuItems(args)
        },
        onEventRightClicked(args) {
          self.shouldShowContextMenuItems(args)
        },
        eventHoverHandling: 'Bubble',
        bubble: new DayPilot.Bubble({
          animated: false,
          showAfter: 0,
          hideAfter: 1,
          theme: '',
          loadingText: '',
          onLoad(args) {
            if (args.source.part.width < 200) {
              args.html = `
                <p class="tooltip text-void font-bold text-14">
                  ${args.source.data.startTime} - ${args.source.data.endTime}
                </p>
              `
            }
          }
        })
      }
    }
  },
  computed: {
    ...mapState('Settings', ['settings']),
    scheduler() {
      return this.$refs.scheduler.control
    }
  },
  created() {
    dayjs.extend(utc)

    const startHours = this.settings.dayStart.split(':')[0]
    const startMinutes = this.settings.dayStart.split(':')[1]
    const start = dayjs()
      .set('hour', startHours)
      .set('minute', startMinutes)
      .format('HH.m')

    const endHours = this.settings.dayEnd.split(':')[0]
    const endMinutes = this.settings.dayEnd.split(':')[1]
    const end = dayjs()
      .set('hour', endHours)
      .set('minute', endMinutes)
      .format('HH.m')

    this.config.businessBeginsHour = start
    this.config.businessEndsHour = end

    this.loadWorkAreas()
    this.loadShifts()
  },
  methods: {
    shouldShowContextMenuItems(args) {
      const items = this.config.contextMenu.items
      const shift = args.e.data

      // Edit
      items[0].hidden = !shift.metadata.security.edit
      // Fill in shift
      items[1].hidden = !shift.metadata.security.plan
      // Delete shift
      items[2].hidden = !shift.metadata.security.delete
      // Clear shift
      items[3].hidden = !shift.metadata.security.clear

      this.contextMenuIsOpen = true
      this.config.contextMenu.show(args)
    },
    editShift(args) {
      const { source } = args

      const start = source.e.start()
      const end = source.e.end()

      const beakStart = source.e.data.breakStartTime
      const breakEnd = source.e.data.breakEndTime

      this.shift.id = source.e.id()
      this.shift.urgent = source.e.data.urgent
      this.shift.workArea = source.e.resource()

      this.shift.startTime = dayjs(start)
        .subtract(subtraction, 'minute')
        .format('HH:mm')
      this.shift.endTime = dayjs(end)
        .subtract(subtraction, 'minute')
        .format('HH:mm')

      this.shift.breakStartTime = beakStart
      this.shift.breakEndTime = breakEnd

      this.$emit('shiftCreateOrClick')
    },
    fillInShift(args) {
      this.$emit('fillInShift', args.source.e.data)
    },
    shiftsChanged(shift) {
      const id = shift.e.id()
      const workArea = shift.newResource

      // New work time
      const newStart = dayjs(shift.newStart)
        .subtract(subtraction, 'minute')
        .format('HH:mm')
      const newEnd = dayjs(shift.newEnd)
        .subtract(subtraction, 'minute')
        .format('HH:mm')

      // Break time (TODO: Move break)
      const newBreakStart = shift.e.data.breakStartTime
        ? dateFromTime(shift.e.data.breakStartTime).format('HH:mm')
        : null
      const newBreakEnd = shift.e.data.breakEndTime
        ? dateFromTime(shift.e.data.breakEndTime).format('HH:mm')
        : null

      const obj = {
        id,
        workArea,
        startTime: newStart,
        endTime: newEnd,
        breakStartTime: newBreakStart,
        breakEndTime: newBreakEnd
      }

      this.$emit('shiftResizedOrMoved', obj)
    },
    loadShifts() {
      Vue.set(
        this.config,
        'events',
        this.shifts.map(shift => {
          let contentToShow = ''

          shift.areas = [
            {
              start: null,
              end: null,
              cssClass: 'break'
            }
          ]

          // Add class to make blue if a user is assigned to the shift
          if (shift.plannedUser) {
            shift.cssClass = 'shift assigned'
          }

          if (!shift.plannedUser) {
            shift.cssClass = 'shift unassigned'
          }

          // Disable moving when edit permission is false
          if (!shift.metadata.security.edit) {
            shift.moveDisabled = true
            shift.resizeDisabled = true
          }

          // Set workarea
          shift.resource = shift.workArea.id

          // Set formatted worktime
          shift.start = shift.startTime
            ? dateFromTime(shift.startTime)
                .add(30, 'minute')
                .format()
            : null

          const isSameDay = dateFromTime(shift.startTime).isBefore(
            dateFromTime(shift.endTime)
          )

          // If end time is before start time, increment with one
          // day so that the event is still shown on the planner.
          if (!isSameDay) {
            shift.end = shift.endTime
              ? dateFromTime(shift.endTime)
                  .add(1, 'day')
                  .add(30, 'minute')
                  .format()
              : null
          } else {
            shift.end = shift.endTime
              ? dateFromTime(shift.endTime)
                  .add(30, 'minute')
                  .format()
              : null
          }

          // Set formatted breaktime
          shift.areas[0].start = shift.breakStartTime
            ? dateFromTime(shift.breakStartTime)
                .add(30, 'minute')
                .format()
            : null
          shift.areas[0].end = shift.breakEndTime
            ? dateFromTime(shift.breakEndTime)
                .add(30, 'minute')
                .format()
            : null

          contentToShow = shift.plannedUser
            ? shift.plannedUser.firstName
            : this.$t('components.planner.need_to_fill_in')

          contentToShow += ` ${shift.startTime} - ${shift.endTime}`

          shift.html = `
            <div class="overflow-hidden">
              <div class="flex items-center">
                ${
                  shift.urgent
                    ? `
                      <div class="w-5 h-5 mr-2" style="min-width: 1.25rem;">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 17 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1688 1.35571C6.77916 1.35571 4.881 3.10337 3.38959 4.06737L4.74541 5.42321L0 7.45696L4.74541 9.49071L3.38956 10.8466C4.881 11.8106 7.45709 13.5582 10.1688 13.5582C13.5384 13.5582 16.27 10.8266 16.27 7.45696C16.27 4.08733 13.5384 1.35571 10.1688 1.35571ZM10.1688 11.5245C7.92235 11.5245 6.10125 9.70337 6.10125 7.45696C6.10125 5.21056 7.92235 3.38946 10.1688 3.38946C12.4152 3.38946 14.2362 5.21056 14.2362 7.45696C14.2336 9.70229 12.4141 11.5219 10.1688 11.5245Z"
                            fill="#0667F9"
                          />
                          <path
                            d="M9.98484 6.6814L8.34904 6.13906L7.91992 7.42709L9.95367 8.10499C10.1973 8.18488 10.4652 8.12152 10.6472 7.94093L12.6809 5.90718L11.723 4.94385L9.98484 6.6814Z"
                            fill="#0667F9"
                          />
                          <path d="M2.71166 2.71167H0V4.06751H2.71166V2.71167Z" fill="#0667F9" />
                          <path d="M2.71166 10.8467H0V12.2025H2.71166V10.8467Z" fill="#0667F9" />
                          <path d="M5.42308 0H2.71143V1.35584H5.42308V0Z" fill="#0667F9" />
                          <path d="M5.42308 13.5583H2.71143V14.9142H5.42308V13.5583Z" fill="#0667F9" />
                        </svg>
                      </div>`
                    : ''
                }

                <p>${contentToShow}</p>
              </div>
            </div>
          `

          return shift
        })
      )
    },
    loadWorkAreas() {
      const rows = cloneDeep(this.rows)

      rows.map(row => {
        row.html = `<h3 class="title-3">${row.name}</h3`
        return row
      })

      Vue.set(this.config, 'resources', rows)
    }
  }
}
</script>

<style lang="scss">
// Context menu
.context_menu_main {
  @apply bg-none bg-snow rounded-md border-none shadow-lg;

  .context_menu_item a {
    @apply px-3 py-2 text-14 cursor-pointer hover:bg-eiffel hover:bg-opacity-10;
  }
}

.planner {
  width: 100%;
  min-width: 45rem;

  // Scheduler
  .scheduler_default_main {
    @apply border-none;
  }

  // Hours
  .scheduler_default_timeheadercol {
    @apply bg-transparent text-dirt transform;

    .scheduler_default_timeheadercol_inner {
      @apply border-none;
    }
  }

  // Crosshair
  .scheduler_default_crosshair_left,
  .scheduler_default_crosshair_top {
    @apply rounded-lg bg-eiffel opacity-10;
  }

  // Workareas
  .scheduler_default_corner,
  .scheduler_default_rowheader {
    @apply bg-transparent;

    .scheduler_default_rowheader_inner {
      @apply border-none items-start;
    }

    .scheduler_default_resourcedivider {
      @apply hidden;
    }

    > div:nth-child(2) {
      @apply hidden;
    }
  }

  // Dividers
  .scheduler_default_divider_horizontal,
  .scheduler_default_splitter {
    @apply hidden;
  }

  // Matrix
  .scheduler_default_matrix {
    // Vertical lines
    div.scheduler_default_matrix_vertical_line {
      @apply bg-snowball;

      &:last-child {
        @apply hidden;
      }
    }
    .scheduler_default_matrix_vertical_line:not(:nth-child(2n + 1)) {
      @apply hidden;
    }

    // Horizontal lines
    .scheduler_default_matrix_horizontal_line {
      @apply hidden;
    }
  }

  // Cell
  .scheduler_default_cell,
  .scheduler_default_cell_business {
    @apply bg-transparent;
    background: transparent !important;

    &:hover {
      @apply bg-eiffel bg-opacity-5;
    }
  }

  // Rowheader
  .scheduler_default_main .scheduler_default_rowheader_scroll {
    @apply bg-transparent;
  }

  // Colheader
  .scheduler_default_main .scheduler_default_timeheader_scroll {
    @apply bg-transparent;
  }

  // Shadow
  .scheduler_default_shadow_inner {
    @apply bg-eiffel bg-opacity-10 bg-none;
  }

  // Event
  .scheduler_default_event {
    overflow: visible !important;

    &.assigned .scheduler_default_event_inner {
      @apply bg-sky;
    }

    &.shift.unassigned .scheduler_default_event_inner {
      @apply border border-opal;
    }

    &:hover {
      .scheduler_default_event_inner {
        @apply bg-eiffelLift;
      }

      .urgent-label {
        @apply scale-125;
      }
    }

    .urgent-label {
      top: -2px;
      left: -2px;
    }

    .scheduler_default_event_inner {
      @apply bg-snowball bg-none border-none rounded-lg px-3 text-mine overflow-visible;
      bottom: 5px;
    }

    .break {
      @apply bg-snow opacity-40;
      height: 30px;
    }
  }
}
</style>
