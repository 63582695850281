<template>
  <div class="flex flex-col h-screen overflow-auto">
    <div class="px-4">
      <div v-if="!loading">
        <MobileNav :title="$t('pages.planning.title')" />

        <DatePicker
          ref="datePicker"
          v-model="date"
          :attributes="attrs"
          class="mt-5 borderless"
          title-position="left"
          is-expanded
          is-required
          @update:to-page="e => (pageInfo = e)"
        />

        <!-- Planned Shifts -->
        <div v-if="!fetching && shiftsOfToday.length" class="space-y-3">
          <ShadowBox v-for="(shift, i) in shiftsOfToday" :key="i">
            <div
              class="flex justify-between p-3"
              @click="shiftExtended = shift.id"
            >
              <div>
                <h2 class="text-16 font-bold text-coal">
                  {{ $t('pages.planning.my_planned_shift') }}
                </h2>

                <p class="caption-1 text-void">
                  {{ shift.workArea.translations[$i18n.locale].name }}
                </p>
              </div>

              <div class="flex flex-col items-end">
                <h2>{{ shift.startTime }} - {{ shift.endTime }}</h2>
                <p
                  v-if="shift.breakStartTime && shift.breakEndTime"
                  class="text-11 text-void"
                >
                  {{ $t('pages.planning.break') }}
                  {{ shift.breakStartTime }} - {{ shift.breakEndTime }}
                </p>
              </div>
            </div>

            <template v-if="shift.id === shiftExtended" #extra>
              <div
                class="flex justify-center items-center flex-col py-5 px-3 space-y-3"
              >
                <Button
                  bg="snow"
                  hover="ice"
                  text="void"
                  pill
                  upper
                  @click="shiftExtended = null"
                >
                  {{ $t('pages.planning.close') }}
                </Button>
              </div>
            </template>
          </ShadowBox>
        </div>

        <PageLoading
          v-else
          class="mt-10"
          :loading="fetching"
          :noneFound="$t('pages.planning.no_planned_on_date')"
        />
      </div>

      <PageLoading
        v-else
        class="mt-10"
        :loading="loading"
        :noneFound="$t('pages.planning.page_loading_error')"
      />
    </div>

    <div v-if="!loading && !fetching" class="w-full bg-ice mt-6 pb-6 flex-1">
      <div
        class="flex w-full overflow-x-auto py-4 space-x-4"
        style="max-width: 100vw"
      >
        <div
          v-for="(workArea, i) in workAreas"
          :key="i"
          :class="{
            'pl-4': i === 0,
            'pr-4': i === workAreas.length - 1
          }"
        >
          <button
            class="caption p-2 px-3 rounded-md bg-opacity-50 whitespace-nowrap"
            :class="{
              'text-void': workArea.id !== selectedWorkArea,
              'text-mine': workArea.id === selectedWorkArea,
              'bg-snowball': workArea.id === selectedWorkArea
            }"
            @click="onWorkAreaClick(workArea)"
          >
            {{ workArea.name }}
          </button>
        </div>
      </div>

      <div class="px-4 mx-auto">
        <div v-if="shiftsBasedOnWorkArea.length" class="space-y-3">
          <Shift
            v-for="(shift, i) in shiftsBasedOnWorkArea"
            :key="i"
            :shift="shift"
            :title="
              shift.plannedUser
                ? shift.plannedUser.firstName
                : $t('components.planner.need_to_fill_in')
            "
            :desc="`${shift.startTime} - ${shift.endTime}`"
            :shiftBreak="
              shift.breakStartTime &&
                shift.breakEndTime &&
                `${$t('pages.planning.break')}
               ${shift.breakStartTime} - ${shift.breakEndTime}`
            "
            :class="{
              'bg-eiffelLift': shift.plannedUser,
              'bg-snow': !shift.plannedUser
            }"
          />
        </div>

        <PageLoading
          v-else
          class="px-2 mt-10"
          :loading="fetching"
          :noneFound="$t('pages.planning.no_shifts_on_this_workarea')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapState } from 'vuex'
import { reverseDate } from '@/scripts/dates'
import MobileNav from '@/components/MobileNav'
import PageLoading from '@/components/PageLoading'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import ShadowBox from '@/components/ShadowBox'
import Shift from '@/components/Shift'

export default {
  name: 'MobilePlanning',
  components: {
    MobileNav,
    DatePicker,
    PageLoading,
    ShadowBox,
    Shift
  },
  data: () => ({
    loading: true,
    fetching: false,
    selectedWorkArea: null,
    pageInfo: null,
    shiftExtended: null,

    selectedShift: null
  }),
  computed: {
    ...mapState('Planning', ['shifts']),
    ...mapState('Auth', ['me']),
    ...mapState('WorkAreas', ['workAreas']),
    ...mapGetters('Planning', ['attrs']),

    date: {
      get() {
        return reverseDate(this.$store.state.Planning.date)
      },
      set(date) {
        if (date) {
          this.fetching = true
          this.setDate(dayjs(date))
          this.fetchShifts(reverseDate(dayjs(date))).finally(() => {
            this.fetching = false
          })
        }
      }
    },
    shiftsOfToday() {
      return this.shifts.filter(
        el => el.plannedUser && el.plannedUser.id === this.me.id
      )
    },
    shiftsBasedOnWorkArea() {
      return this.shifts.filter(el => el.workArea.id === this.selectedWorkArea)
    }
  },
  async created() {
    await this.fetchPlanningStatuses()
    await this.fetchShifts(reverseDate(this.date))
    await this.fetchWorkAreas()

    this.selectedWorkArea =
      this.workAreas.length > 0 ? this.workAreas[0].id : null
    this.loading = false

    const timeout = setTimeout(() => {
      const leftArrow = this.$refs.datePicker.$children[0].$children[2].$el
      const rightArrow = this.$refs.datePicker.$children[0].$children[3].$el

      leftArrow.addEventListener('click', () => {
        this.pageChange()
      })

      rightArrow.addEventListener('click', () => {
        this.pageChange()
      })

      clearTimeout(timeout)
    }, 0)
  },
  methods: {
    ...mapActions('Planning', [
      'setDate',
      'fetchShifts',
      'fetchPlanningStatuses'
    ]),
    ...mapActions('WorkAreas', ['fetchWorkAreas']),

    onWorkAreaClick(workArea) {
      this.selectedWorkArea = workArea.id
    },
    pageChange() {
      const timeout = setTimeout(() => {
        const date = dayjs()
          .set('month', this.pageInfo.month - 1)
          .set('year', this.pageInfo.year)

        const from = reverseDate(date.startOf('month'))
        const to = reverseDate(date.endOf('month'))

        this.fetchPlanningStatuses({ from, to })
        clearTimeout(timeout)
      })
    },
  }
}
</script>
