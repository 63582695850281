<template>
  <router-view />
</template>

<script>
export default {
  name: 'Registration',
  created() {
    this.$store.dispatch('Auth/logout')

    if (this.$route.name !== 'Registration.contractType') {
      this.$router.replace({ name: 'Registration.contractType' })
    }
  }
}
</script>
