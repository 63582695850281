<template>
  <div class="grid gap-4 grid-cols-12 text-left">
    <!-- Start time -->
    <Input
      v-model="shift.workArea"
      class="col-span-12"
      rules="required"
      :label="$t('pages.template.add_template_shift.form.work_area.label')"
      :placeholder="
        $t('pages.template.add_template_shift.form.work_area.placeholder')
      "
      :submitted="submitted"
      :items="workAreas"
      keyToShow="name"
      keyToBind="id"
      name="workArea"
    />

    <!-- Start time -->
    <Input
      v-model="shift.startTime"
      mask="##:##"
      class="col-span-12 sm:col-span-6"
      rules="required"
      :label="$t('pages.template.add_template_shift.form.start_time.label')"
      :placeholder="
        $t('pages.template.add_template_shift.form.start_time.placeholder')
      "
      :submitted="submitted"
      name="startTime"
    />

    <!-- End time -->
    <Input
      v-model="shift.endTime"
      mask="##:##"
      class="col-span-12 sm:col-span-6"
      rules="required"
      :label="$t('pages.template.add_template_shift.form.end_time.label')"
      :placeholder="
        $t('pages.template.add_template_shift.form.end_time.placeholder')
      "
      :submitted="submitted"
      name="endTime"
    />

    <!-- Break start time -->
    <Input
      v-model="shift.breakStartTime"
      class="col-span-12 sm:col-span-6"
      mask="##:##"
      :label="
        $t('pages.template.add_template_shift.form.break_start_time.label')
      "
      :placeholder="
        $t(
          'pages.template.add_template_shift.form.break_start_time.placeholder'
        )
      "
      :submitted="submitted"
      name="breakStartTime"
    />

    <!-- Break end time -->
    <Input
      v-model="shift.breakEndTime"
      class="col-span-12 sm:col-span-6"
      mask="##:##"
      :label="$t('pages.template.add_template_shift.form.break_end_time.label')"
      :placeholder="
        $t('pages.template.add_template_shift.form.break_end_time.placeholder')
      "
      :submitted="submitted"
      name="breakEndTime"
    />

    <Checkbox
      class="col-span-12 mt-2"
      v-model="shift.urgent"
      :label="$t('pages.template.add_template_shift.form.urgent.label')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Input from '@/components/Input'
import Checkbox from '@/components/Checkbox'

export default {
  name: 'TemplateShiftForm',
  components: {
    Input,
    Checkbox
  },
  props: {
    shift: {
      type: Object,
      required: true
    },
    submitted: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    workAreas() {
      return this.$store.state.WorkAreas.workAreas
    }
  },
  created() {
    this.fetchWorkAreas()
  },
  methods: {
    ...mapActions('WorkAreas', ['fetchWorkAreas'])
  }
}
</script>
