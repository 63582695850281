<template>
  <div class="shadow-md md:shadow-none border border-ice bg-snow rounded-xl">
    <slot />

    <slot name="extra" />
  </div>
</template>

<script>
export default {
  name: 'ShadowBox'
}
</script>
