<template>
  <div>
    <h2 class="title-2 mb-3">
      {{ $t('pages.dashboard.your_shifts') }}
    </h2>

    <div
      v-if="
        upcomingShifts && upcomingShifts.items && upcomingShifts.items.length
      "
      class="space-y-3"
    >
      <Shift
        v-for="(shift, i) in upcomingShifts.items"
        :key="i"
        :shift="shift"
        :title="dayjs(shift.date).format('DD/MM/YYYY')"
        :desc="`${shift.startTime} - ${shift.endTime}`"
        class="py-3 cursor-pointer bg-ice md:bg-snow hover:bg-eiffelLift"
        :shiftBreak="
          shift.breakStartTime &&
            shift.breakEndTime &&
            `${$t('pages.planning.break')}
               ${shift.breakStartTime} - ${shift.breakEndTime}`
        "
        @click="onShiftClick(shift.date)"
      />
    </div>

    <p v-else class="text-void body">
      {{ $t('pages.dashboard.no_upcoming_shifts') }}
    </p>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Shift from '@/components/Shift'

export default {
  name: 'UpcomingShifts',
  components: {
    Shift
  },
  props: {
    upcomingShifts: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dayjs
  }),
  methods: {
    onShiftClick(date) {
      this.$router.push({ name: 'Planning', params: { date } })
    }
  }
}
</script>
