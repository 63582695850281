var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"relative flex items-center cursor-pointer",on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"group"},[_c('input',{staticClass:"w-5 h-5 absolute z-10 opacity-0 cursor-pointer",attrs:{"type":"checkbox"},domProps:{"checked":_vm.value},on:{"input":function (e) { return _vm.$emit('input', e.target.checked); }}}),_c('div',{staticClass:"border-2 border-tire w-5 h-5 relative overflow-hidden transition-all duration-200",class:( _obj = {}, _obj[("border-" + _vm.color)] = _vm.value, _obj[("bg-" + _vm.color)] = _vm.value && !_vm.round, _obj['group-hover:bg-track'] =  !_vm.value, _obj['rounded-sm'] =  !_vm.round, _obj['rounded-full'] =  _vm.round, _obj ),on:{"click":function($event){return _vm.$emit('input', !_vm.value)}}},[_c('div',{staticClass:"absolute top-0 left-0 right-0 bottom-0 transition-all duration-100 m-auto",class:[
          ("bg-" + _vm.color),
          {
            'w-full h-full': _vm.value && !_vm.round,
            'inner-round rounded-full': _vm.value && _vm.round,
            'w-0 h-0 rounded-sm': !_vm.value
          }
        ]},[(!_vm.round)?_c(require('@/assets/check.svg'),{tag:"component",staticClass:"text-snow"}):_vm._e()],1)])]),_c('span',{staticClass:"ml-2 inline-block subhead"},[_vm._v(_vm._s(_vm.label))])])}
var staticRenderFns = []

export { render, staticRenderFns }