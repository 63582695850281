<template>
  <div class="bg-ice p-4 rounded-xl pl-8 xs:pl-6 flex items-start relative">
    <Letter
      class="absolute -top-3 -left-2 xs:top-0 xs:left-0 xs:relative"
      :letter="bulletin.user.firstName.charAt(0)"
    />

    <div class="ml-0 xs:ml-3 w-full">
      <div class="xs:flex items-center justify-between">
        <div>
          <h2 class="caption">
            {{ bulletin.user.firstName }} {{ bulletin.user.lastName }}
          </h2>

          <p class="text-void caption-2">
            {{ dayjs(bulletin.createdAt).fromNow() }}
          </p>
        </div>

        <div
          class="flex items-center space-x-1 mt-3 sm:mt-0"
          v-if="permissions.messageBoardItem.create"
        >
          <Button
            px="0"
            py="0"
            bg="ice"
            hover="snowball"
            pill
            @click="editBulletin(bulletin)"
          >
            <component
              class="w-5 h-5 text-void"
              :is="require('@/assets/pencil.svg')"
            />
          </Button>

          <Button
            px="0"
            py="0"
            bg="ice"
            hover="snowball"
            pill
            @click="deleteBulletin(bulletin)"
          >
            <component
              class="w-5 h-5 text-void"
              :is="require('@/assets/trash.svg')"
            />
          </Button>
        </div>
      </div>

      <div
        v-if="bulletin.translations[$i18n.locale]"
        class="content mt-3"
        v-html="bulletin.translations[$i18n.locale].content"
      />

      <p v-else class="content mt-3">
        {{ $t('general.translation_not_found') }}
      </p>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { mapState } from 'vuex'
import Letter from '@/components/Letter'

export default {
  name: 'Bulletin',
  components: {
    Letter
  },
  props: {
    bulletin: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dayjs
  }),
  computed: {
    ...mapState('Auth', ['permissions'])
  },
  created() {
    dayjs.extend(relativeTime)
  },
  methods: {
    editBulletin(bulletin) {
      this.$emit('editBulletin', bulletin)
    },
    deleteBulletin(bulletin) {
      this.$emit('deleteBulletin', bulletin)
    }
  }
}
</script>

<style lang="scss" scoped>
.content::v-deep a {
  @apply text-eiffel hover:text-tower;
}
</style>
