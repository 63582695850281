<template>
  <div>
    <Spinner v-if="loading" line-fg-color="#0667F9" />
    <p v-else class="text-void body" :class="align">
      {{ noneFound }}
    </p>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'

export default {
  name: 'PageLoading',
  components: {
    Spinner
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    align: {
      type: String,
      required: false,
      default: () => 'text-center'
    },
    noneFound: {
      type: String,
      required: true
    }
  }
}
</script>
