var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex list-overview",style:(_vm.height)},[(_vm.letterFilter)?_c('LetterFilter',{staticClass:"mt-10 overflow-y-auto max-h-screen pl-1 pr-4 mr-2",attrs:{"clickable":_vm.getClickableLetters(_vm.items)},on:{"letterClick":_vm.letterClick}}):_vm._e(),_c('div',{staticClass:"w-full overflow-y-auto"},[_c('div',{staticClass:"grid grid-cols-12 mb-2 grid-flow-col"},[_c('div',{class:[
          ("grid grid-cols-" + (_vm.headers.length) + " px-2"),
          {
            'col-span-10': _vm.buttons.length,
            'col-span-12': !_vm.buttons.length
          }
        ]},_vm._l((_vm.headers),function(header,i){return _c('p',{key:i,staticClass:"text-tire caption-1"},[_vm._v(" "+_vm._s(header.text)+" ")])}),0),(_vm.buttons.length)?_c('p',{staticClass:"text-tire caption-1 col-span-2 px-2"},[_vm._v(" "+_vm._s(_vm.$t('general.list.actions'))+" ")]):_vm._e()]),_c('ul',_vm._l((_vm.items),function(item,i){return _c('li',{key:i,staticClass:"grid grid-cols-12 rounded-sm overflow-hidden",class:[
          _vm.valueToFilter
            ? ("letter-" + (item[_vm.valueToFilter].charAt(0).toLowerCase()))
            : '',
          {
            'bg-snow': i % 2 === 1,
            'bg-ice': i % 2 === 0,
            'bg-tire text-snow': _vm.get(item, _vm.idPath) === _vm.selectedId,
            archived: item.archived
          }
        ]},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"hover:bg-eiffelLift px-2 cursor-pointer flex items-center transition-all duration-200",class:[
            ("grid grid-cols-" + (_vm.headers.length)),
            {
              'col-span-10': _vm.buttons.length,
              'col-span-12': !_vm.buttons.length,
              'py-5': !_vm.buttons.length,
              'py-3': _vm.buttons.length
            }
          ],on:{"click":function($event){return _vm.itemClick(item)}}},_vm._l((_vm.headers),function(header,i){return _c('div',{key:i,domProps:{"innerHTML":_vm._s(_vm.get(item, header.value))}})}),0),(_vm.buttons.length)?_c('div',{staticClass:"col-span-2 py-3 px-2 flex space-x-3"},_vm._l((_vm.buttons),function(button,i){return _c('Button',{key:i,staticClass:"min-w-8 h-8 flex-center transform hover:scale-105",class:("button-" + (button.icon)),attrs:{"disabled":!_vm.get(item, button.access),"px":"0","py":"0","pill":""},on:{"click":function($event){return button.click(item)}}},[_c(require(("@/assets/" + (button.icon) + ".svg")),{tag:"component",staticClass:"w-5 h-5"})],1)}),1):_vm._e()])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }