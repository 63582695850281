var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-snow overflow-y-scroll",class:{ 'md:bg-ice': _vm.isAuth }},[_c('div',{class:{ page: _vm.isAuth }},[(_vm.isAuth)?_c('TopNav',{attrs:{"title":_vm.$t('pages.privacy.title')}}):_c('div',[_c('BackButton',{staticClass:"-ml-6",attrs:{"bg":"snow","hover":"snow","back":"Login"}},[_vm._v(" "+_vm._s(_vm.$t('pages.privacy.back_to_login'))+" ")]),_c('h1',{staticClass:"title-1-bold md:bold-title sm:mt-6 mb-2 text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.privacy.title'))+" ")])],1),_c('div',{domProps:{"innerHTML":_vm._s(
        _vm.$t('privacy.policy', {
          organisationName: this.settings.organisationName,
          organisationEmail: this.settings.organisationEmail,
          organisationVatNumber: this.settings.organisationVatNumber,
          organisationAddress: this.settings.organisationAddress
        })
      )}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }