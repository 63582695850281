/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  console.log('PRODUCTION')
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('App is being served from cache by a service worker.\n')
    },
    registered() {},
    cached() {},
    updatefound() {},
    updated() {},
    offline() {},
    error(/* error */) {}
  })
}
