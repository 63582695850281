<template>
  <div v-if="category" class="background">
    <TopNav
      :title="
        category.translations[$i18n.locale]
          ? category.translations[$i18n.locale].name
          : $t('general.translation_not_found')
      "
      back-button
    />

    <div class="page">
      <div v-if="!loading && category">
        <ValidationObserver
          ref="form"
          tag="form"
          v-slot="{ invalid }"
          autocomplete="off"
          @submit.prevent="formSubmit"
        >
          <div>
            <p
              v-if="Object.keys(formData).length === 0"
              class="text-opal"
              v-html="$t('pages.faq_category.form.warning')"
            />

            <div class="space-y-4">
              <WhiteFormBox
                v-for="(item, key, i) in formData"
                :key="i"
                :label="
                  Object.keys(formData).length > 1
                    ? $t('general.translations')[key].label
                    : ''
                "
              >
                <template #buttons>
                  <Button
                    px="0"
                    py="0"
                    pill
                    bg="transparent"
                    hover="ice"
                    text="void"
                    @click="deleteTranslation(key)"
                  >
                    <component
                      :is="require('@/assets/trash.svg')"
                      class="w-6 h-6"
                    />
                  </Button>
                </template>

                <Input
                  v-model="formData[key].name"
                  :placeholder="$t('pages.faq_category.form.input.placeholder')"
                  :name="`name-${i}`"
                  :submitted="submitted"
                  rules="required"
                  :disabled="!permissions.faqItem.create"
                  @input="formChanged = true"
                />
              </WhiteFormBox>

              <div class="space-x-4 mt-5">
                <!-- Save button -->
                <Button
                  v-if="permissions.faqItem.create"
                  pill
                  upper
                  type="submit"
                  :disabled="invalid || !formChanged"
                  :loading="submitLoading"
                >
                  {{ $t('pages.faq_category.save') }}
                </Button>

                <!-- Add translation button -->
                <Button
                  v-if="
                    permissions.faqCategory.create && translations.length > 0
                  "
                  pill
                  bg="transparent"
                  hover="snowball"
                  text="void"
                  @click="languageModal = true"
                >
                  {{ $t('pages.faq_category.form.add_translation') }}
                </Button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <PageLoading
        v-else
        class="h-80"
        :loading="loading"
        :noneFound="$t('pages.faq_category.not_found')"
      />
    </div>

    <!-- Language Modal -->
    <LanguageModal
      v-if="languageModal"
      :items="translations"
      @close="languageModal = false"
      @confirm="addTranslation"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Input from '@/components/Input'
import cloneDeep from 'lodash.clonedeep'
import TopNav from '@/components/TopNav'
import PageLoading from '@/components/PageLoading'
import WhiteFormBox from '@/components/WhiteFormBox'
import LanguageModal from '@/components/modals/Language.modal'
import translations from '@/mixins/translations'

export default {
  name: 'FAQ',
  mixins: [translations],
  components: {
    Input,
    TopNav,
    PageLoading,
    WhiteFormBox,
    LanguageModal
  },
  data: () => ({
    loading: true,
    category: null,
    submitted: false,
    languageModal: false,
    formChanged: false,
    submitLoading: false,
    formData: {}
  }),
  computed: {
    ...mapState('Auth', ['permissions']),
    ...mapState('Settings', ['settings'])
  },
  created() {
    this.fetchCategory()
  },
  methods: {
    fetchCategory() {
      this.$http
        .get(this.$api.faq.category.replace('{id}', this.$route.params.id))
        .then(res => {
          this.category = res.data
          this.formData = cloneDeep(res.data.translations)
        })
        .finally(() => {
          this.loading = false
        })
    },
    addTranslation(lang) {
      this.languageModal = false

      if (!this.formData[lang]) {
        this.$set(this.formData, lang, {})
      } else {
        this.$toasted.error(this.$t('pages.faq.already_exists'))
      }
    },
    deleteTranslation(key) {
      this.formChanged = true
      this.$delete(this.formData, key)
    },
    formSubmit() {
      this.submitted = true

      this.$refs.form.validate().then(success => {
        if (success && this.formChanged) {
          this.submitLoading = true

          const url = this.$api.faq.edit_category.replace(
            '{id}',
            this.$route.params.id
          )

          this.$http
            .put(url, { translations: this.formData })
            .then(() => {
              this.$toasted.success(
                this.$t('pages.faq_category.messages.success.edit_category')
              )
              this.fetchCategory()
            })
            .catch(() => {
              this.$toasted.error(
                this.$t('pages.faq_category.messages.error.edit_category')
              )
            })
            .finally(() => {
              this.submitted = false
              this.formChanged = false
              this.submitLoading = false
            })
        }
      })
    }
  }
}
</script>
