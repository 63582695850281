var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"},{name:"ripple",rawName:"v-ripple",value:(!_vm.ripple && 'rgba(0, 0, 0, 0)'),expression:"!ripple && 'rgba(0, 0, 0, 0)'"}],class:[
    'text-14',
    'relative',
    'font-bold',
    'transition-all',
    'outline-none',
    'focus:outline-none',
    'transform',
    ("px-" + _vm.hPadding),
    ("py-" + _vm.py),
    _vm.disabled ? '' : ("hover:bg-" + _vm.hover),
    _vm.disabled ? 'text-void' : ("text-" + _vm.text),
    _vm.disabled ? 'bg-track' : ("bg-" + _vm.bg),
    {
      uppercase: _vm.upper,
      'rounded-full': _vm.pill
    }
  ],attrs:{"disabled":_vm.loading,"type":_vm.type},on:{"click":_vm.click}},[_c('div',{staticClass:"flex-center",class:_vm.loading ? 'opacity-0' : 'opacity-100'},[_vm._t("default")],2),_c('div',{staticClass:"absolute top-0 bottom-0 left-0 right-0 flex-center",class:_vm.loading ? 'opacity-100' : 'opacity-0'},[_c('Spinner',{attrs:{"color":_vm.spinnerColor}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }