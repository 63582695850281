import Dashboard from '@/views/dashboard/Dashboard'

export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      access: () => true
    }
  }
]
