<template>
  <div class="background">
    <TopNav :title="$t('pages.availabilities.title')" />

    <div class="page">
      <div>
        <div class="mb-10">
          <ButtonGroup
            :buttons="buttons"
            @buttonClick="button => (selected = button.value)"
          />

          <p class="footnote text-void mt-3 md:w-4/6">
            <span v-if="selected === 'default'">
              {{ $t('pages.availabilities.info_default') }}
            </span>

            <span v-if="selected === 'specific'">
              {{ $t('pages.availabilities.info_specific') }}
            </span>
          </p>
        </div>

        <!-- Default availabilities -->
        <DefaultAvailabilities v-if="selected === 'default'" />

        <!-- Specific availabilities -->
        <SpecificAvailabilities v-if="selected === 'specific'" />
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/TopNav'
import ButtonGroup from '@/components/ButtonGroup'
import DefaultAvailabilities from '@/views/availabilities/sections/DefaultAvailabilities'
import SpecificAvailabilities from '@/views/availabilities/sections/SpecificAvailabilities'

export default {
  name: 'Availabilities',
  components: {
    TopNav,
    ButtonGroup,
    DefaultAvailabilities,
    SpecificAvailabilities
  },
  data() {
    return {
      selected: 'default',
      buttons: [
        {
          text: this.$t('pages.availabilities.button_group.default'),
          value: 'default'
        },
        {
          text: this.$t('pages.availabilities.button_group.specific'),
          value: 'specific'
        }
      ]
    }
  }
}
</script>
