import axios from 'axios'
import api from '@/api'

export const namespaced = true

export const state = {
  users: [],
  user: null,
  addressChanged: false,
  statistics: null
}

export const mutations = {
  SET_USERS(state, users) {
    state.users = users
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_ADDRESS_CHANGED(state, payload) {
    state.addressChanged = payload
  },
  SET_STATISTICS(state, payload) {
    state.statistics = payload
  }
}

export const getters = {
  users(state) {
    return state.users.map(el => {
      el.fullName = `${el.firstName} ${el.lastName}`
      return el
    })
  }
}

export const actions = {
  fetchUsers({ commit }) {
    return axios.get(api.user.list).then(res => {
      commit('SET_USERS', res.data)
      return res
    })
  },
  fetchUsersAvailability(_, date) {
    const url = api.user.availabilities.replace('{date}', date)
    return axios.get(url)
  },
  fetchUser({ commit }, id) {
    return axios.get(api.user.user.replace('{id}', id)).then(res => {
      commit('SET_USER', res.data)
      return res
    })
  },
  archiveUser(_, userId) {
    return axios.post(api.user.archive.replace('{id}', userId))
  },
  unarchiveUser(_, userId) {
    return axios.post(api.user.unarchive.replace('{id}', userId))
  },
  setAddressChanged({ commit }, payload) {
    commit('SET_ADDRESS_CHANGED', payload)
  },
  setStatistics({ commit }, payload) {
    commit('SET_STATISTICS', payload)
  }
}
