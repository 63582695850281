import TermsAndConditions from '@/views/terms-and-conditions/TermsAndConditions'

export default [
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    meta: {
      requiresAuth: false,
      access: () => true
    }
  }
]
