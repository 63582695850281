var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-nav md:flex items-center justify-between w-full space-x-0 md:space-x-2 p-4 md:pt-6 md:pb-0 mb-4",class:{
    'bg-ice': _vm.subNav.length,
    'pb-0': !_vm.subNav.length
  }},[_c('div',[_c('div',{staticClass:"flex md:hidden",class:{
        'justify-end': !_vm.showLogo,
        'justify-between': _vm.showLogo
      }},[(_vm.showLogo)?_c(require('@/assets/logo-blue-text.svg'),{tag:"component",staticClass:"w-36"}):_vm._e(),_c('button',{staticClass:"p-2 mt-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.setNavigation(true)}}},[_c(require('@/assets/menu-icon.svg'),{tag:"component",staticClass:"w-8"})],1)],1),_c('div',{class:{
        'mt-0 xs:-mt-6 md:mt-0': !_vm.showLogo,
        'mt-6 md:mt-0': _vm.showLogo
      }},[_c('div',{staticClass:"flex items-start lg:items-center flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-4 lg:space-y-0"},[_c('div',[(_vm.me && _vm.showHello)?_c('p',{staticClass:"md:hidden text-void font-light"},[_vm._v(" "+_vm._s(_vm.$t('pages.dashboard.desc', { n: _vm.me.firstName }))+" ")]):_vm._e(),_c('h1',{staticClass:"title-1-bold md:bold-title"},[_c('span',{staticClass:"flex",class:{
                hidden: !_vm.showHello,
                'md:hidden': _vm.showHello
              }},[_vm._v(" "+_vm._s(_vm.$t('pages.dashboard.welcome'))+" "),_c(require('@/assets/hand.svg'),{tag:"component",staticClass:"w-8 h-8 mt-1 ml-3"})],1),_c('span',{class:{
                'hidden md:inline': _vm.showHello
              }},[_vm._v(" "+_vm._s(_vm.title)+" ")])])]),(_vm.subNav.length)?_c('ul',{staticClass:"space-y-4 md:hidden pt-4"},_vm._l((_vm.subNav),function(subItem,i){return _c('li',{key:i},[_c('router-link',{staticClass:"flex items-start space-x-3",attrs:{"to":{ name: subItem.to }}},[(subItem.icon)?_c(require(("@/assets/" + (subItem.icon) + ".svg")),{tag:"component",staticClass:"w-8 mt-1",class:subItem.iconColor}):_vm._e(),_c('div',[_c('p',{staticClass:"text-coal font-bold"},[_vm._v(_vm._s(subItem.label))]),_c('p',{staticClass:"text-coal"},[_vm._v(_vm._s(subItem.desc))])])],1)],1)}),0):_vm._e(),(_vm.label)?_c('p',{staticClass:"bg-track rounded-full caption-1 font-black uppercase text-tire px-4 py-1"},[_vm._v(" "+_vm._s(_vm.$t('pages.user.archived'))+" ")]):_vm._e()]),(_vm.backButton)?_c('Button',{staticClass:"px-4 py-2 mt-3 group",attrs:{"bg":"ice","text":"tire","hover":"snowball","pill":""},on:{"click":function($event){typeof _vm.backButton === 'string'
            ? _vm.$router.replace({ name: _vm.backButton })
            : _vm.$router.back()}}},[_c(require('@/assets/arrow-left.svg'),{tag:"component",staticClass:"h-6 mr-2 transition-all duration-200 ease-in-out transform group-hover:-translate-x-1"}),_c('p',[_vm._v(_vm._s(_vm.$t('pages.work_area.back')))])],1):_vm._e()],1)]),_c('div',{staticClass:"mt-4 md:mt-0"},[_vm._t("buttons")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }