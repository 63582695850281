var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{staticClass:"input",class:{
    'w-full': _vm.fullWidth
  },attrs:{"tag":"div","name":_vm.name,"rules":_vm.rules,"mask":"XXXX"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [(_vm.label)?_c('label',{staticClass:"font-normal text-12 block mb-2",attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.dropdown = false); }),expression:"() => (dropdown = false)"}],staticClass:"relative"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"},{name:"focus",rawName:"v-focus",value:(_vm.autoFocus),expression:"autoFocus"}],staticClass:"rounded-sm py-2 outline-none placeholder-void text-mine",class:[
        _vm.inputClass,
        {
          'bg-ice focus:bg-eiffel focus:bg-opacity-10 px-3':
            _vm.mode === 'default',
          'title-2 text-mine': _vm.mode === 'large',
          'text-center': _vm.center,
          'w-full': _vm.fullWidth
        }
      ],attrs:{"id":_vm.name,"type":_vm.type,"placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete,"tabindex":_vm.tabindex,"disabled":_vm.disabled},domProps:{"value":_vm.items.length ? _vm.selected : _vm.value},on:{"input":_vm.input,"focus":_vm.focus}}),(_vm.items.length)?_c(require('@/assets/chevron-down.svg'),{tag:"component",staticClass:"w-6 h-6 text-void absolute bottom-0 m-auto right-2 cursor-pointer transform transition-all duration-150 ease-out top-0",class:{
        'rotate-180': _vm.dropdown
      },on:{"click":function($event){_vm.dropdown = !_vm.dropdown}}}):_vm._e(),(_vm.dropdown && _vm.items.length)?_c('ul',{staticClass:"dropdown overflow-y-auto absolute w-full p-0 rounded-b-lg bg-snow border border-snowball border-t-0 z-50 shadow-xl"},[(_vm.items.length)?_c('div',_vm._l((_vm.items),function(item,i){return _c('li',{key:i,staticClass:"border-b border-ice px-3 py-2 cursor-pointer hover:bg-eiffel hover:bg-opacity-10",on:{"click":function($event){return _vm.itemClick(item)}}},[(item)?_c('p',[_vm._v(_vm._s(item[_vm.keyToShow]))]):_vm._e()])}),0):_c('li',{staticClass:"border-b border-ice px-3 py-2",on:{"click":function($event){_vm.dropdown = false}}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.no_options'))+" ")])]):_vm._e(),(_vm.icon)?_c('div',{staticClass:"absolute right-0 top-0 bottom-0 m-auto flex-center"},[_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"focus:outline-none rounded-full w-10 h-10 flex-center",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('iconClick')}}},[_c(require(("@/assets/" + _vm.icon + ".svg")),{tag:"component",staticClass:"text-void",class:{
            'w-6': _vm.mode === 'default',
            'w-8': _vm.mode === 'large'
          }})],1)]):_vm._e()],1),(errors[0] && _vm.submitted)?_c('p',{staticClass:"text-opal mt-1",class:{
      'text-12': _vm.mode === 'default',
      'text-15': _vm.mode === 'large'
    }},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }