<template>
  <Modal :title="$t('modals.fill_in_shift.title')">
    <!-- User select -->
    <div v-if="!partialConfirmModal">
      <SearchWithFilters
        v-model="search"
        :filters="filters"
        inputBg="bg-ice"
        class="mt-4 mb-5 space-y-3"
        hide-add-user-button
        hide-archived-filter
      />

      <List
        v-if="filtered.length && !usersLoading"
        :items="filtered"
        :headers="headers"
        :selectedId="selectedUserId"
        idPath="user.id"
        class="h-80"
        @itemClick="userClick"
      />

      <PageLoading
        v-else
        class="h-80"
        :loading="usersLoading"
        :noneFound="$t('modals.fill_in_shift.none_found')"
      />
    </div>

    <!-- Partial shift fill confirm -->
    <div v-if="partialConfirmModal">
      <p class="text-void body">
        {{
          $t('modals.fill_in_shift.partial_confirm.desc', {
            n: selectedUserName
          })
        }}
      </p>
    </div>

    <!-- Buttons -->
    <template #buttons>
      <template v-if="!partialConfirmModal">
        <!-- Submit -->
        <Button
          class="py-3 w-full sm:w-auto rounded-md ml-0 sm:ml-3"
          pill
          upper
          :loading="fullLoading"
          :disabled="!selectedUserId"
          @click="confirmClick"
        >
          {{ $t('modals.fill_in_shift.confirm') }}
        </Button>

        <!-- Cancel -->
        <Button
          class="py-3 w-full sm:w-auto rounded-md mt-3 sm:mt-0"
          pill
          upper
          text="tire"
          bg="snow"
          hover="ice"
          @click="$emit('close')"
        >
          {{ $t('modals.fill_in_shift.cancel') }}
        </Button>
      </template>

      <template v-if="partialConfirmModal">
        <div class="flex justify-between w-full">
          <!-- Back -->
          <Button
            class="py-3 w-full sm:w-auto rounded-md ml-0 sm:mr-3"
            pill
            upper
            text="tire"
            bg="snow"
            hover="ice"
            @click="onBackClick"
          >
            {{ $t('modals.fill_in_shift.partial_confirm.back') }}
          </Button>

          <div>
            <!-- Partial confirm -->
            <Button
              class="py-3 w-full sm:w-auto rounded-md ml-0 sm:ml-3"
              pill
              upper
              :loading="partialLoading"
              @click="partialConfirm"
            >
              {{ $t('modals.fill_in_shift.partial_confirm.partial_confirm') }}
            </Button>

            <!-- Full confirm -->
            <Button
              class="py-3 w-full sm:w-auto rounded-md ml-0 sm:ml-3"
              pill
              upper
              :loading="fullLoading"
              @click="fullConfirm"
            >
              {{ $t('modals.fill_in_shift.partial_confirm.full_confirm') }}
            </Button>
          </div>
        </div>
      </template>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { reverseDate } from '@/scripts/dates'
import Modal from '@/components/Modal'
import SearchWithFilters from '@/components/SearchWithFilters'
import List from '@/components/List'
import PageLoading from '@/components/PageLoading'

export default {
  name: 'FillInShift',
  components: {
    Modal,
    SearchWithFilters,
    List,
    PageLoading
  },
  props: {
    fullLoading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    partialLoading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    shift: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      search: '',
      filters: {
        students: false,
        flexis: false
      },
      headers: [
        {
          text: this.$t('modals.fill_in_shift.list.name'),
          value: 'fullName'
        },
        {
          text: this.$t('modals.fill_in_shift.list.status'),
          value: 'statusText'
        }
      ],
      users: [],
      usersLoading: true,

      selectedUserId: null,
      selectedUserName: null,
      selectedUserStatus: null,

      partialConfirmModal: false
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.selectedUserId = null
      }
    }
  },
  computed: {
    ...mapState('Planning', ['date']),

    filtered() {
      let arr = this.users

      if (this.search) {
        arr = this.users.filter(el =>
          el.fullName.toLowerCase().includes(this.search.toLowerCase())
        )
      }

      if (this.filters.students && !this.filters.flexis) {
        arr = arr.filter(el => el.user.employeeData.contractType === 'student')
      }

      if (this.filters.flexis && !this.filters.students) {
        arr = arr.filter(el => el.user.employeeData.contractType === 'flexi')
      }

      return arr.map(el => {
        el.fullName = `
            <div class="flex items-center">
              <div class="w-3 h-3 bg-${this.getColor(
                el
              )} rounded-full mr-3"></div>
              <p>${el.user.firstName} ${el.user.lastName}</p>
            </div>
          `
        return el
      })
    }
  },
  created() {
    const date = reverseDate(this.date)
    this.fetchAvailabilities({ date, shiftId: this.shift.id })
      .then(res => {
        this.users = res.data.map(el => {
          el.statusText = `
            <p class="text-${this.getColor(el)}">
              ${this.getTranslations(el)}
            </p>
          `

          return el
        })
      })
      .finally(() => {
        this.usersLoading = false
      })
  },
  methods: {
    ...mapActions('Planning', ['fetchAvailabilities']),

    getTranslations(user) {
      switch (user.status) {
        case 'unavailable':
          return this.$t('modals.fill_in_shift.list.statuses.unavailable')

        case 'available':
          return this.$t('modals.fill_in_shift.list.statuses.available')

        case 'partial_available':
          return this.$t('modals.fill_in_shift.list.statuses.partial', {
            from: user.partialMatch.start,
            to: user.partialMatch.end
          })

        default:
          return this.$t('modals.fill_in_shift.list.statuses.unknown')
      }
    },
    getColor(user) {
      switch (user.status) {
        case 'unavailable':
          return 'opal'

        case 'available':
          return 'apple'

        case 'partial_available':
          return 'coral'
      }
    },
    userClick(user) {
      this.selectedUserId = user.user.id
      this.selectedUserName = user.user.firstName

      if (user.status) {
        this.selectedUserStatus = user.status
      }
    },
    confirmClick() {
      if (this.selectedUserId) {
        if (this.selectedUserStatus === 'partial_available') {
          this.partialConfirmModal = true
        } else {
          this.$emit('confirm', { userId: this.selectedUserId, partial: false })
        }
      }
    },
    onBackClick() {
      this.selectedUserId = null
      this.selectedUserName = null
      this.selectedUserStatus = null
      this.partialConfirmModal = false
    },
    fullConfirm() {
      this.$emit('confirm', { userId: this.selectedUserId, partial: false })
    },
    partialConfirm() {
      this.$emit('confirm', { userId: this.selectedUserId, partial: true })
    }
  }
}
</script>
