<template>
  <Loader
    line-bg-color="transparent"
    :line-fg-color="spinnerColor"
    :line-size="4"
    :size="size"
  />
</template>

<script>
import Loader from 'vue-simple-spinner'

export default {
  name: 'Spinner',
  components: {
    Loader
  },
  props: {
    color: {
      type: String,
      required: false,
      default: () => 'snow'
    },
    size: {
      type: Number,
      required: false,
      default: () => 28
    }
  },
  computed: {
    spinnerColor() {
      switch (this.color) {
        case 'snow':
          return '#FFFFFF'
      }

      return '#0667F9'
    }
  }
}
</script>
