import Vue from 'vue'

Vue.directive('focus', {
  inserted: function(el, binding) {
    const value = binding.value
    if (value) {
      setTimeout(() => {
        el.focus()
      }, 500)
    }
  }
})
