export const namespaced = true

export const state = {
  open: false,
  component: '',
  closable: false,
  meta: null,
  md: 768
}

export const mutations = {
  SET_PANEL(state, payload) {
    state.component = payload.component
    state.closable = payload.closable
    state.meta = payload.meta
  },
  OPEN_PANEL() {
    state.open = open
  },
  CLOSE_PANEL(state) {
    state.open = false
  }
}

export const actions = {
  setPanel({ commit, state }, payload) {
    if (payload) {
      commit('SET_PANEL', payload)
    }

    if (!payload) {
      commit('SET_PANEL', {
        component: state.component,
        closable: state.closable,
        meta: state.meta
      })
    }

    if (window.innerWidth >= state.md) {
      commit('OPEN_PANEL')
    }
  },
  closePanel({ commit }) {
    commit('CLOSE_PANEL')
  }
}
