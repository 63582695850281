<template>
  <Modal
    :title="$t('modals.templates.delete_confirm.title')"
    :desc="
      $t('modals.templates.delete_confirm.desc', {
        n: templateName
      })
    "
    icon="exclamation"
    iconColor="opal"
  >
    <template #buttons>
      <!-- Submit -->
      <Button
        class="py-3 w-full sm:w-auto rounded-md ml-0 sm:ml-3"
        pill
        upper
        :loading="loading"
        bg="opal"
        hover="ruby"
        @click="$emit('confirm')"
      >
        {{ $t('modals.templates.delete_confirm.confirm') }}
      </Button>

      <!-- Cancel -->
      <Button
        class="py-3 w-full sm:w-auto rounded-md mt-3 sm:mt-0"
        pill
        upper
        text="tire"
        bg="snow"
        hover="ice"
        @click="$emit('close')"
      >
        {{ $t('modals.templates.delete_confirm.cancel') }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'DeleteTemplate',
  components: {
    Modal
  },
  props: {
    template: {
      Type: String,
      required: true
    },
    loading: {
      Type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    templateName() {
      if (this.template.name) {
        return this.template.name
      }

      if (this.template.translations[this.$i18n.locale]) {
        return this.template.translations[this.$i18n.locale].name
      }

      return this.$t('general.translation_not_found')
    }
  }
}
</script>
