<template>
  <div class="background">
    <TopNav
      :title="
        workArea.translations[$i18n.locale]
          ? workArea.translations[$i18n.locale].name
          : $t('general.translation_not_found')
      "
      back-button="WorkAreas"
    >
      <template #buttons>
        <!-- Delete button -->
        <Button
          v-if="workArea.metadata.security.delete"
          pill
          upper
          px="0"
          py="0"
          @click="deleteModal = true"
        >
          <component class="w-5 h-5" :is="require('@/assets/trash.svg')" />
        </Button>
      </template>
    </TopNav>

    <div class="page">
      <div>
        <ValidationObserver
          v-if="workArea"
          ref="form"
          tag="form"
          v-slot="{ invalid }"
          autocomplete="off"
          @submit.prevent="formSubmit"
        >
          <div class="space-y-4">
            <p
              v-if="Object.keys(formData).length === 0"
              class="text-opal"
              v-html="$t('pages.work_area.form.warning')"
            />

            <WhiteFormBox
              v-for="(item, key, i) in formData"
              :key="i"
              :label="
                Object.keys(formData).length > 1
                  ? $t('general.translations')[key].label
                  : ''
              "
            >
              <template #buttons>
                <Button
                  px="0"
                  py="0"
                  pill
                  bg="transparent"
                  hover="ice"
                  text="void"
                  @click="deleteTranslation(key)"
                >
                  <component
                    :is="require('@/assets/trash.svg')"
                    class="w-6 h-6"
                  />
                </Button>
              </template>

              <Input
                v-model="formData[key].name"
                :placeholder="
                  $t(`pages.work_area.translations.${key}.placeholder`)
                "
                :name="`name-${i}`"
                :submitted="submitted"
                rules="required"
                :disabled="!workArea.metadata.security.edit"
                @input="formChanged = true"
              />
            </WhiteFormBox>
          </div>

          <!-- Add translation button -->
          <div class="space-y-4 md:space-y-0 md:space-x-4 mt-5">
            <!-- Save button -->
            <Button
              v-if="workArea.metadata.security.edit"
              pill
              upper
              type="submit"
              :disabled="invalid || !formChanged"
              :loading="submitLoading"
            >
              {{ $t('pages.work_area.save') }}
            </Button>

            <Button
              v-if="workArea.metadata.security.edit && translations.length > 0"
              pill
              bg="transparent"
              hover="snowball"
              text="void"
              @click="openLanguageModal"
            >
              {{ $t('pages.work_area.form.add_translation') }}
            </Button>
          </div>
        </ValidationObserver>

        <PageLoading
          v-else
          class="mt-10"
          :loading="loading"
          :noneFound="$t('pages.work_area.not_found')"
        />
      </div>
    </div>

    <!-- Delete workarea modal -->
    <DeleteConfirm
      v-if="deleteModal && workArea"
      :title="$t('modals.work_areas.delete_confirm.title')"
      :desc="
        $t('modals.work_areas.delete_confirm.desc', {
          n: workArea.translations[$i18n.locale].name
        })
      "
      :loading="deleteLoading"
      :cancel-text="$t('modals.work_areas.delete_confirm.cancel')"
      :confirm-text="$t('modals.work_areas.delete_confirm.confirm')"
      @close="deleteModal = false"
      @confirm="deleteWorkArea"
    />

    <!-- Language Modal -->
    <LanguageModal
      v-if="modal"
      :items="translations"
      @close="modal = false"
      @confirm="addTranslation"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import WhiteFormBox from '@/components/WhiteFormBox'
import Input from '@/components/Input'
import TopNav from '@/components/TopNav'
import PageLoading from '@/components/PageLoading'
import LanguageModal from '@/components/modals/Language.modal'
import DeleteConfirm from '@/components/modals/DeleteConfirm.modal'
import { mapState } from 'vuex'
import translations from '@/mixins/translations'

export default {
  name: 'WorkArea',
  mixins: [translations],
  components: {
    WhiteFormBox,
    Input,
    TopNav,
    PageLoading,
    LanguageModal,
    DeleteConfirm
  },
  data: () => ({
    loading: true,
    workArea: null,
    modal: false,
    selectedLanguage: '',
    submitted: false,
    submitLoading: false,
    formChanged: false,

    deleteModal: false,
    deleteLoading: false,

    formData: {}
  }),
  created() {
    this.fetchWorkArea()
  },
  computed: {
    ...mapState('Settings', ['settings'])
  },
  methods: {
    fetchWorkArea() {
      this.$http
        .get(this.$api.work_area.single.replace('{id}', this.$route.params.id))
        .then(res => {
          this.workArea = res.data
          this.formData = cloneDeep(res.data.translations)
        })
        .finally(() => (this.loading = false))
    },
    openLanguageModal() {
      this.modal = true
    },
    addTranslation(lang) {
      this.modal = false

      if (!this.formData[lang]) {
        this.$set(this.formData, lang, {})
      } else {
        this.$toasted.error(this.$t('pages.work_area.already_exists'))
      }
    },
    deleteTranslation(key) {
      this.formChanged = true
      this.$delete(this.formData, key)
    },
    deleteWorkArea() {
      this.deleteLoading = true

      this.$http
        .delete(this.$api.work_area.delete.replace('{id}', this.workArea.id))
        .then(() => {
          this.$toasted.success(this.$t('pages.work_areas.delete.success'))
          this.$router.back()
        })
        .finally(() => {
          this.deleteLoading = false
          this.deleteModal = false
        })
    },
    formSubmit() {
      this.submitted = true

      this.$refs.form.validate().then(success => {
        if (success && this.formChanged) {
          this.submitLoading = true

          this.$http
            .put(
              this.$api.work_area.edit_full.replace(
                '{id}',
                this.$route.params.id
              ),
              { translations: this.formData }
            )
            .then(() => {
              this.$toasted.success(this.$t('pages.work_area.update_success'))
              this.fetchWorkArea()
            })
            .catch(() => {
              this.$toasted.error(this.$t('general.error_occurred'))
            })
            .finally(() => {
              this.submitted = false
              this.formChanged = false
              this.submitLoading = false
            })
        }
      })
    }
  }
}
</script>
