<template>
  <div>
    <div
      class="flex items-center justify-between sm:justify-start sm:space-x-4"
    >
      <div
        v-for="(weekday, i) in weekdays"
        :key="i"
        v-ripple
        class="w-10 h-10 rounded-full flex-center cursor-pointer relative"
        :class="{
          'bg-eiffel text-snow': selectedWeekday === i + 1,
          'hover:bg-snowball': selectedWeekday !== i + 1
        }"
        @click="dayClick(weekday, i)"
      >
        {{ weekday.slug }}

        <div
          v-if="weekday.bg"
          class="absolute w-2 h-2 -bottom-1 rounded-full border border-snow"
          :class="weekday.bg"
        />
      </div>
    </div>

    <div v-if="!fetching" class="mt-10 lg:w-6/12">
      <AvailabilityBox
        :availability="availability"
        :createUrl="
          $api.availabilities.create_day_of_week.replace(
            '{day}',
            selectedWeekday
          )
        "
        :updateUrl="
          $api.availabilities.edit_day_of_week.replace('{day}', selectedWeekday)
        "
        :createSuccessMessage="
          $t('pages.availabilities.default.messages.success.created')
        "
        :updateSuccessMessage="
          $t('pages.availabilities.default.messages.success.updated')
        "
        :createErrorMessage="
          $t('pages.availabilities.default.messages.error.created')
        "
        :updateErrorMessage="
          $t('pages.availabilities.default.messages.error.updated')
        "
        @availabilityUpdated="fetchWeek"
      />
    </div>

    <PageLoading
      v-else
      class="h-80"
      :loading="loading"
      :noneFound="
        $t('pages.availabilities.default.messages.error.fetching_day')
      "
    />
  </div>
</template>

<script>
import PageLoading from '@/components/PageLoading'
import AvailabilityBox from '@/components/AvailabilityBox'

export default {
  name: 'DefaultAvailabilities',
  components: {
    PageLoading,
    AvailabilityBox
  },
  data() {
    return {
      weekdays: this.$t('general.weekdays'),
      selectedWeekday: null,
      fetching: false,
      weekData: []
    }
  },
  created() {
    this.fetchWeek()
  },
  computed: {
    availability() {
      const availability = this.weekData.find(
        el => el.dayOfWeek === this.selectedWeekday
      )

      if (availability) {
        availability.desc = this.weekdays[availability.dayOfWeek - 1].text
      }

      return availability
    }
  },
  methods: {
    fetchWeek() {
      this.$http.get(this.$api.availabilities.list_days_of_week).then(res => {
        this.weekData = res.data

        if (!this.selectedWeekday) {
          this.selectedWeekday = 1
        }

        for (const day of res.data) {
          this.weekdays[day.dayOfWeek - 1].bg = this.getDotColor(day.state)
        }
      })
    },
    getDotColor(type) {
      if (type === 'available') return 'bg-eiffel'
      if (type === 'partial_available') return 'bg-eiffelLift'
      if (type === 'unavailable') return 'bg-opal'
    },
    dayClick(day, i) {
      this.selectedWeekday = i + 1
    }
  }
}
</script>
