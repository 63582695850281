import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
import dashboardRoutes from './routes/dashboard.routes'
import authRoutes from './routes/auth.routes'
import userRoutes from './routes/users.routes'
import planningRoutes from './routes/planning.routes'
import CommunicationRoutes from './routes/communication.routes'
import WorkAreasRoutes from './routes/workareas.routes'
import templatesRoutes from './routes/templates.routes'
import availabilitiesRoutes from './routes/availabilities.routes'
import FAQRoutes from './routes/faq.routes'
import TermsAndConditionsRoutes from './routes/terms-and-conditions.routes'
import PrivacyRoutes from './routes/privacy.routes'
import HoursRoutes from './routes/hours.routes'

Vue.use(VueRouter)

const routes = [].concat(
  dashboardRoutes,
  authRoutes,
  userRoutes,
  planningRoutes,
  CommunicationRoutes,
  WorkAreasRoutes,
  templatesRoutes,
  availabilitiesRoutes,
  FAQRoutes,
  TermsAndConditionsRoutes,
  PrivacyRoutes,
  HoursRoutes
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const access = to.matched.some(record => record.meta.access())
  const token = store.state.Auth.jwtToken
  const jwtData = store.state.Auth.jwtData
  const permissions = store.state.Auth.permissions

  store.dispatch('Panel/setPanel', { component: '', closable: false })
  store.dispatch('Panel/closePanel')

  // If path is empty, route to login or dashboard page.
  if (!to.name) {
    if (!token) {
      next({ name: 'Login' })
    } else {
      if (permissions.planningShift.managePlanning) {
        next({ name: 'Planning' })
      } else {
        next({ name: 'Dashboard' })
      }
    }
  }

  // Check if route requires Auth
  if (requiresAuth) {
    if (!token) {
      next({ name: 'Login' })
    } else {
      if (jwtData.two_fa_in_progress && to.name !== 'TwoFA') {
        next({ name: 'TwoFA' })
      } else {
        if (!access) {
          next({ name: 'Dashboard' })
        } else {
          next()
        }
      }
    }
  } else {
    next()
  }
})

export default router
