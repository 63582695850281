<template>
  <div>
    <h2 class="title-2 mb-3">
      {{ $t('pages.user.administration.title') }}
    </h2>

    <div class="bg-snow px-0 py-6 md:p-6 rounded-lg space-y-3">
      <Input
        v-model="user.email"
        :label="$t('pages.user.administration.email.label')"
        :placeholder="$t('pages.user.administration.email.placeholder')"
        :submitted="submitted"
        name="email"
      />

      <Input
        v-model="user.employeeData.phoneNumber"
        :label="$t('pages.user.administration.phone.label')"
        :placeholder="$t('pages.user.administration.phone.placeholder')"
        :submitted="submitted"
        name="phone"
        mask="+## ### ## ## ##"
        type="tel"
      />

      <Input
        v-model="user.employeeData.accountNumber"
        :label="$t('pages.user.administration.billing.label')"
        :placeholder="$t('pages.user.administration.billing.placeholder')"
        :submitted="submitted"
        name="billing"
        mask="BE## #### #### ####"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/Input'

export default {
  name: 'Administration',
  components: {
    Input
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    submitted: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
