export const namespaced = true

export const state = {
  isMobile: false,
  md: 768
}

export const mutations = {
  SET_MOBILE(state, payload) {
    state.isMobile = payload
  }
}

export const actions = {
  setMobile({ commit }, payload) {
    commit('SET_MOBILE', payload)
  }
}
