<template>
  <div class="background">
    <TopNav
      :title="
        template.translations[$i18n.locale]
          ? template.translations[$i18n.locale].name
          : $t('general.translation_not_found')
      "
      back-button="Templates"
    >
      <template #buttons>
        <div class="flex space-x-2 lg:space-x-3 mt-4 lg:mt-0">
          <!-- Add shift -->
          <Button py="0" pill upper @click="shiftModal = 'add'">
            {{ $t('pages.template.add_shift') }}
          </Button>

          <!-- Delete button -->
          <Button
            v-if="template.metadata.security.delete"
            px="0"
            py="0"
            pill
            upper
            type="button"
            :tooltip="$t('tooltips.templates.delete_template')"
            @click="deleteModal = true"
          >
            <component class="w-5 h-5" :is="require('@/assets/trash.svg')" />
          </Button>
        </div>
      </template>
    </TopNav>

    <div class="page">
      <!-- Header with back button -->
      <div
        v-if="template && !loading"
        class="overflow-y-auto h-full flex flex-col"
      >
        <Planner
          ref="planner"
          :rows="workAreas"
          :shift="shift"
          :shifts="template.shifts"
          :loading="addOrEditShiftLoading"
          :can-create="permissions.templateShift.create"
          @shiftCreateOrClick="shiftModal = true"
          @shiftResizedOrMoved="updateShift"
          @shiftDeleted="deleteShift"
        />
      </div>

      <PageLoading
        v-else
        :loading="loading"
        :noneFound="$t('pages.template.not_found')"
        class="mt-10"
      />
    </div>

    <!-- Shift modal -->
    <Shift
      v-if="shiftModal"
      :shift="shift"
      :loading="addOrEditShiftLoading"
      @close="closeShiftModal"
      @confirm="shiftModalSubmit"
    />

    <!-- Delete template modal -->
    <DeleteTemplate
      v-if="deleteModal && template"
      :loading="deleteLoading"
      :template="template"
      @close="deleteModal = false"
      @confirm="deleteTemplate"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { IShift } from '@/interfaces'
import Modal from '@/components/Modal'
import cloneDeep from 'lodash.clonedeep'
import TopNav from '@/components/TopNav'
import Planner from '@/components/Planner'
import BackButton from '@/components/BackButton'
import PageLoading from '@/components/PageLoading'
import Shift from '@/components/modals/Shift.modal'
import DeleteTemplate from '@/components/modals/DeleteTemplate.modal'

export default {
  name: 'Template',
  components: {
    Shift,
    Modal,
    TopNav,
    Planner,
    BackButton,
    PageLoading,
    DeleteTemplate
  },
  data: () => ({
    loading: true,

    shiftModal: false,
    addOrEditShiftLoading: false,

    deleteModal: false,
    deleteLoading: false,

    shift: null
  }),
  computed: {
    ...mapState('WorkAreas', ['workAreas']),
    ...mapState('Templates', ['template']),
    ...mapState('Auth', ['permissions']),

    topHeight() {
      return this.$refs.top && this.$refs.top.clientHeight
    },
    id() {
      return this.$route.params.id
    }
  },
  created() {
    this.shift = cloneDeep(IShift)
    this.$store
      .dispatch('Templates/fetchTemplate', this.$route.params.id)
      .then(() => {
        this.$store.dispatch('Panel/setPanel', {
          component: 'EditTemplateLanguages',
          closable: false
        })

        return this.$store.dispatch('WorkAreas/fetchWorkAreas')
      })
      .finally(() => {
        //repeat check - on app.vue value stil null
        const topNav = document.querySelector('.top-nav')
        const page = document.querySelector('.page')
        if (page && topNav) {
          page.setAttribute(
            'style',
            `height: calc(100vh - 1rem - ${topNav.clientHeight}px)`
          )
        }
        this.loading = false
      })
  },
  methods: {
    deleteTemplate() {
      this.deleteLoading = true

      this.$store.dispatch('Templates/deleteTemplate', this.id).then(() => {
        this.deleteLoading = false
        this.$router.back()
      })
    },
    shiftModalSubmit() {
      if (!this.shift.id) {
        this.createShift(this.shift)
      }

      if (this.shift.id) {
        this.updateShift(this.shift)
      }
    },
    createShift(shift) {
      this.addOrEditShiftLoading = true

      this.$store
        .dispatch('Templates/addShift', { id: this.id, shift })
        .then(() => {
          this.$toasted.success(this.$t('pages.template.messages.add_success'))
          return this.$store.dispatch('Templates/fetchTemplate', this.id)
        })
        .then(() => {
          this.$refs.planner.addModal = false
          this.$refs.planner.loadShifts()
        })
        .catch(() => {
          this.$toasted.error(this.$t('general.error_occurred'))
        })
        .finally(() => {
          this.addOrEditShiftLoading = false
          this.closeShiftModal()
        })
    },
    updateShift(shift) {
      this.addOrEditShiftLoading = true

      this.$store
        .dispatch('Templates/editShift', { id: this.id, shift })
        .then(() => {
          this.$toasted.success(this.$t('pages.template.messages.edit_success'))
          return this.$store.dispatch('Templates/fetchTemplate', this.id)
        })
        .then(() => {
          this.$refs.planner.loadShifts()
        })
        .catch(() => {
          this.$toasted.error(this.$t('general.error_occurred'))
        })
        .finally(() => {
          this.addOrEditShiftLoading = false
          this.closeShiftModal()
        })
    },
    deleteShift(shift) {
      this.$store
        .dispatch('Templates/deleteShift', { id: this.id, shiftId: shift.id })
        .then(() => {
          this.$toasted.success(
            this.$t('pages.template.messages.delete_success')
          )
          return this.$store.dispatch('Templates/fetchTemplate', this.id)
        })
        .then(() => {
          this.$refs.planner.loadShifts()
        })
    },
    closeShiftModal() {
      this.shift = cloneDeep(IShift)
      this.shiftModal = ''
    }
  }
}
</script>
