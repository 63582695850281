<template>
  <ValidationObserver
    ref="form"
    tag="form"
    class="flex flex-col justify-between h-full"
    v-slot="{ invalid }"
    @submit.prevent="submit"
  >
    <RegistrationHeader
      :title="$t('registration.password_repeat.title')"
      :step="5"
      :steps="5"
      class="mb-8"
    />

    <div>
      <Input
        v-model="passwordRepeat"
        name="passwordRepeat"
        rules="required|min:8|max:72"
        mode="large"
        :type="show ? 'text' : 'password'"
        :icon="show ? 'eye-off' : 'eye'"
        :placeholder="$t('registration.password_repeat.placeholder')"
        :submitted="submitted"
        autoFocus
        @iconClick="show = !show"
      />

      <p v-if="error" class="text-opal">
        {{ error }}
      </p>
    </div>

    <Button
      class="mt-10 self-center"
      pill
      upper
      :loading="loading"
      :disabled="invalid"
      type="submit"
    >
      {{ $t('registration.password_repeat.next') }}
    </Button>
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex'
import Input from '@/components/Input'
import RegistrationHeader from '@/components/RegistrationHeader'

export default {
  name: 'PasswordRepeat',
  components: {
    Input,
    RegistrationHeader
  },
  data: () => ({
    show: false,
    submitted: false,
    loading: false
  }),
  computed: {
    error() {
      return this.$store.state.Registration.error
    },
    formData() {
      return this.$store.state.Registration.formData
    },
    jwtData() {
      return this.$store.state.Auth.jwtData
    },
    passwordRepeat: {
      get() {
        return this.$store.state.Registration.formData.passwordRepeat
      },
      set(val) {
        this.setFormData({ passwordRepeat: val })
      }
    }
  },
  methods: {
    ...mapActions('Registration', ['setFormData']),

    submit() {
      this.submitted = true

      // If passwords don't match.
      if (this.formData.password !== this.formData.passwordRepeat) {
        // Set reverted transition.
        this.$store.dispatch('Transitions/setReversed', true)

        // Clear passwords.
        this.$store.dispatch('Registration/setFormData', {
          password: '',
          passwordRepeat: ''
        })

        // Set error to show in prev step.
        this.$toasted.error(this.$t('registration.passwords_do_not_match'))

        this.$router.replace({ name: 'Registration.password' })

        return
      }

      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading = true

          // Register user.
          this.$http
            .post(this.$api.auth.register, this.formData)
            .then(res => {
              // If OK, login user.
              if (res.status === 200) {
                this.$http
                  .post(this.$api.auth.login, {
                    username: this.formData.email,
                    password: this.formData.password
                  })
                  .then(loginRes => {
                    this.$store.dispatch(
                      'Auth/setJwtToken',
                      loginRes.data.token
                    )
                    this.$store.dispatch(
                      'Auth/setRefreshToken',
                      loginRes.data.refreshToken
                    )

                    if (this.jwtData.two_fa_in_progress) {
                      this.$router.push({ name: 'TwoFA' })
                    } else {
                      this.$router.push({ name: 'Dashboard' })
                    }
                  })
              }
            })
            .catch(err => {
              // Set reverted transition.
              this.$store.dispatch('Transitions/setReversed', true)

              // Clear passwords.
              this.$store.dispatch('Registration/setFormData', {
                password: '',
                passwordRepeat: ''
              })

              // Set error message from API.
              if (err.response.data[0]) {
                this.$toasted.error(err.response.data[0].message)
              }

              // Navigate to first error in API response.
              this.$router.replace({
                name: `Registration.${err.response.data[0].property_path}`
              })
            })
            .finally(() => (this.loading = false))
        }
      })
    }
  }
}
</script>
