<template>
  <button
    v-tooltip="tooltip"
    v-ripple="!ripple && 'rgba(0, 0, 0, 0)'"
    :disabled="loading"
    :type="type"
    :class="[
      'text-14',
      'relative',
      'font-bold',
      'transition-all',
      'outline-none',
      'focus:outline-none',
      'transform',
      `px-${hPadding}`,
      `py-${py}`,
      disabled ? '' : `hover:bg-${hover}`,
      disabled ? 'text-void' : `text-${text}`,
      disabled ? 'bg-track' : `bg-${bg}`,
      {
        uppercase: upper,
        'rounded-full': pill
      }
    ]"
    @click="click"
  >
    <div class="flex-center" :class="loading ? 'opacity-0' : 'opacity-100'">
      <slot />
    </div>

    <div
      class="absolute top-0 bottom-0 left-0 right-0 flex-center"
      :class="loading ? 'opacity-100' : 'opacity-0'"
    >
      <Spinner :color="spinnerColor" />
    </div>
  </button>
</template>

<script>
import Spinner from '@/components/Spinner'

export default {
  name: 'Button',
  components: {
    Spinner
  },
  props: {
    pill: {
      type: Boolean,
      required: false,
      default: false
    },
    upper: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    bg: {
      type: String,
      required: false,
      default: () => 'eiffel'
    },
    text: {
      type: String,
      required: false,
      default: () => 'snow'
    },
    tooltip: {
      type: String,
      required: false,
      default: () => ''
    },
    hover: {
      type: String,
      required: false,
      default: () => 'tower'
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    px: {
      type: String,
      required: false,
      default: () => '5'
    },
    py: {
      type: String,
      required: false,
      default: () => '3'
    },
    type: {
      type: String,
      required: false,
      default: () => 'button'
    },
    ripple: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  computed: {
    hPadding() {
      return this.disabled ? this.px : parseInt(this.px) + 1
    },
    spinnerColor() {
      switch (this.bg) {
        case 'snow':
          return 'eiffel'
      }

      return 'snow'
    }
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
button {
  min-height: 2.5rem;
  min-width: 2.5rem;
}
</style>
