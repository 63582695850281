<template>
  <label
    class="relative flex items-center cursor-pointer"
    @click="$emit('click')"
  >
    <div class="group">
      <input
        type="checkbox"
        :checked="value"
        class="w-5 h-5 absolute z-10 opacity-0 cursor-pointer"
        @input="e => $emit('input', e.target.checked)"
      />

      <div
        class="border-2 border-tire w-5 h-5 relative overflow-hidden transition-all duration-200"
        :class="{
          [`border-${color}`]: value,
          [`bg-${color}`]: value && !round,
          'group-hover:bg-track': !value,
          'rounded-sm': !round,
          'rounded-full': round
        }"
        @click="$emit('input', !value)"
      >
        <div
          class="absolute top-0 left-0 right-0 bottom-0 transition-all duration-100 m-auto"
          :class="[
            `bg-${color}`,
            {
              'w-full h-full': value && !round,
              'inner-round rounded-full': value && round,
              'w-0 h-0 rounded-sm': !value
            }
          ]"
        >
          <component
            v-if="!round"
            class="text-snow"
            :is="require('@/assets/check.svg')"
          />
        </div>
      </div>
    </div>

    <span class="ml-2 inline-block subhead">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    color: {
      type: String,
      required: false,
      default: () => 'eiffel'
    },
    round: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>

<style lang="scss" scoped>
.inner-round {
  width: 0.65rem;
  height: 0.65rem;
}
</style>
