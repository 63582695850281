<template>
  <div class="background">
    <TopNav :title="$t('pages.faq_overview.title')">
      <template #buttons>
        <Button
          v-if="permissions.faqCategory.create"
          pill
          upper
          :disabled="open && component ? true : false"
          @click="onAddCategory"
        >
          {{ $t('pages.faq_overview.add_category.title') }}
        </Button>
      </template>
    </TopNav>

    <div class="page">
      <div v-if="!loading && categories.length" class="space-y-4">
        <div v-for="(category, i) in categories" :key="i">
          <div class="flex items-center space-x-4">
            <!-- CATEGORY -->
            <DropdownBox
              :title="
                category.translations[$i18n.locale]
                  ? category.translations[$i18n.locale].name
                  : $t('general.translation_not_found')
              "
              :open="selectedCategoryId === category.id"
              :hide-arrow="category.items.length === 0"
              title-class="body"
              @click="onCategoryClick(category)"
            >
              <template #buttons v-if="permissions.faqCategory.create">
                <!-- Edit button -->
                <Button
                  pill
                  px="0"
                  py="0"
                  bg="transparent"
                  hover="track"
                  text="void"
                  @click="onAddItem(category)"
                >
                  <component
                    :is="require('@/assets/plus.svg')"
                    class="w-7 h-7"
                  />
                </Button>

                <!-- Edit button -->
                <Button
                  pill
                  px="0"
                  py="0"
                  bg="transparent"
                  hover="track"
                  text="void"
                  @click="onCategoryEdit(category)"
                >
                  <component
                    :is="require('@/assets/pencil.svg')"
                    class="w-5 h-5"
                  />
                </Button>

                <!-- Delete button -->
                <Button
                  pill
                  px="0"
                  py="0"
                  bg="transparent"
                  hover="track"
                  text="void"
                  @click="onCategoryDelete(category)"
                >
                  <component
                    :is="require('@/assets/trash.svg')"
                    class="w-5 h-5"
                  />
                </Button>
              </template>
            </DropdownBox>
          </div>

          <div
            v-if="selectedCategoryId === category.id"
            class="pl-6 space-y-4 mt-4"
          >
            <div
              v-for="(item, j) in category.items"
              :key="j"
              class="flex space-x-4"
            >
              <!-- ITEM -->
              <DropdownBox
                class="bg-opacity-30"
                :title="
                  item.translations[$i18n.locale]
                    ? item.translations[$i18n.locale].question
                    : $t('general.translation_not_found')
                "
                :dropdown="
                  item.translations[$i18n.locale]
                    ? item.translations[$i18n.locale].answer
                    : $t('general.translation_not_found')
                "
                :open="selectedItemId === item.id"
                :is-html="item.translations[$i18n.locale] ? true : false"
                title-class="subhead text-void"
                dropdown-class="footnote text-void"
                @click="onItemClick(item)"
              >
                <template #buttons>
                  <!-- Edit button -->
                  <Button
                    v-if="permissions.faqCategory.create"
                    pill
                    px="0"
                    py="0"
                    bg="transparent"
                    hover="snowball"
                    text="void"
                    @click="onItemEdit(item)"
                  >
                    <component
                      :is="require('@/assets/pencil.svg')"
                      class="w-5 h-5"
                    />
                  </Button>

                  <!-- Delete button -->
                  <Button
                    v-if="permissions.faqCategory.create"
                    pill
                    px="0"
                    py="0"
                    bg="transparent"
                    hover="snowball"
                    text="void"
                    @click="onItemDelete(item)"
                  >
                    <component
                      :is="require('@/assets/trash.svg')"
                      class="w-5 h-5"
                    />
                  </Button>
                </template>
              </DropdownBox>
            </div>
          </div>
        </div>
      </div>

      <PageLoading
        v-else
        class="h-80 mt-10"
        :loading="loading"
        :noneFound="$t('pages.faq_overview.none_found')"
      />
    </div>

    <!-- Delete category modal -->
    <DeleteConfirm
      v-if="categoryDeleteConfirmModal"
      :title="$t('modals.faq_overview.delete_category.title')"
      :desc="$t('modals.faq_overview.delete_category.desc')"
      :loading="categoryDeleteLoading"
      :cancel-text="$t('modals.faq_overview.delete_category.cancel')"
      :confirm-text="$t('modals.faq_overview.delete_category.confirm')"
      :extra-check="$t('modals.faq_overview.delete_category.extra_check')"
      @close="categoryDeleteConfirmModal = false"
      @confirm="deleteCategory"
    />

    <!-- Delete item modal -->
    <DeleteConfirm
      v-if="itemDeleteConfirmModal"
      :title="$t('modals.faq_overview.delete_item.title')"
      :desc="$t('modals.faq_overview.delete_item.desc')"
      :loading="itemDeleteLoading"
      :cancel-text="$t('modals.faq_overview.delete_item.cancel')"
      :confirm-text="$t('modals.faq_overview.delete_item.confirm')"
      @close="itemDeleteConfirmModal = false"
      @confirm="deleteItem"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TopNav from '@/components/TopNav'
import DropdownBox from '@/components/DropdownBox'
import DeleteConfirm from '@/components/modals/DeleteConfirm.modal'
import PageLoading from '@/components/PageLoading'

export default {
  name: 'FAQOverview',
  components: {
    TopNav,
    DropdownBox,
    DeleteConfirm,
    PageLoading
  },
  data: () => ({
    loading: true,

    selectedCategoryId: null,
    selectedItemId: null,

    categoryDeleteLoading: false,
    categoryDeleteConfirmModal: false,

    itemDeleteLoading: false,
    itemDeleteConfirmModal: false
  }),
  computed: {
    ...mapState('Auth', ['permissions']),
    ...mapState('Panel', ['open', 'component']),
    ...mapState('FAQ', ['categories'])
  },
  created() {
    this.fetchCategories()
      .then(() => {
        if (this.categories.length === 1) {
          this.selectedCategoryId = this.categories[0].id
        }
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    ...mapActions('Panel', ['setPanel', 'closePanel']),
    ...mapActions('FAQ', ['fetchCategories']),

    onCategoryClick(category) {
      this.selectedItemId = null
      this.closePanel()

      if (this.selectedCategoryId === category.id) {
        this.selectedCategoryId = null
      } else {
        this.selectedCategoryId = category.id
      }
    },
    onItemClick(item) {
      if (this.selectedItemId === item.id) {
        this.selectedItemId = null
      } else {
        this.selectedItemId = item.id
      }
    },

    /* Category */
    onAddCategory() {
      this.setPanel({ component: 'AddFAQCategory', closable: true })
    },
    onCategoryEdit(category) {
      this.$router.push({ name: 'FAQCategory', params: { id: category.id } })
    },
    onCategoryDelete(category) {
      this.selectedCategoryId = category.id
      this.categoryDeleteConfirmModal = true
    },
    deleteCategory() {
      this.categoryDeleteLoading = true
      const url = this.$api.faq.delete_category.replace(
        '{id}',
        this.selectedCategoryId
      )

      this.$http
        .delete(url)
        .then(() => {
          this.$toasted.success(
            this.$t('pages.faq_overview.messages.success.category_delete')
          )
          this.fetchCategories()
        })
        .catch(() => {
          this.$toasted.error(
            this.$t('pages.faq_overview.messages.error.category_delete')
          )
        })
        .finally(() => {
          this.categoryDeleteLoading = false
          this.categoryDeleteConfirmModal = false
        })
    },

    /* Item */
    onAddItem(category) {
      this.$router.push({
        name: 'AddFAQItem',
        params: { categoryId: category.id, id: 'new' }
      })
    },
    onItemEdit(item) {
      this.$router.push({ name: 'FAQItem', params: { id: item.id } })
    },
    onItemDelete(item) {
      this.selectedItemId = item.id
      this.itemDeleteConfirmModal = true
    },
    deleteItem() {
      this.itemDeleteLoading = true

      const url = this.$api.faq.delete_item.replace('{id}', this.selectedItemId)

      this.$http
        .delete(url)
        .then(() => {
          this.$toasted.success(
            this.$t('pages.faq_overview.messages.success.item_delete')
          )

          this.fetchCategories()
        })
        .catch(() => {
          this.$toasted.error(
            this.$t('pages.faq_overview.messages.error.item_delete')
          )
        })
        .finally(() => {
          this.itemDeleteLoading = false
          this.itemDeleteConfirmModal = false
        })
    }
  }
}
</script>
