<template>
  <div class="relative" v-click-outside="() => (dropdown = false)">
    <input
      type="text"
      class="relative px-4 py-2 pl-10 z-40 rounded-full outline-none border-2 focus:border-eiffel transition-all duration-200 placeholder-dirt text-coal w-full md:w-60 xl:w-72"
      :class="[
        bg,
        {
          'border-ice': bg === 'bg-ice',
          'border-snow': bg === 'bg-snow',
          'pr-12': clearable
        }
      ]"
      :value="value"
      :placeholder="placeholder"
      ref="searchInput"
      @input="onInput"
      @focus="focus"
    />

    <component
      :is="require('@/assets/search.svg')"
      class="w-4 absolute top-0 bottom-0 ml-4 m-auto z-40"
    />

    <component
      v-if="value.length > 0 && clearable"
      :is="require('@/assets/close-circle.svg')"
      class="w-6 cursor-pointer h-6 text-dirt absolute top-0 bottom-0 right-4 ml-4 m-auto z-40 hover:text-void transition-all duration-100 ease-in-out transform hover:scale-105"
      @click="clearInput"
    />

    <ul
      v-if="dropdown && items.length"
      class="dropdown overflow-y-auto absolute p-0 pt-6 -mt-4 rounded-b-lg bg-snow border border-snowball border-t-0 z-30 shadow-xl w-full md:w-60 xl:w-72"
    >
      <div v-if="items.length">
        <li
          v-for="(item, i) of items"
          :key="i"
          class="border-b border-ice px-3 py-2 cursor-pointer hover:bg-eiffel hover:bg-opacity-10"
          @click="itemClick(item)"
        >
          <p v-if="item">{{ item[keyToShow] }}</p>
        </li>
      </div>

      <li
        v-else
        class="border-b border-ice px-3 py-2"
        @click="dropdown = false"
      >
        {{ $t('components.dropdown.no_options') }}
      </li>
    </ul>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: 'SearchInput',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data: () => ({
    dropdown: false,
    selected: ''
  }),
  props: {
    value: {
      type: String,
      required: false,
      default: () => ''
    },
    placeholder: {
      type: String,
      required: false,
      default: () => ''
    },
    bg: {
      type: String,
      required: false,
      default: () => 'bg-snow'
    },
    items: {
      type: [Array, Object],
      required: false,
      default: () => []
    },
    keyToShow: {
      type: String,
      required: false
    },
    keyToBind: {
      type: [String],
      required: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  created() {
    if (this.value && this.items.length) {
      const item = this.items.find(el => el[this.keyToBind] === this.value)
      this.selected = item ? item[this.keyToShow] : ''
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value)
    },
    focus() {
      this.dropdown = true
      this.$emit('focus')
    },
    itemClick(item) {
      this.dropdown = false
      this.selected = item[this.keyToShow]
      this.$emit('itemClick', item[this.keyToBind])
    },
    clearInput() {
      this.$refs.searchInput.focus()
      this.$emit('input', '')
    }
  }
}
</script>
