import Vue from 'vue'
import i18n from '@/locales'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required,
  email,
  length,
  min,
  max,
  digits
} from 'vee-validate/dist/rules'

// Required rule
extend('required', {
  ...required,
  message: i18n.t('validations.required')
})

// Email rule
extend('email', {
  ...email,
  message: i18n.t('validations.email')
})

// Length rule
extend('length', {
  ...length,
  message: i18n.t('validations.length', { n: '{length}' })
})

// Minlength rule.
extend('min', {
  ...min,
  message: i18n.t('validations.min_length', {
    n: '{length}'
  })
})

// Maxlength rule.
extend('max', {
  ...max,
  message: i18n.t('validations.max_length', {
    n: '{length}'
  })
})

// Numeric
extend('digits', {
  ...digits,
  message: i18n.t('validations.digits', { n: '{length}' })
})

// Same password
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: i18n.t('validations.passwords_do_not_match')
})

// Components
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
