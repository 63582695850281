<template>
  <div class="flex justify-between">
    <h2 class="bold-title mt-6">{{ title }}</h2>

    <button class="p-2 mr-2" @click="setNavigation(true)">
      <component :is="require('@/assets/menu-icon.svg')" class="w-8" />
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'MobileNav',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('Navigation', ['setNavigation'])
  }
}
</script>
