<template>
  <div class="p-6 pt-14">
    <div class="flex items-center mb-10 space-x-2">
      <component :is="require('@/assets/statistics.svg')" class="w-5 h-5" />
      <h2 class="title-2">{{ $t('pages.user.statistics.title') }}</h2>
    </div>

    <UserStatistics />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UserStatistics from '@/components/UserStatistics'

export default {
  name: 'UserAnalytics',
  components: {
    UserStatistics
  },
  computed: {
    ...mapState('Users', ['statistics'])
  }
}
</script>
