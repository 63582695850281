<template>
  <div class="flex relative">
    <Button
      v-for="(button, i) in buttons"
      :key="i"
      class="z-0"
      :class="i !== 0 ? 'ml-5' : 'ml-0'"
      :ref="`button-${i}`"
      :text="i === selectedButton ? 'transparent' : 'void'"
      :ripple="false"
      bg="transparent"
      hover="snowball"
      pill
      @click="onButtonClick(button, i)"
    >
      {{ button.text }}
    </Button>

    <div ref="moverContainer" class="mc absolute">
      <Button pill ref="moverButton" text="snow" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonGroup',
  props: {
    buttons: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    selectedButton: 0
  }),
  mounted() {
    this.calculatePosition(this.$refs['button-0'][0].$el)
    this.setMoverButtonContent(this.$refs['button-0'][0].$el)

    window.addEventListener('resize', () => {
      if (this.$refs[`button-${this.selectedButton}`][0]) {
        this.calculatePosition(
          this.$refs[`button-${this.selectedButton}`][0].$el
        )
      }
    })
  },
  methods: {
    calculatePosition(button) {
      const moverContainer = this.$refs.moverContainer
      moverContainer.style.left = button.offsetLeft + 'px'
    },
    setMoverButtonContent(button) {
      const moverButton = this.$refs.moverButton.$el
      moverButton.innerHTML = button.innerHTML
    },

    onButtonClick(button, index) {
      this.selectedButton = index

      this.calculatePosition(this.$refs[`button-${index}`][0].$el)
      this.setMoverButtonContent(this.$refs[`button-${index}`][0].$el)

      this.$emit('buttonClick', button)
    }
  }
}
</script>

<style lang="scss" scoped>
.mc {
  transition: left 150ms ease-in-out;
}
</style>
