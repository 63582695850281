<template>
  <ValidationObserver
    v-if="formData"
    ref="form"
    tag="form"
    class="bg-snow rounded-t-lg rounded-b-lg"
    v-slot="{ invalid }"
    autocomplete="off"
    @submit.prevent="formSubmit"
  >
    <div class="p-5 md:flex items-center justify-between">
      <h2 v-if="!title" class="title-2">
        {{ $t('components.add_bulletin_box.title') }}
      </h2>

      <h2 v-if="title" class="title-2">
        {{ title }}
      </h2>

      <Input
        v-model="selectedLanguage"
        :fullWidth="isMobile"
        :items="translations"
        class="mt-4 md:mt-0"
        name="translation-switch"
        keyToShow="keyToShow"
        keyToBind="locale"
      />
    </div>

    <div class="w-full bg-ice" style="height: 2px;" />

    <div class="p-5 flex space-x-4">
      <Letter v-if="!me" :loading="true" />
      <Letter v-else :letter="me.firstName.charAt(0)" />

      <div class="w-full ck-snow">
        <ckeditor
          id="content"
          v-model="formData.translations[selectedLanguage].content"
          :editor="editor"
          :config="editorConfig"
          :disabled="!permissions.messageBoardItem.create"
        />

        <p v-if="errors[selectedLanguage]" class="text-12 text-opal ml-3">
          {{ errors[selectedLanguage] }}
        </p>
      </div>
    </div>

    <div class="w-full bg-ice" style="height: 2px;" />

    <div class="p-5 md:flex items-center justify-end form-footer">
      <div
        class="md:flex-row-reverse md:flex items-center space-y-2 md:space-y-0"
      >
        <Button
          pill
          upper
          type="submit"
          :disabled="invalid"
          :loading="submitLoading"
        >
          {{
            $t(
              `components.add_bulletin_box.${bulletin ? 'edit' : 'add'}_submit`
            )
          }}
        </Button>

        <Button
          v-if="inModal"
          class="py-3 w-full sm:w-auto mr-3 sm:mt-0"
          pill
          upper
          text="tire"
          bg="snow"
          hover="ice"
          @click="$emit('close')"
        >
          {{ $t('components.add_bulletin_box.cancel') }}
        </Button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import config from '@/config'
import { mapActions, mapState } from 'vuex'
import Input from '@/components/Input'
import Letter from '@/components/Letter'
import translations from '@/mixins/translations'

export default {
  name: 'AddBulletinBox',
  mixins: [translations],
  components: {
    Input,
    Letter
  },
  props: {
    bulletin: {
      type: Object,
      required: false,
      default: () => null
    },
    inModal: {
      type: Boolean,
      required: false,
      default: () => false
    },
    title: {
      type: String,
      required: false,
      default: () => ''
    },
    nbrToFetch: {
      type: Number,
      required: false,
      default: () => 10
    }
  },
  data() {
    return {
      editor: window.ClassicEditor,
      editorConfig: {
        ...config.ckEditorConfig,
        placeholder: this.$t('components.add_bulletin_box.input.placeholder')
      },

      selectedLanguage: '',
      search: '',
      formData: {
        translations: {}
      },
      submitLoading: false,

      errors: {}
    }
  },
  created() {
    this.selectedLanguage = this.settings.locales.find(
      el => el.locale === this.$i18n.locale
    ).locale

    this.setNewBulletin()
  },
  computed: {
    ...mapState('Auth', ['permissions', 'me']),
    ...mapState('Settings', ['settings']),
    ...mapState('Breakpoints', ['isMobile'])
  },
  methods: {
    ...mapActions('Bulletin', ['fetchBulletins']),
    setNewBulletin() {
      if (this.bulletin) {
        this.formData = cloneDeep(this.bulletin)

        for (const locale of this.settings.locales) {
          if (!this.formData.translations[locale.locale]) {
            this.formData.translations[locale.locale] = { content: '' }
          }
        }
      }

      if (!this.bulletin) {
        // Set new bulletin with available locales.
        for (const locale of this.settings.locales) {
          this.formData.translations[locale.locale] = { content: '' }
        }
      }
    },
    formSubmit() {
      this.errors = {}
      const translation = this.formData.translations[this.selectedLanguage]

      if (!translation.content.trim()) {
        this.$set(
          this.errors,
          this.selectedLanguage,
          this.$t('validations.required')
        )

        return
      }

      this.submitLoading = true

      let request

      if (this.bulletin) {
        request = this.$http.put(
          this.$api.bulletin.edit.replace('{id}', this.bulletin.id),
          {
            ...this.formData,
            restrictedTo: null
          }
        )
      }

      if (!this.bulletin) {
        request = this.$http.post(this.$api.bulletin.create, {
          ...this.formData,
          restrictedTo: null
        })
      }

      request
        .then(() => {
          if (this.bulletin) {
            this.$toasted.success(
              this.$t('components.add_bulletin_box.messages.success.edited')
            )
          }

          if (!this.bulletin) {
            this.$toasted.success(
              this.$t('components.add_bulletin_box.messages.success.created')
            )
          }

          this.setNewBulletin()
          this.fetchBulletins(this.nbrToFetch)
        })
        .catch(() => {
          if (this.bulletin) {
            this.$toasted.error(
              this.$t('components.add_bulletin_box.messages.error.edited')
            )
          }

          if (!this.bulletin) {
            this.$toasted.error(
              this.$t('components.add_bulletin_box.messages.error.created')
            )
          }
        })
        .finally(() => {
          this.submitLoading = false
          this.$emit('close')
        })
    }
  }
}
</script>

<style lang="scss">
.name-letter {
  min-width: 2.5rem;
  max-width: 2.5rem;
}

.h-96 {
  height: 24rem;
}
</style>
