var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background"},[_c('TopNav',{attrs:{"title":_vm.workArea.translations[_vm.$i18n.locale]
        ? _vm.workArea.translations[_vm.$i18n.locale].name
        : _vm.$t('general.translation_not_found'),"back-button":"WorkAreas"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.workArea.metadata.security.delete)?_c('Button',{attrs:{"pill":"","upper":"","px":"0","py":"0"},on:{"click":function($event){_vm.deleteModal = true}}},[_c(require('@/assets/trash.svg'),{tag:"component",staticClass:"w-5 h-5"})],1):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"page"},[_c('div',[(_vm.workArea)?_c('ValidationObserver',{ref:"form",attrs:{"tag":"form","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var invalid = ref.invalid;
return [_c('div',{staticClass:"space-y-4"},[(Object.keys(_vm.formData).length === 0)?_c('p',{staticClass:"text-opal",domProps:{"innerHTML":_vm._s(_vm.$t('pages.work_area.form.warning'))}}):_vm._e(),_vm._l((_vm.formData),function(item,key,i){return _c('WhiteFormBox',{key:i,attrs:{"label":Object.keys(_vm.formData).length > 1
                ? _vm.$t('general.translations')[key].label
                : ''},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('Button',{attrs:{"px":"0","py":"0","pill":"","bg":"transparent","hover":"ice","text":"void"},on:{"click":function($event){return _vm.deleteTranslation(key)}}},[_c(require('@/assets/trash.svg'),{tag:"component",staticClass:"w-6 h-6"})],1)]},proxy:true}],null,true)},[_c('Input',{attrs:{"placeholder":_vm.$t(("pages.work_area.translations." + key + ".placeholder")),"name":("name-" + i),"submitted":_vm.submitted,"rules":"required","disabled":!_vm.workArea.metadata.security.edit},on:{"input":function($event){_vm.formChanged = true}},model:{value:(_vm.formData[key].name),callback:function ($$v) {_vm.$set(_vm.formData[key], "name", $$v)},expression:"formData[key].name"}})],1)})],2),_c('div',{staticClass:"space-y-4 md:space-y-0 md:space-x-4 mt-5"},[(_vm.workArea.metadata.security.edit)?_c('Button',{attrs:{"pill":"","upper":"","type":"submit","disabled":invalid || !_vm.formChanged,"loading":_vm.submitLoading}},[_vm._v(" "+_vm._s(_vm.$t('pages.work_area.save'))+" ")]):_vm._e(),(_vm.workArea.metadata.security.edit && _vm.translations.length > 0)?_c('Button',{attrs:{"pill":"","bg":"transparent","hover":"snowball","text":"void"},on:{"click":_vm.openLanguageModal}},[_vm._v(" "+_vm._s(_vm.$t('pages.work_area.form.add_translation'))+" ")]):_vm._e()],1)]}}],null,false,2497412964)}):_c('PageLoading',{staticClass:"mt-10",attrs:{"loading":_vm.loading,"noneFound":_vm.$t('pages.work_area.not_found')}})],1)]),(_vm.deleteModal && _vm.workArea)?_c('DeleteConfirm',{attrs:{"title":_vm.$t('modals.work_areas.delete_confirm.title'),"desc":_vm.$t('modals.work_areas.delete_confirm.desc', {
        n: _vm.workArea.translations[_vm.$i18n.locale].name
      }),"loading":_vm.deleteLoading,"cancel-text":_vm.$t('modals.work_areas.delete_confirm.cancel'),"confirm-text":_vm.$t('modals.work_areas.delete_confirm.confirm')},on:{"close":function($event){_vm.deleteModal = false},"confirm":_vm.deleteWorkArea}}):_vm._e(),(_vm.modal)?_c('LanguageModal',{attrs:{"items":_vm.translations},on:{"close":function($event){_vm.modal = false},"confirm":_vm.addTranslation}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }