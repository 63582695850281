// Auth
import Auth from '@/views/auth/Auth'

// Login
import Login from '@/views/auth/Login'
import ForgotPassword from '@/views/auth/ForgotPassword'
import PasswordReset from '@/views/auth/PasswordReset'

// Registration
import Registration from '@/views/auth/Registration'
import ContractType from '@/views/auth/registration/ContractType'
import Email from '@/views/auth/registration/Email'
import FirstName from '@/views/auth/registration/FirstName'
import LastName from '@/views/auth/registration/LastName'
import Password from '@/views/auth/registration/Password'
import PasswordRepeat from '@/views/auth/registration/PasswordRepeat'

// 2FA
import TwoFA from '@/views/auth/TwoFA'

// T&C and Privacy
import TermsAndConditions from '@/views/terms-and-conditions/TermsAndConditions'
import Privacy from '@/views/privacy/Privacy'

export default [
  {
    path: '/login',
    component: Auth,
    meta: {
      hideSidebar: true,
      access: () => true
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: Login,
        meta: {
          access: () => true
        }
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
          access: () => true
        }
      },
      {
        path: '/password-reset/:token',
        name: 'PasswordReset',
        component: PasswordReset,
        meta: {
          newUser: false,
          access: () => true
        }
      },
      {
        path: '/password-reset/:token/new-user',
        name: 'PasswordResetNewUser',
        component: PasswordReset,
        meta: {
          newUser: true,
          access: () => true
        }
      },
      {
        path: '/registration',
        component: Registration,
        meta: {
          access: () => true
        }
      },
      {
        path: 'contract-type',
        name: 'Registration.contractType',
        component: ContractType
      },
      {
        path: 'email',
        name: 'Registration.email',
        component: Email
      },
      {
        path: 'first-name',
        name: 'Registration.firstName',
        component: FirstName
      },
      {
        path: 'last-name',
        name: 'Registration.lastName',
        component: LastName
      },
      {
        path: 'password',
        name: 'Registration.password',
        component: Password
      },
      {
        path: 'password-repeat',
        name: 'Registration.passwordRepeat',
        component: PasswordRepeat
      },
      {
        path: '/two-fa',
        name: 'TwoFA',
        component: TwoFA,
        meta: {
          requiresAuth: true,
          hideSidebar: true,
          access: () => true
        }
      },
      {
        path: '/terms-and-conditions',
        name: 'TermsAndConditionsLogin',
        component: TermsAndConditions,
        meta: {
          requiresAuth: false,
          access: () => true
        }
      },
      {
        path: '/privacy',
        name: 'PrivacyLogin',
        component: Privacy,
        meta: {
          requiresAuth: false,
          access: () => true
        }
      }
    ]
  }
]
