<template>
  <div class="background">
    <TopNav :title="$t('pages.users.title')" />

    <div class="page">
      <div>
        <!-- Top part -->
        <div ref="top">
          <SearchWithFilters
            v-model="search"
            :filters="filters"
            :disabled="open && component ? true : false"
            :hide-add-user-button="!permissions.user.create"
            :hide-archived-filter="!permissions.user.viewArchived"
            class="pb-12 space-y-8"
            @addUser="onAddUser"
          />
        </div>

        <List
          v-if="filtered.length"
          :items="filtered"
          :headers="headers"
          :buttons="buttons"
          :height="`max-height: calc(100vh - ${topHeight()}px - 9rem)`"
          valueToFilter="fullName"
          letterFilter
          @itemClick="userClick"
        />

        <PageLoading
          v-else
          class="mt-8"
          :loading="loading"
          :noneFound="$t('pages.users.none_found')"
        />
      </div>
    </div>

    <ArchiveUser
      v-if="modal"
      :username="username"
      :loading="archiving"
      @close="modal = false"
      @confirm="onArchive"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import List from '@/components/List'
import TopNav from '@/components/TopNav'
import PageLoading from '@/components/PageLoading'
import ArchiveUser from '@/components/modals/ArchiveUser.modal'
import SearchWithFilters from '@/components/SearchWithFilters'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

export default {
  name: 'Users',
  components: {
    List,
    TopNav,
    PageLoading,
    ArchiveUser,
    SearchWithFilters
  },
  data() {
    return {
      search: '',
      loading: true,
      modal: false,
      username: '',
      selectedUser: '',
      archiving: false,
      filters: {
        students: false,
        flexis: false,
        archived: false
      },

      headers: [
        {
          text: this.$t('pages.users.table.name'),
          value: 'fullName'
        },
        {
          text: this.$t('pages.users.table.contract_type'),
          value: 'employeeData.contractType'
        },
        {
          text: this.$t('pages.users.table.last_activity'),
          value: 'lastActivityFormatted'
        }
      ],

      buttons: [
        {
          icon: 'pencil',
          access: 'metadata.security.view',
          click: this.userClick
        },
        {
          icon: 'archive',
          access: 'metadata.security.archive',
          click: this.archiveClick
        }
      ]
    }
  },
  created() {
    dayjs.extend(relativeTime)
    this.fetchUsers()
      .then(() => {
        this.getClickableLetters(this.users)
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    ...mapState('Panel', ['open', 'component']),
    ...mapState('Auth', ['permissions']),
    ...mapGetters('Users', ['users']),

    filtered() {
      let arr = this.users

      if (this.filters.archived) {
        arr = arr.filter(el => el.archived)
      } else {
        arr = arr.filter(el => !el.archived)
      }

      if (this.search) {
        arr = this.users.filter(el =>
          el.fullName.toLowerCase().includes(this.search.toLowerCase())
        )
      }

      if (this.filters.students && !this.filters.flexis) {
        arr = arr.filter(el => el.employeeData.contractType === 'student')
      }

      if (this.filters.flexis && !this.filters.students) {
        arr = arr.filter(el => el.employeeData.contractType === 'flexi')
      }

      arr = arr.map(el => {
        el.lastActivityFormatted = el.lastActivity
          ? dayjs(el.lastActivity).fromNow()
          : this.$t('pages.user.statistics.not_known')

        return el
      })

      return arr
    }
  },
  methods: {
    ...mapActions('Users', ['archiveUser', 'fetchUsers']),
    ...mapActions('Panel', ['setPanel', 'closePanel']),

    archiveClick(user) {
      if (!user.archived && user.metadata.security.archive) {
        this.modal = true
        this.selectedUser = user
        this.username = user.firstName + ' ' + user.lastName
      }
    },
    onArchive() {
      this.archiving = true

      this.archiveUser(this.selectedUser.id)
        .then(() => {
          this.modal = false
          this.fetchUsers()
          this.$toasted.success(this.$t('pages.users.messages.archive.success'))
        })
        .catch(() => {
          this.$toasted.error(this.$t('pages.users.messages.archive.error'))
        })
        .finally(() => {
          this.archiving = false
        })
    },
    topHeight() {
      return this.$refs.top && this.$refs.top.clientHeight
    },
    getClickableLetters(arr) {
      return [...new Set(arr.map(el => el.firstName.charAt(0).toLowerCase()))]
    },
    letterClick(letter) {
      const firstEl = document.querySelector(`.letter-${letter}`)

      if (firstEl) {
        firstEl.scrollIntoView({ behavior: 'smooth' })
      }
    },
    onAddUser() {
      this.setPanel({ component: 'AddUser', closable: true })
    },
    userClick(user) {
      if (user.metadata.security.view) {
        this.closePanel()
        this.$router.push({ name: 'User', params: { id: user.id } })
      }
    }
  }
}
</script>
