<template>
  <div>
    <h2 class="title-2 mb-3">
      {{ $t('pages.user.personalia.title') }}
    </h2>

    <div class="bg-snow px-0 py-6 md:p-6 rounded-lg space-y-3">
      <Input
        v-model="user.firstName"
        :label="$t('pages.user.personalia.firstName.label')"
        :placeholder="$t('pages.user.personalia.firstName.placeholder')"
        :submitted="submitted"
        name="firstName"
      />

      <Input
        v-model="user.lastName"
        :label="$t('pages.user.personalia.lastName.label')"
        :placeholder="$t('pages.user.personalia.lastName.placeholder')"
        :submitted="submitted"
        name="lastName"
      />

      <Input
        v-model="user.employeeData.birthDate"
        :label="$t('pages.user.personalia.birth.label')"
        :placeholder="$t('pages.user.personalia.birth.placeholder')"
        :submitted="submitted"
        name="birth"
        mask="##/##/####"
      />

      <Input
        v-model="user.employeeData.placeOfBirth"
        :label="$t('pages.user.personalia.birth_place.label')"
        :placeholder="$t('pages.user.personalia.birth_place.placeholder')"
        :submitted="submitted"
        name="birthPlace"
      />

      <Input
        v-model="user.employeeData.nationalRegistryNumber"
        :label="$t('pages.user.personalia.national_number.label')"
        :placeholder="$t('pages.user.personalia.national_number.placeholder')"
        :submitted="submitted"
        name="nationalNumber"
        mask="##.##.##-###.##"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/Input'

export default {
  name: 'Personalia',
  components: {
    Input
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    submitted: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
