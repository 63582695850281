import api from '@/api'
import axios from 'axios'
import dayjs from 'dayjs'
import { reverseDate } from '@/scripts/dates'
import config from '../../../tailwind.config'

export const namespaced = true

export const state = {
  statuses: null,
  shifts: [],
  plannedShifts: [],
  date: dayjs()
}

export const getters = {
  emptyShiftsDayWarning(state, _, rootState) {
    if (
      state.statuses &&
      state.statuses.dayStatuses[reverseDate(state.date)] &&
      rootState.Auth.permissions.planningShift.create
    ) {
      return state.statuses.dayStatuses[reverseDate(state.date)].warnings.find(
        el => el.code === 'empty_shifts'
      )
    }
  },
  attrs(state, _, rootState) {
    if (state.statuses) {
      const statuses = state.statuses.dayStatuses
      const dates = []
      const plannedDates = Object.keys(state.plannedShifts)

      for (const date in statuses) {
        if (statuses[date].warnings.length) {
          dates.push(date)
        }
      }

      const arr = [
        {
          key: 'today',
          highlight: {
            contentClass: 'text-eiffel '
          },
          dates: reverseDate(dayjs())
        },
        {
          highlight: {
            style: {
              backgroundColor: config.theme.colors.eiffel
            }
          },
          dates: reverseDate(state.date)
        },
        {
          dot: {
            style: {
              backgroundColor: config.theme.colors.eiffel
            }
          },
          dates: plannedDates
        }
      ]

      if (rootState.Auth.permissions.planningShift.create) {
        arr.push({
          dot: {
            style: {
              backgroundColor: config.theme.colors.opal
            }
          },
          dates
        })
      }

      return arr
    }
  }
}

export const mutations = {
  SET_DATE(state, date) {
    state.date = date
  },
  SET_PLANNING_STATUSES(state, statuses) {
    state.statuses = statuses
  },
  SET_SHIFTS(state, shifts) {
    state.shifts = shifts
  },
  SET_PLANNED_SHIFTS(state, shifts) {
    state.plannedShifts = shifts
  }
}

export const actions = {
  setDate({ commit }, date) {
    commit('SET_DATE', date)
  },
  fetchPlanningStatuses({ commit, state }, pl) {
    const from = !pl ? reverseDate(state.date.startOf('month')) : pl.from
    const to = !pl ? reverseDate(state.date.endOf('month')) : pl.to
    const url = api.planning.status.replace('{from}', from).replace('{to}', to)

    return axios.get(url).then(res => {
      const planned = {}

      for (const date in res.data.dayStatuses) {
        if (res.data.dayStatuses[date].planned) {
          planned[date] = res.data.dayStatuses[date]
        }
      }

      commit('SET_PLANNED_SHIFTS', planned)
      commit('SET_PLANNING_STATUSES', res.data)
    })
  },
  fetchShifts({ commit }, date) {
    const url = api.planning.list.replace('{date}', date)

    return axios.get(url).then(res => {
      commit('SET_SHIFTS', res.data)
    })
  },
  fetchAvailabilities(_, { date, shiftId }) {
    const url = api.planning.shift_availabilities
      .replace('{date}', reverseDate(date))
      .replace('{id}', shiftId)

    return axios.get(url)
  }
}
