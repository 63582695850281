<template>
  <div class="w-full">
    <h2 v-if="title" class="title-2 mb-3">{{ title }}</h2>

    <div
      class="bg-snow p-6 rounded-xl space-y-3 border border-ice shadow-md md:shadow-none md:border-none"
    >
      <p
        v-if="label"
        class="uppercase text-void inline-block caption-1 rounded-full mb-1"
      >
        {{ label }}
      </p>

      <div class="flex items-center space-x-3">
        <div class="w-full">
          <slot />
        </div>

        <slot name="buttons" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhiteFormBox',
  props: {
    title: {
      type: String,
      required: false,
      default: () => ''
    },
    label: {
      type: String,
      required: false,
      default: () => ''
    }
  }
}
</script>
