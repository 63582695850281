import Privacy from '@/views/privacy/Privacy'

export default [
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      requiresAuth: true,
      access: () => true
    }
  }
]
