<template>
  <Button
    class="group"
    :bg="bg"
    :text="text"
    :hover="hover"
    pill
    @click="back ? $router.replace({ name: back }) : $router.back()"
  >
    <component
      class="h-6 mr-2 transition-all duration-200 ease-in-out transform group-hover:-translate-x-1"
      :is="require('@/assets/arrow-left.svg')"
    />
    <p>
      <!-- {{ $t('pages.template.back') }} -->
      <slot />
    </p>
  </Button>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    bg: {
      type: String,
      required: false,
      default: () => 'ice'
    },
    text: {
      type: String,
      required: false,
      default: () => 'tire'
    },
    hover: {
      type: String,
      required: false,
      default: () => 'snowball'
    },
    back: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
