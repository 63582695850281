<template>
  <Modal compact>
    <AddBulletinBox
      :title="$t('modals.bulletin.edit.title')"
      :bulletin="bulletin"
      :nbrToFetch="nbrToFetch"
      in-modal
      class="bulletin-form"
      @close="$emit('close')"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import AddBulletinBox from '@/components/AddBulletinBox'

export default {
  name: 'Bulletin.modal',
  components: {
    Modal,
    AddBulletinBox
  },
  props: {
    bulletin: {
      type: Object,
      required: true
    },
    nbrToFetch: {
      type: Number,
      required: false,
      default: () => 10
    }
  }
}
</script>

<style lang="scss">
.bulletin-form .form-footer {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
</style>
