<template>
  <ValidationObserver
    tag="form"
    ref="form"
    class="flex flex-col h-full"
    v-slot="{ invalid }"
    @submit.prevent="formSubmit"
  >
    <RegistrationHeader
      :title="$t('forgot_password.title')"
      :prevBtn="$t('forgot_password.prev_step')"
      @prevClick="$router.replace({ name: 'Login' })"
    />

    <div
      v-if="success"
      class="flex flex-col flex-1 items-center justify-center"
    >
      <p>{{ $t('forgot_password.success.desc') }}</p>

      <component
        class="w-16 mt-12 text-apple"
        :is="require('@/assets/check-circle.svg')"
      />
    </div>

    <div v-if="!success" class="flex flex-col flex-1 justify-between">
      <div>
        <p class="mb-8 subhead">
          {{ $t('forgot_password.desc') }}
        </p>

        <Input
          v-model="email"
          :label="$t('forgot_password.email.label')"
          :placeholder="$t('forgot_password.email.placeholder')"
          :submitted="submitted"
          rules="required|email"
          name="email"
          type="email"
        />
      </div>

      <Button
        class="mt-10 self-center"
        pill
        upper
        type="submit"
        :loading="loading"
        :disabled="invalid"
      >
        {{ $t('forgot_password.submit') }}
      </Button>
    </div>
  </ValidationObserver>
</template>

<script>
import Input from '@/components/Input'
import RegistrationHeader from '@/components/RegistrationHeader'

export default {
  name: 'ForgotPassword',
  components: {
    Input,
    RegistrationHeader
  },
  data: () => ({
    email: '',
    submitted: false,
    loading: false,
    success: false
  }),
  methods: {
    formSubmit() {
      this.submitted = true

      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading = true

          this.$http
            .post(this.$api.auth.password_request, {
              email: this.email,
              urlTemplate: window.location.origin + '/password-reset/<token>'
            })
            .then(() => {
              this.success = true
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
