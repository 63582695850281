<template>
  <div class="flex flex-col h-full">
    <RegistrationHeader
      :title="$t('registration.contract_type.title')"
      class="mb-8"
      back="Login"
    />

    <div
      class="flex flex-col sm:flex-row flex-1 justify-center sm:justify-between space-y-4 sm:space-y-0 w-full mx-auto"
    >
      <Button class="self-center" pill upper @click="onStudentClick">
        {{ $t('registration.contract_type.student') }}
      </Button>

      <Button class="self-center" pill upper @click="onFlexiClick">
        {{ $t('registration.contract_type.flexi') }}
      </Button>
    </div>
  </div>
</template>

<script>
import RegistrationHeader from '@/components/RegistrationHeader'

export default {
  name: 'UserRole',
  components: {
    RegistrationHeader
  },
  methods: {
    onStudentClick() {
      this.$store.dispatch('Registration/setFormData', {
        contractType: 'student'
      })
      this.nextStep()
    },
    onFlexiClick() {
      this.$store.dispatch('Registration/setFormData', {
        contractType: 'flexi'
      })
      this.nextStep()
    },
    nextStep() {
      this.$router.push({ name: 'Registration.email' })
    }
  }
}
</script>
