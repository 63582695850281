<template>
  <div
    class="top-nav md:flex items-center justify-between w-full space-x-0 md:space-x-2 p-4 md:pt-6 md:pb-0 mb-4"
    :class="{
      'bg-ice': subNav.length,
      'pb-0': !subNav.length
    }"
  >
    <div>
      <div
        class="flex md:hidden"
        :class="{
          'justify-end': !showLogo,
          'justify-between': showLogo
        }"
      >
        <component
          v-if="showLogo"
          class="w-36"
          :is="require('@/assets/logo-blue-text.svg')"
        />

        <button type="button" class="p-2 mt-2" @click="setNavigation(true)">
          <component :is="require('@/assets/menu-icon.svg')" class="w-8" />
        </button>
      </div>

      <div
        :class="{
          'mt-0 xs:-mt-6 md:mt-0': !showLogo,
          'mt-6 md:mt-0': showLogo
        }"
      >
        <div
          class="flex items-start lg:items-center flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-4 lg:space-y-0"
        >
          <div>
            <p v-if="me && showHello" class="md:hidden text-void font-light">
              {{ $t('pages.dashboard.desc', { n: me.firstName }) }}
            </p>

            <h1 class="title-1-bold md:bold-title">
              <span
                class="flex"
                :class="{
                  hidden: !showHello,
                  'md:hidden': showHello
                }"
              >
                {{ $t('pages.dashboard.welcome') }}
                <component
                  class="w-8 h-8 mt-1 ml-3"
                  :is="require('@/assets/hand.svg')"
                />
              </span>

              <span
                :class="{
                  'hidden md:inline': showHello
                }"
              >
                {{ title }}
              </span>
            </h1>
          </div>

          <ul v-if="subNav.length" class="space-y-4 md:hidden pt-4">
            <li v-for="(subItem, i) in subNav" :key="i">
              <router-link
                :to="{ name: subItem.to }"
                class="flex items-start space-x-3"
              >
                <component
                  v-if="subItem.icon"
                  class="w-8 mt-1"
                  :class="subItem.iconColor"
                  :is="require(`@/assets/${subItem.icon}.svg`)"
                />

                <div>
                  <p class="text-coal font-bold">{{ subItem.label }}</p>
                  <p class="text-coal">{{ subItem.desc }}</p>
                </div>
              </router-link>
            </li>
          </ul>

          <p
            v-if="label"
            class="bg-track rounded-full caption-1 font-black uppercase text-tire px-4 py-1"
          >
            {{ $t('pages.user.archived') }}
          </p>
        </div>

        <Button
          v-if="backButton"
          class="px-4 py-2 mt-3 group"
          bg="ice"
          text="tire"
          hover="snowball"
          pill
          @click="
            typeof backButton === 'string'
              ? $router.replace({ name: backButton })
              : $router.back()
          "
        >
          <component
            class="h-6 mr-2 transition-all duration-200 ease-in-out transform group-hover:-translate-x-1"
            :is="require('@/assets/arrow-left.svg')"
          />
          <p>{{ $t('pages.work_area.back') }}</p>
        </Button>
      </div>
    </div>

    <div class="mt-4 md:mt-0">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TopNav',
  props: {
    title: {
      type: String,
      required: () => true
    },
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    backButton: {
      type: [Boolean, String],
      required: false,
      default: () => false
    },
    showLogo: {
      type: Boolean,
      required: false,
      default: () => false
    },
    subNav: {
      type: Array,
      required: false,
      default: () => []
    },
    showHello: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState('Auth', ['me'])
  },
  methods: {
    ...mapActions('Navigation', ['setNavigation'])
  }
}
</script>
