import store from '@/store'
import Hours from '@/views/hours/Hours'

export default [
  {
    path: '/hours',
    name: 'Hours',
    component: Hours,
    meta: {
      requiresAuth: true,
      access: () => store.getters['Auth/getAccess']('user.create')
    }
  }
]
