<template>
  <div
    class="rounded-md p-2 px-4 flex justify-between relative flex-wrap"
    @click="$emit('click')"
  >
    <div class="flex items-center">
      <component
        v-if="
          shift.urgent &&
            (shift.metadata.security.edit || shift.metadata.security.clear)
        "
        class="w-5 h-5 mr-2"
        :is="require('@/assets/urgent.svg')"
        style="min-width: 1.25rem;"
      />
      <p v-if="title">{{ title }}</p>
    </div>

    <div class="flex flex-col items-end">
      <p>{{ desc }}</p>
      <p v-if="shiftBreak" class="text-11 text-void">{{ shiftBreak }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Shift',
  props: {
    shift: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: () => ''
    },
    desc: {
      type: String,
      required: false,
      default: () => ''
    },
    shiftBreak: {
      type: [Boolean, String],
      required: false,
      default: () => ''
    }
  }
}
</script>
