<template>
  <div class="background">
    <TopNav :title="pageTitle" back-button="FAQOverview" />

    <div class="page">
      <div v-if="!loading && isNew ? formData : item">
        <ValidationObserver
          ref="form"
          tag="form"
          v-slot="{ invalid }"
          autocomplete="off"
          @submit.prevent="formSubmit"
        >
          <div class="space-y-4">
            <p
              v-if="Object.keys(formData).length === 0"
              class="text-opal"
              v-html="$t('pages.faq_item.form.warning')"
            />

            <WhiteFormBox
              v-for="(item, key, i) in formData"
              :key="i"
              :label="
                Object.keys(formData).length > 1
                  ? $t('general.translations')[key].label
                  : ''
              "
            >
              <template #buttons>
                <Button
                  px="0"
                  py="0"
                  pill
                  bg="transparent"
                  hover="ice"
                  text="void"
                  @click="deleteTranslation(key)"
                >
                  <component
                    :is="require('@/assets/trash.svg')"
                    class="w-6 h-6"
                  />
                </Button>
              </template>

              <div v-if="permissions && editor" class="space-y-3">
                <!-- Question -->
                <Input
                  v-model="formData[key].question"
                  :label="$t('pages.faq_item.form.question.label')"
                  :placeholder="$t('pages.faq_item.form.question.placeholder')"
                  :name="`name-${i}`"
                  :submitted="submitted"
                  rules="required"
                  :disabled="!permissions.faqItem.create"
                  @input="formChanged = true"
                />

                <!-- Answer -->
                <div>
                  <label for="answer" class="font-normal text-12 block mb-2">
                    {{ $t('pages.faq_item.form.answer.label') }}
                  </label>
                  <ckeditor
                    id="answer"
                    v-model="formData[key].answer"
                    :editor="editor"
                    :config="editorConfig"
                    :disabled="!permissions.faqItem.create"
                    @input="formChanged = true"
                  />
                  <p class="text-opal mt-1 text-12">
                    {{ ckerrors[key] }}
                  </p>
                </div>
              </div>
            </WhiteFormBox>
          </div>

          <div class="space-x-4 mt-5">
            <!-- Save button -->
            <Button
              v-if="permissions.faqItem.create"
              pill
              upper
              type="submit"
              :disabled="invalid || !formChanged"
              :loading="submitLoading"
            >
              {{ $t('pages.faq_item.save') }}
            </Button>

            <!-- Add translation button -->
            <Button
              v-if="permissions.faqItem.create && translations.length > 0"
              pill
              bg="transparent"
              hover="snowball"
              text="void"
              @click="languageModal = true"
            >
              {{ $t('pages.faq_item.form.add_translation') }}
            </Button>
          </div>
        </ValidationObserver>
      </div>

      <PageLoading
        v-else
        class="h-80 mt-10"
        :loading="loading"
        :noneFound="$t('pages.faq_item.not_found')"
      />
    </div>

    <!-- Language Modal -->
    <LanguageModal
      v-if="languageModal"
      :items="translations"
      @close="languageModal = false"
      @confirm="addTranslation"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Input from '@/components/Input'
import cloneDeep from 'lodash.clonedeep'
import TopNav from '@/components/TopNav'
import PageLoading from '@/components/PageLoading'
import WhiteFormBox from '@/components/WhiteFormBox'
import LanguageModal from '@/components/modals/Language.modal'
import translations from '@/mixins/translations'
import config from '@/config'

export default {
  name: 'FAQDetail',
  mixins: [translations],
  components: {
    Input,
    TopNav,
    PageLoading,
    WhiteFormBox,
    LanguageModal
  },
  data() {
    return {
      loading: true,
      item: null,
      formData: {},
      formChanged: false,
      submitLoading: false,
      languageModal: false,
      submitted: false,
      pageTitle: '',
      ckerrors: {},

      isNew: false,

      editor: window.ClassicEditor,
      editorConfig: {
        ...config.ckEditorConfig,
        placeholder: this.$t('pages.faq_item.form.answer.placeholder')
      }
    }
  },
  computed: {
    ...mapState('Auth', ['permissions']),
    ...mapState('Settings', ['settings'])
  },
  created() {
    if (this.$route.params.id !== 'new') {
      this.isNew = false
      this.fetchItem()
    }

    if (this.$route.params.id === 'new') {
      this.isNew = true

      this.pageTitle = this.$t('pages.faq_item.add_new_item')
      this.formData = {
        [this.$i18n.locale]: {
          question: '',
          answer: ''
        }
      }

      this.item = 'is-new'
      this.loading = false
    }
  },
  methods: {
    fetchItem() {
      this.$http
        .get(this.$api.faq.item.replace('{id}', this.$route.params.id))
        .then(res => {
          this.item = res.data

          this.formData = cloneDeep(res.data.translations)
          this.pageTitle = res.data.translations[this.$i18n.locale]
            ? res.data.translations[this.$i18n.locale].question
            : this.$t('general.translation_not_found')
        })
        .finally(() => {
          this.loading = false
        })
    },
    addTranslation(lang) {
      this.languageModal = false

      if (!this.formData[lang]) {
        this.$set(this.formData, lang, {})
      } else {
        this.$toasted.error(this.$t('pages.faq_item.already_exists'))
      }
    },
    deleteTranslation(key) {
      this.formChanged = true
      this.$delete(this.formData, key)
    },
    formSubmit() {
      this.submitted = true
      let invalid = false

      for (const el in this.formData) {
        if (!this.formData[el].answer) {
          this.ckerrors[el] = this.$t('validations.required')
          invalid = true
        } else {
          invalid = false
        }
      }

      this.$refs.form.validate().then(success => {
        if (success && !invalid) {
          this.submitLoading = true

          this.ckerrors = {}

          if (this.isNew) {
            this.addItem()
          }

          if (!this.isNew) {
            this.editItem()
          }
        }
      })
    },
    addItem() {
      this.$http
        .post(this.$api.faq.create_item, {
          category: this.$route.params.categoryId,
          translations: this.formData
        })
        .then(res => {
          this.$toasted.success(
            this.$t('pages.faq_overview.messages.success.item_add')
          )

          this.$router.push({ name: 'FAQItem', params: { id: res.data.id } })
          this.fetchItem()
        })
        .catch(() => {
          this.$toasted.error(
            this.$t('pages.faq_overview.messages.error.item_add')
          )
        })
        .finally(() => {
          this.submitted = false
          this.formChanged = false
          this.submitLoading = false
        })
    },
    editItem() {
      const url = this.$api.faq.edit_item.replace('{id}', this.$route.params.id)

      this.$http
        .put(url, {
          category: this.item.category.id,
          translations: this.formData
        })
        .then(() => {
          this.$toasted.success(
            this.$t('pages.faq_item.messages.success.edit_item')
          )

          this.fetchItem()
        })
        .catch(() => {
          this.$toasted.error(
            this.$t('pages.faq_item.messages.error.edit_item')
          )
        })
        .finally(() => {
          this.submitted = false
          this.formChanged = false
          this.submitLoading = false
        })
    }
  }
}
</script>
