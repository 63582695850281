<template>
  <div>
    <h2 class="title-2 mb-3">
      {{ $t('pages.dashboard.unassigned_shifts') }}
    </h2>

    <div class="space-y-3">
      <Shift
        v-for="(shift, date, i) in emptyShifts.dayStatuses"
        :key="i"
        :shift="shift"
        :title="dayjs(date).format('DD/MM/YYYY')"
        :desc="shift.warnings[0].message"
        class="py-3 cursor-pointer bg-ice md:bg-snow hover:bg-eiffelLift"
        @click="onShiftClick(date)"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Shift from '@/components/Shift'

export default {
  name: 'EmptyShifts',
  components: {
    Shift
  },
  props: {
    emptyShifts: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dayjs
  }),
  methods: {
    onShiftClick(date) {
      this.$router.push({ name: 'Planning', params: { date } })
    }
  }
}
</script>
