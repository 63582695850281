<template>
  <ValidationObserver
    ref="form"
    tag="form"
    v-slot="{ invalid }"
    autocomplete="off"
    @submit.prevent="formSubmit"
  >
    <Modal
      :title="$t('pages.planning.copy_template_modal.title')"
      :desc="$t('pages.planning.copy_template_modal.desc')"
      icon="template"
    >
      <Input
        v-model="selectedTemplate"
        class="mt-5"
        :label="$t('pages.planning.copy_template_modal.input.label')"
        :placeholder="
          $t('pages.planning.copy_template_modal.input.placeholder')
        "
        :items="templates"
        keyToBind="id"
        keyToShow="name"
        name="template"
      />
      <template #buttons>
        <!-- Submit -->
        <Button
          class="py-3 w-full sm:w-auto rounded-md ml-0 sm:ml-3"
          pill
          upper
          :loading="loading"
          :disabled="invalid"
          type="submit"
        >
          {{ $t(`components.planner.add_shift.confirm`) }}
        </Button>

        <!-- Cancel -->
        <Button
          class="py-3 w-full sm:w-auto rounded-md mt-3 sm:mt-0"
          pill
          upper
          text="tire"
          bg="snow"
          hover="ice"
          @click="$emit('close')"
        >
          {{ $t(`components.planner.add_shift.cancel`) }}
        </Button>
      </template>
    </Modal>
  </ValidationObserver>
</template>

<script>
import Modal from '@/components/Modal'
import Input from '@/components/Input'

export default {
  name: 'TemplateSelect',
  components: {
    Modal,
    Input
  },
  props: {
    loading: {
      Type: Boolean,
      required: false,
      default: () => false
    },
    templates: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    submitted: false,
    selectedTemplate: null
  }),
  methods: {
    formSubmit() {
      this.submitted = true

      this.$refs.form.validate().then(success => {
        if (success) {
          this.$emit('confirm', this.selectedTemplate)
        }
      })
    }
  }
}
</script>
