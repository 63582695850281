<template>
  <Modal
    :title="$t('modals.users.unarchive_confirm.title')"
    :desc="$t('modals.users.unarchive_confirm.desc', { n: username })"
    icon="exclamation"
    iconColor="opal"
  >
    <template #buttons>
      <!-- Submit -->
      <Button
        class="py-3 w-full sm:w-auto rounded-md ml-0 sm:ml-3"
        pill
        upper
        :loading="loading"
        bg="opal"
        hover="ruby"
        @click="$emit('confirm')"
      >
        {{ $t('modals.users.unarchive_confirm.confirm') }}
      </Button>

      <!-- Cancel -->
      <Button
        class="py-3 w-full sm:w-auto rounded-md mt-3 sm:mt-0"
        pill
        upper
        text="tire"
        bg="snow"
        hover="ice"
        @click="$emit('close')"
      >
        {{ $t('modals.users.unarchive_confirm.cancel') }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'UnarchiveModal',
  components: {
    Modal
  },
  props: {
    username: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
