import Vue from 'vue'
import Button from '@/components/Button'
import Toasted from 'vue-toasted'
import VTooltip from 'v-tooltip'
import CKEditor from '@ckeditor/ckeditor5-vue2'

const toastOptions = {
  position: 'bottom-center',
  singleton: true,
  containerClass: 'toast-container',
  className: 'toast',
  duration: 4000
}

const tooltipOptions = {
  defaultPlacement: 'bottom',
  defaultClass: 'tooltip'
}

Vue.component('Button', Button)

Vue.use(Toasted, toastOptions)
Vue.use(VTooltip, tooltipOptions)
Vue.use(CKEditor)
