<template>
  <div class="fixed z-40 inset-0">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity"
        aria-hidden="true"
        @click="$emit('cancelClick')"
      >
        <div class="absolute inset-0 bg-coal opacity-75"></div>
      </div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >
        &#8203;
      </span>

      <div
        class="inline-block align-bottom bg-snow rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-lg"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div
          :class="{
            'px-4 pt-5 pb-4 sm:p-6 sm:pb-4': !compact
          }"
        >
          <div class="sm:flex sm:items-start">
            <div
              v-if="icon"
              class="mb-4 sm:mb-0 mx-auto flex-shrink-0 flex-center h-12 w-12 rounded-full bg-opacity-20 sm:mx-0 sm:h-10 sm:w-10"
              :class="`bg-${iconColor}`"
            >
              <component
                :is="require(`@/assets/${icon}.svg`)"
                :class="`h-6 w-6 text-${iconColor}`"
              />
            </div>

            <div
              class="text-center sm:mt-0 sm:text-left w-full"
              :class="{ 'ml-0 sm:ml-4': icon }"
            >
              <h3
                class="title-2 leading-6 font-bold text-coal"
                id="modal-headline"
              >
                {{ title }}
              </h3>
              <div class="mt-2">
                <p v-if="desc" class="subhead text-coal">
                  {{ desc }}
                </p>

                <slot v-if="$slots.default" />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="$slots.buttons"
          class="px-4 py-6 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-lg"
        >
          <slot name="buttons" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      required: false,
      default: () => ''
    },
    desc: {
      type: String,
      required: false,
      default: () => ''
    },
    icon: {
      type: String,
      required: false,
      default: () => ''
    },
    iconColor: {
      type: String,
      required: false,
      default: () => 'eiffel'
    },
    compact: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
