<template>
  <div class="background">
    <TopNav :title="$t('pages.planning.title')" />

    <div class="page pb-0">
      <div v-if="!loading" class="overflow-y-auto h-full flex flex-col">
        <div>
          <div class="xl:flex items-center justify-between my-5">
            <DaySelect
              v-if="date"
              :date="date"
              :loading="fetching"
              @prevDay="prevDay"
              @nextDay="nextDay"
              @reset="resetDay"
            />

            <div
              class="mt-5 space-y-3 xl:mt-0 xl:space-y-0 space-x-0 lg:space-x-3"
            >
              <!-- Template button -->
              <Button
                v-if="permissions.templateShift.create"
                pill
                upper
                bg="snow"
                hover="snowball"
                class="text-void w-full lg:w-auto"
                @click="templateModal = true"
              >
                {{ $t('pages.planning.from_template') }}
              </Button>

              <!-- Create shift button -->
              <Button
                v-if="permissions.planningShift.create"
                pill
                upper
                class="w-full lg:w-auto"
                @click="addShiftButton"
              >
                {{ $t('pages.planning.add_shift') }}
              </Button>
            </div>
          </div>
        </div>

        <Planner
          ref="planner"
          :rows="workAreas"
          :shifts="shifts"
          :shift="shift"
          :date="date"
          :can-create="permissions.planningShift.create"
          @shiftCreateOrClick="shiftModal = true"
          @shiftResizedOrMoved="updateShift"
          @shiftDeleted="onDeleteShift"
          @fillInShift="onFillInShift"
          @shiftCleared="onShiftCleared"
        />
      </div>

      <PageLoading
        v-else
        class="mt-10"
        :loading="loading"
        :noneFound="$t('pages.planning.page_loading_error')"
      />
    </div>

    <!-- Shift modal -->
    <Shift
      v-if="shiftModal"
      :shift="shift"
      :loading="addOrEditShiftLoading"
      @close="resetState"
      @confirm="shiftModalSubmit"
    />

    <!-- Copy template modal -->
    <TemplateSelect
      v-if="templateModal"
      :templates="templates"
      :loading="copyLoading"
      @close="templateModal = false"
      @confirm="copyTemplate"
    />

    <!-- Fill Shift -->
    <FillInShift
      v-if="fillInShiftModal"
      :shift="shift"
      :fullLoading="fillInFullLoading"
      :partialLoading="fillInPartialLoading"
      @close="resetState"
      @confirm="fillInShift"
    />

    <!-- Delete confirm modal -->
    <DeleteConfirm
      v-if="deleteShiftModal"
      :title="$t('modals.delete_shift.title')"
      :desc="$t('modals.delete_shift.desc')"
      :loading="deleteShiftLoading"
      @close="resetState"
      @confirm="deleteShift"
    />

    <!-- Clear shift modal -->
    <DeleteConfirm
      v-if="clearShiftModal"
      :title="
        shift.plannedUser.id === me.id
          ? $t('modals.cancel_shift.title')
          : $t('modals.clear_shift.title')
      "
      :desc="
        shift.plannedUser.id === me.id
          ? $t('modals.cancel_shift.desc')
          : $t('modals.clear_shift.desc', {
              name: `${shift.plannedUser.firstName} ${shift.plannedUser.lastName}`
            })
      "
      :loading="clearShiftLoading"
      :confirm-text="
        shift.plannedUser.id === me.id
          ? $t('modals.cancel_shift.confirm')
          : $t('modals.clear_shift.confirm')
      "
      @close="resetState"
      @confirm="clearShift"
    />
  </div>
</template>

<script>
import { IShift } from '@/interfaces'
import { mapActions, mapState } from 'vuex'
import { reverseDate } from '@/scripts/dates'
import dayjs from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import Planner from '@/components/Planner'
import PageLoading from '@/components/PageLoading'
import DaySelect from '@/components/DaySelect'
import Shift from '@/components/modals/Shift.modal'
import TopNav from '@/components/TopNav'
import TemplateSelect from '@/components/modals/TemplateSelect.modal'
import FillInShift from '@/components/modals/FillInShift.modal'
import DeleteConfirm from '@/components/modals/DeleteConfirm.modal'

export default {
  name: 'Planning',
  components: {
    Planner,
    PageLoading,
    DaySelect,
    Shift,
    TopNav,
    TemplateSelect,
    FillInShift,
    DeleteConfirm
  },
  data: () => ({
    loading: true,
    shift: null,

    shiftModal: false,
    addOrEditShiftLoading: false,

    templateModal: false,

    fetching: false,
    copyLoading: false,

    fillInShiftModal: false,
    fillInFullLoading: false,
    fillInPartialLoading: false,
    availableUsers: [],

    clearShiftModal: false,
    clearShiftLoading: false,

    deleteShiftModal: false,
    deleteShiftLoading: false
  }),
  watch: {
    date() {
      this.fetchAndUpdatePlanner()
    }
  },
  async created() {
    this.shift = cloneDeep(IShift)

    await this.fetchPlanningStatuses()
    await this.fetchShifts(reverseDate(this.date))
    await this.fetchWorkAreas()

    if (this.permissions.template.list) {
      await this.fetchTemplates()
    }

    if (this.$route.params.date) {
      this.setDate(dayjs(this.$route.params.date))
    }

    this.setPanel({ component: 'PlanningInfo', closable: false })
    this.loading = false
  },
  computed: {
    ...mapState('Planning', ['date', 'shifts']),
    ...mapState('Auth', ['me', 'permissions']),
    ...mapState('WorkAreas', ['workAreas']),
    ...mapState('Templates', ['templates'])
  },
  methods: {
    ...mapActions('Planning', [
      'fetchPlanningStatuses',
      'fetchShifts',
      'setDate'
    ]),
    ...mapActions('Panel', ['setPanel']),
    ...mapActions('Users', ['fetchUsers']),
    ...mapActions('WorkAreas', ['fetchWorkAreas']),
    ...mapActions('Templates', ['fetchTemplates']),

    prevDay() {
      this.setDate(this.date.subtract(1, 'day'))
      this.fetchAndUpdatePlanner()
    },
    nextDay() {
      this.setDate(this.date.add(1, 'day'))
      this.fetchAndUpdatePlanner()
    },
    resetDay() {
      this.setDate(dayjs())
      this.fetchAndUpdatePlanner()
    },
    addShiftButton() {
      this.resetState()
      this.shiftModal = true
    },
    resetState() {
      this.shift = cloneDeep(IShift)

      this.addOrEditShiftLoading = false
      this.deleteShiftLoading = false
      this.copyLoading = false
      this.fillInFullLoading = false
      this.fillInPartialLoading = false
      this.clearShiftLoading = false

      this.shiftModal = false
      this.templateModal = false
      this.fillInShiftModal = false
      this.deleteShiftModal = false
      this.clearShiftModal = false
    },

    onFillInShift(shift) {
      this.shift = shift
      this.fillInShiftModal = true
    },
    onShiftCleared(shift) {
      this.shift = shift
      this.clearShiftModal = true
    },
    onDeleteShift(shift) {
      this.shift = shift
      this.deleteShiftModal = true
    },

    updateShift(shift) {
      this.addOrEditShiftLoading = true

      const url = this.$api.planning.edit_shift
        .replace('{date}', reverseDate(this.date))
        .replace('{id}', shift.id)

      this.$http
        .patch(url, shift)
        .then(() => {
          this.$toasted.success(this.$t('pages.planning.messages.success.edit'))
        })
        .catch(() => {
          this.$toasted.error(this.$t('pages.planning.messages.error.edit'))
        })
        .finally(() => {
          this.fetchAndUpdatePlanner()
          this.resetState()
        })
    },
    deleteShift() {
      this.deleteShiftLoading = true

      const id = this.shift.id
      const url = this.$api.planning.delete_shift
        .replace('{date}', reverseDate(this.date))
        .replace('{id}', id)

      this.$http
        .delete(url)
        .then(() => {
          this.$toasted.success(
            this.$t('pages.planning.messages.success.delete')
          )
        })
        .catch(() => {
          this.$toasted.error(this.$t('pages.planning.messages.error.delete'))
        })
        .finally(() => {
          this.fetchAndUpdatePlanner()
          this.resetState()
        })
    },
    fillInShift(payload) {
      const { userId, partial } = payload

      if (partial) {
        this.fillInPartialLoading = true
      }
      if (!partial) {
        this.fillInFullLoading = true
      }

      const url = this.$api.planning.plan_shift
        .replace('{date}', reverseDate(this.date))
        .replace('{id}', this.shift.id)

      this.$http
        .post(url, { user: userId, splitAllowed: partial })
        .then(() => {
          this.$toasted.success(
            this.$t('pages.planning.messages.success.fill_shift')
          )
        })
        .catch(() => {
          this.$toasted.error(
            this.$t('pages.planning.messages.error.fill_shift')
          )
        })
        .finally(() => {
          this.fillInShiftModal = false
          this.fillInFullLoading = false
          this.fillInPartialLoading = false
          this.shift = cloneDeep(IShift)
          this.fetchAndUpdatePlanner()
        })
    },
    clearShift() {
      this.clearShiftLoading = true

      const url = this.$api.planning.clear_shift
        .replace('{date}', reverseDate(this.date))
        .replace('{id}', this.shift.id)

      this.$http
        .post(url)
        .then(() => {
          this.$toasted.success(
            this.$t('pages.planning.messages.success.clear_shift')
          )
        })
        .catch(() => {
          this.$toasted.error(
            this.$t('pages.planning.messages.error.clear_shift')
          )
        })
        .finally(() => {
          this.clearShiftModal = false
          this.clearShiftLoading = false
          this.shift = cloneDeep(IShift)
          this.fetchAndUpdatePlanner()
        })
    },
    fetchAndUpdatePlanner() {
      this.fetching = true

      this.fetchShifts(reverseDate(this.date))
        .then(() => {
          return this.fetchPlanningStatuses()
        })
        .then(() => {
          this.$refs.planner.loadShifts()
        })
        .finally(() => {
          this.fetching = false
        })
    },
    shiftModalSubmit() {
      if (this.shift.id) {
        this.updateShift(this.shift)
      }

      if (!this.shift.id) {
        this.addOrEditShiftLoading = true

        const url = this.$api.planning.create_shift.replace(
          '{date}',
          reverseDate(this.date)
        )

        this.$http
          .post(url, this.shift)
          .then(() => {
            return this.fetchShifts(reverseDate(this.date))
          })
          .then(() => {
            this.$toasted.success(
              this.$t('pages.planning.messages.success.add')
            )
            this.fetchAndUpdatePlanner()
          })
          .catch(() => {
            this.$toasted.error(this.$t('pages.planning.messages.error.add'))
          })
          .finally(() => {
            this.addOrEditShiftLoading = false
            this.resetState()
          })
      }
    },
    copyTemplate(templateId) {
      this.copyLoading = true

      const url = this.$api.templates.copy_to_planning.replace(
        '{id}',
        templateId
      )

      this.$http
        .post(url, { date: reverseDate(this.date) })
        .then(() => {
          this.$toasted.success(
            this.$t('pages.planning.messages.success.copy_template')
          )
          this.fetchAndUpdatePlanner()
        })
        .catch(() => {
          this.$toasted.error(
            this.$t('pages.planning.messages.error.copy_template')
          )
        })
        .finally(() => {
          this.resetState()
        })
    }
  }
}
</script>
