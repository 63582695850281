<template>
  <div class="background">
    <TopNav :title="$t('pages.communication.title')" />

    <div class="page">
      <div>
        <AddBulletinBox class="-ml-5 -mr-5 md:ml-0 md:mr-0" />
        <BulletinOverview
          v-if="permissions.messageBoardItem.create"
          class="rounded-b-lg mt-8"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TopNav from '@/components/TopNav'
import AddBulletinBox from '@/components/AddBulletinBox'
import BulletinOverview from '@/components/BulletinOverview'

export default {
  name: 'Communication',
  components: {
    TopNav,
    AddBulletinBox,
    BulletinOverview
  },
  computed: {
    ...mapState('Auth', ['permissions'])
  }
}
</script>
