<template>
  <div class="p-6 pt-14">
    <div>
      <h2 class="title-2 mb-6">{{ $t('pages.users.add.title') }}</h2>

      <!-- Basic details -->
      <div>
        <ValidationObserver
          ref="form"
          tag="form"
          v-slot="{ invalid }"
          autocomplete="off"
          @submit.prevent="formSubmit"
        >
          <div class="space-y-3">
            <Input
              v-model="formData.firstName"
              :label="$t('pages.users.add.first_name.label')"
              :placeholder="$t('pages.users.add.first_name.placeholder')"
              :submitted="submitted"
              rules="required"
              type="text"
              name="firstName"
            />

            <Input
              v-model="formData.lastName"
              :label="$t('pages.users.add.last_name.label')"
              :placeholder="$t('pages.users.add.last_name.placeholder')"
              :submitted="submitted"
              rules="required"
              type="text"
              name="lastName"
            />

            <Input
              v-model.trim="formData.email"
              :label="$t('pages.users.add.email.label')"
              :placeholder="$t('pages.users.add.email.placeholder')"
              :submitted="submitted"
              rules="required|email"
              type="email"
              name="email"
            />

            <Input
              v-model="formData.contractType"
              :label="$t('pages.users.add.contract_type.label')"
              :placeholder="$t('pages.users.add.contract_type.placeholder')"
              :items="items"
              :submitted="submitted"
              rules="required"
              name="contract_type"
              keyToShow="text"
              keyToBind="id"
            />
          </div>

          <Button
            class="mt-6"
            pill
            upper
            type="submit"
            :loading="loading"
            :disabled="invalid"
          >
            {{ $t('pages.users.add.send_invite') }}
          </Button>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Input from '@/components/Input'

export default {
  name: 'AddUser',
  components: {
    Input
  },
  data() {
    return {
      loading: false,
      submitted: false,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        contractType: '',
        urlTemplate: window.location.origin + '/password-reset/<token>/new-user'
      },
      items: [
        {
          id: 'student',
          text: this.$t('pages.users.add.contract_type.student')
        },
        {
          id: 'flexi',
          text: this.$t('pages.users.add.contract_type.flexi')
        }
      ]
    }
  },
  methods: {
    ...mapActions('Panel', ['closePanel']),

    formSubmit() {
      this.submitted = true
      const provider = this.$refs.form

      provider.validate().then(success => {
        if (success) {
          this.loading = true

          this.$http
            .post(this.$api.user.create_and_invite, this.formData)
            .then(() => {
              this.$store.dispatch('Users/fetchUsers')
              this.$toasted.success(
                this.$t('pages.users.add.success', {
                  name: this.formData.firstName + ' ' + this.formData.lastName
                })
              )

              this.formData = {
                firstName: '',
                lastName: '',
                email: '',
                contractType: '',
                urlTemplate:
                  window.location.origin + '/password-reset/<token>/new-user'
              }

              provider.reset()
            })
            .catch(() => {
              this.$toasted.error(this.$t('pages.users.add.error'))
            })
            .finally(() => {
              this.loading = false
              this.closePanel()
            })
        }
      })
    }
  }
}
</script>
