<template>
  <div class=" bg-eiffel rounded-full h-10 flex-center name-letter">
    <p class="font-bold text-snow text-24">
      <span v-if="!loading && letter">
        {{ letter }}
      </span>

      <Spinner :size="20" v-else />
    </p>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner'

export default {
  name: 'Letter',
  components: {
    Spinner
  },
  props: {
    letter: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
