<template>
  <nav
    class="absolute md:relative px-10 pt-8 pb-6 w-full min-w-72 md:w-72 h-screen bg-snow md:bg-transparent transition-all duration-200 ease-in-out z-50 md:z-0 flex flex-col justify-between overflow-y-auto"
    :class="{ closed: !navigation }"
  >
    <!-- Close button -->
    <button
      class="md:hidden absolute right-10 top-12"
      @click="setNavigation(false)"
    >
      <component :is="require('@/assets/close.svg')" class="w-6 text-space" />
    </button>

    <div>
      <!-- Polly logo -->
      <component
        :is="require('@/assets/logo-blue-text.svg')"
        class="hidden md:block w-24 text-space mx-auto pb-8"
      />

      <!-- Divider -->
      <hr class="text-snowball hidden md:block" />

      <!-- Menu icons -->
      <ul v-if="permissions" class="-mx-4 mt-16 md:mt-10">
        <li v-for="(item, i) of menuItems" :key="i">
          <p
            v-if="item.section"
            class="pl-4 uppercase text-void footnote tracking-widest mt-6 mb-3"
          >
            {{ $t(item.section) }}
          </p>

          <router-link
            v-ripple
            v-else
            :to="{ name: item.to, params: item.params }"
            tag="button"
            :disabled="item.disabled"
            class="rounded-full hover:bg-ice w-full flex items-center opacity-30 hover:opacity-100 px-4 py-2 transition-all duration-200 mb-2 text-left"
            @click.native="linkClick"
          >
            <component
              :is="require(`@/assets/${item.icon}.svg`)"
              class="w-5 h-5"
            />
            <p class="body ml-3">{{ $t(item.title) }}</p>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="space-y-2 mt-5">
      <router-link
        :to="{ name: 'TermsAndConditions' }"
        class="text-void hover:text-coal transition-all duration-200 ease-in-out footnote block"
      >
        {{ $t('sidebar.items.terms_and_conditions') }}
      </router-link>

      <router-link
        :to="{ name: 'Privacy' }"
        class="text-void hover:text-coal transition-all duration-200 ease-in-out footnote block"
      >
        {{ $t('sidebar.items.privacy') }}
      </router-link>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Sidebar',
  computed: {
    ...mapState('Auth', ['jwtData', 'permissions', 'me']),
    ...mapState('Navigation', ['navigation']),
    ...mapState('Breakpoints', ['isMobile']),

    menuItems() {
      const items = [
        {
          section: 'sidebar.sections.common',
          access: true
        },
        {
          to: 'Dashboard',
          icon: 'bulletin',
          title: 'sidebar.items.bulletin',
          access: true
        },
        {
          to: 'Availabilities',
          icon: 'clock',
          title: 'sidebar.items.availabilities',
          access: !this.permissions.planningShift.create
        },
        {
          to: 'AvailabilitiesUser',
          icon: 'clock',
          title: 'sidebar.items.availabilities',
          access: this.permissions.planningShift.create
        },
        {
          to: 'FAQOverview',
          icon: 'question-mark-circle',
          title: 'sidebar.items.faq',
          access: true
        },

        // Planning
        {
          section: 'sidebar.sections.planning',
          access: true
        },
        {
          to: 'Planning',
          icon: 'agenda',
          title: 'sidebar.items.work_schedule',
          access: true
        },
        {
          to: 'WorkAreas',
          icon: 'briefcase',
          title: 'sidebar.items.work_areas',
          access: this.permissions.workArea.create
        },
        {
          to: 'Templates',
          icon: 'template',
          title: 'sidebar.items.templates',
          access: this.permissions.template.list
        },
        {
          to: 'Hours',
          icon: 'table',
          title: 'sidebar.items.hours',
          access: this.permissions.user.create
        },

        // Manage
        {
          section: 'sidebar.sections.manage',
          access: true
        },
        {
          to: 'MyProfile',
          icon: 'identification',
          title: 'sidebar.items.my_profile',
          access: true,
          disabled: !this.me,
          params: {
            id: this.me ? this.me.id : 0
          }
        },
        {
          to: 'Users',
          icon: 'users',
          title: 'sidebar.items.users',
          access: this.permissions.user.list
        },
        {
          to: 'Communication',
          icon: 'communication',
          title: 'sidebar.items.communication',
          access: this.permissions.messageBoardItem.create
        }
      ]
      return items.filter(el => el.access)
    }
  },
  methods: {
    ...mapActions('Navigation', ['setNavigation']),

    linkClick() {
      if (this.isMobile) {
        this.setNavigation(false)
      }
    }
  }
}
</script>

<style lang="scss">
nav {
  &.closed {
    transform: translateY(-100%);
  }

  button.router-link-active {
    @apply opacity-100;

    svg {
      @apply text-eiffel;
    }
  }
}
</style>
