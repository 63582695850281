<template>
  <div class="background">
    <TopNav :title="$t('pages.availabilities.title')" />

    <div class="page">
      <div v-if="!loading">
        <!-- Top part -->
        <div ref="top">
          <div class="overflow-y-auto h-full flex flex-col">
            <div class="xl:flex items-center justify-between my-5">
              <DaySelect
                v-if="date"
                :date="date"
                :loading="fetching"
                @prevDay="prevDay"
                @nextDay="nextDay"
                @reset="resetDay"
              />
              <div
                class="mt-5 space-y-3 xl:mt-0 xl:space-y-0 space-x-0 lg:space-x-3"
              >
                <SearchInput
                  :value="search"
                  :placeholder="$t('pages.users.search')"
                  @input="onSearch"
                />
              </div>
            </div>
          </div>
        </div>

        <List
          v-if="filtered.length"
          :items="filtered"
          :headers="headers"
          :height="`max-height: calc(100vh - ${topHeight()}px - 9rem)`"
          valueToFilter="firstName"
        />
        <PageLoading
          v-else
          class="mt-10"
          :loading="loading"
          :noneFound="$t('pages.planning.page_loading_error')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dayjs from 'dayjs'
import PageLoading from '@/components/PageLoading'
import DaySelect from '@/components/DaySelect'
import TopNav from '@/components/TopNav'
import relativeTime from 'dayjs/plugin/relativeTime'
import List from '@/components/List'
import { reverseDate } from '@/scripts/dates'
import SearchInput from '../../components/SearchInput'

export default {
  name: 'AvailabilitiesUser',
  components: {
    SearchInput,
    List,
    PageLoading,
    DaySelect,
    TopNav
  },
  data() {
    return {
      search: '',
      loading: true,
      fetching: false,
      selectedUser: '',
      archiving: false,
      filters: {
        students: false,
        flexis: false,
        archived: false
      },

      headers: [
        {
          text: this.$t('pages.users.table.name'),
          value: 'fullName'
        },
        {
          text: this.$t('modals.fill_in_shift.list.status'),
          value: 'statusText'
        },
        {
          text: this.$t('pages.users.table.last_activity'),
          value: 'lastActivityFormatted'
        }
      ],

      availableUsers: []
    }
  },
  watch: {
    date() {
      this.fetchAndUpdateUserList()
    }
  },
  async created() {
    if (this.$route.params.date) {
      this.setDate(dayjs(this.$route.params.date))
    }
    dayjs.extend(relativeTime)

    this.fetchAndUpdateUserList()

    this.setPanel({ component: 'DatepickerFilter', closable: false })
    this.loading = false
  },
  computed: {
    ...mapState('Planning', ['date', 'shifts']),
    ...mapState('Auth', ['me', 'permissions']),
    ...mapState('Panel', ['open', 'component']),

    filtered() {
      let arr = this.availableUsers

      if (this.availableUsers) {
        if (this.search) {
          arr = this.availableUsers.filter(el =>
            el.fullName.toLowerCase().includes(this.search.toLowerCase())
          )
        }

        arr = arr.map(el => {
          el.lastActivityFormatted = el.user.lastActivity
            ? dayjs(el.user.lastActivity).fromNow()
            : this.$t('pages.user.statistics.not_known')
          el.fullName = `
            <div class="flex items-center">
              <div class="w-3 h-3 bg-${this.getColor(
                el
              )} rounded-full mr-3"></div>
              <p>${el.user.firstName} ${el.user.lastName}</p>
            </div>
          `
          return el
        })
      }
      return arr
    }
  },
  methods: {
    ...mapActions('Users', ['fetchUsersAvailability']),
    ...mapActions('Panel', ['setPanel', 'closePanel']),
    ...mapActions('Planning', ['setDate']),
    onSearch(search) {
      this.search = search
    },
    prevDay() {
      this.setDate(this.date.subtract(1, 'day'))
      this.fetchAndUpdateUserList()
    },
    nextDay() {
      this.setDate(this.date.add(1, 'day'))
      this.fetchAndUpdateUserList()
    },
    resetDay() {
      this.setDate(dayjs())
      this.fetchAndUpdateUserList()
    },

    fetchAndUpdateUserList() {
      this.fetching = true
      const date = reverseDate(this.date)
      this.fetchUsersAvailability(date)
        .then(res => {
          this.availableUsers = res.data.map(el => {
            el.statusText = `
            <p class="text-${this.getColor(el)}">
              ${this.getTranslations(el)}
            </p>
          `
            el.fullName = `${el.user.firstName} ${el.user.lastName}`
            el.firstName = `${el.user.firstName}`
            return el
          })
        })
        .finally(() => {
          this.fetching = false
        })
    },
    topHeight() {
      return this.$refs.top && this.$refs.top.clientHeight
    },
    getTranslations(user) {
      switch (user.status) {
        case 'unavailable':
          return this.$t('modals.fill_in_shift.list.statuses.unavailable')

        case 'available':
          return this.$t('modals.fill_in_shift.list.statuses.available')

        case 'partial_available':
          return this.$t('modals.fill_in_shift.list.statuses.partial', {
            from: user.partialMatch.start,
            to: user.partialMatch.end
          })

        case 'planned':
          return this.$t('modals.fill_in_shift.list.statuses.planned')

        default:
          return this.$t('modals.fill_in_shift.list.statuses.unknown')
      }
    },
    getColor(user) {
      switch (user.status) {
        case 'unavailable':
          return 'opal'

        case 'available':
          return 'apple'

        case 'partial_available':
          return 'coral'

        case 'planned':
          return 'eiffel'
      }
    }
  }
}
</script>
