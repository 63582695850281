export const namespaced = true

export const state = {
  reversed: false
}

export const mutations = {
  SET_REVERSED(state, reversed) {
    state.reversed = reversed
  }
}

export const actions = {
  setReversed({ commit }, reversed) {
    commit('SET_REVERSED', reversed)

    setTimeout(() => {
      commit('SET_REVERSED', false)
    }, 550)
  }
}
