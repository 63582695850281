<template>
  <div>
    <div class="bg-ice w-full" style="height: 2px;" />

    <div class="p-6 pt-16">
      <DatePicker
        ref="datePicker"
        v-model="date"
        title-position="left"
        :attributes="attributes"
        is-expanded
        class="borderless"
        :first-day-of-week="2"
        :locale="{ id: 'nl', masks: { weekdays: 'WW' } }"
        is-required
        @update:to-page="e => (pageInfo = e)"
      />
    </div>
  </div>
</template>

<script>
import { reverseDate } from '@/scripts/dates'
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import config from '../../../tailwind.config'

export default {
  name: 'DatepickerFilter',
  components: {
    DatePicker
  },
  data: () => ({
    pageInfo: null
  }),
  computed: {
    ...mapGetters('Planning', ['attrs', 'date']),

    date: {
      get() {
        return reverseDate(this.$store.state.Planning.date)
      },
      set(date) {
        if (date) {
          this.setDate(dayjs(date))
        }
      }
    },
    attributes() {
      const arr = [
        {
          key: 'today',
          highlight: {
            contentClass: 'text-eiffel '
          },
          dates: reverseDate(dayjs())
        },
        {
          highlight: {
            style: {
              backgroundColor: config.theme.colors.eiffel
            }
          },
          dates: reverseDate(this.date)
        }
      ]
      return arr
    }
  },
  watch: {
    date() {
      this.$refs.datePicker.move(this.date)
    }
  },
  mounted() {
    const leftArrow = this.$refs.datePicker.$children[0].$children[2].$el
    const rightArrow = this.$refs.datePicker.$children[0].$children[3].$el

    leftArrow.addEventListener('click', () => {
      this.pageChange()
    })

    rightArrow.addEventListener('click', () => {
      this.pageChange()
    })
  },
  methods: {
    ...mapActions('Planning', ['setDate'])
  }
}
</script>
