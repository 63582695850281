import store from '@/store'
import WorkAreas from '@/views/workareas/WorkAreas'
import WorkArea from '@/views/workareas/WorkArea'

export default [
  {
    path: '/work-areas',
    name: 'WorkAreas',
    component: WorkAreas,
    meta: {
      requiresAuth: true,
      access: () => store.getters['Auth/getAccess']('workArea.create')
    }
  },
  {
    path: '/work-areas/:id',
    name: 'WorkArea',
    component: WorkArea,
    meta: {
      requiresAuth: true,
      access: () => store.getters['Auth/getAccess']('workArea.create')
    }
  }
]
