import axios from 'axios'
import api from '@/api'

export const namespaced = true

export const state = {
  bulletins: []
}

export const mutations = {
  SET_BULLETINS(state, bulletins) {
    state.bulletins = bulletins
  }
}

export const actions = {
  async fetchBulletins({ commit }, count) {
    return axios.get(api.bulletin.list.replace('{count}', count)).then(res => {
      if (res) {
        commit('SET_BULLETINS', res.data.items)
      }

      return res
    })
  }
}
