<template>
  <div>
    <div class="w-full flex items-center justify-between">
      <BackButton
        bg="snow"
        hover="snow"
        class="-ml-6"
        px="0"
        :back="back"
        @click.native="onBackClick"
      >
        {{ prevBtn }}
      </BackButton>

      <Button
        pill
        bg="snow"
        hover="ice"
        px="0"
        py="0"
        class="text-void"
        @click="onHomeClick"
      >
        <component :is="require('@/assets/home.svg')" class="w-5 h-5" />
      </Button>
    </div>

    <h1 class="title-1-bold md:bold-title sm:mt-6 mb-2 text-left">
      {{ title }}
    </h1>

    <p class="mt-2 body">{{ desc }}</p>

    <p v-if="step && steps" class="mt-1 caption-1 text-left">
      <span class="caption-1" v-if="step !== steps">
        {{ $t('registration.step', { step, steps }) }}
      </span>

      <span class="caption-1" v-else>
        {{ $t('registration.last_step') }}
      </span>
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BackButton from '@/components/BackButton'

export default {
  name: 'RegistrationHeader',
  components: {
    BackButton
  },
  props: {
    title: {
      type: String,
      required: false,
      default: () => ''
    },
    desc: {
      type: String,
      required: false,
      default: () => ''
    },
    step: {
      type: Number,
      required: false,
      default: () => 0
    },
    steps: {
      type: Number,
      required: false,
      default: () => 0
    },
    back: {
      type: String,
      required: false,
      default: () => ''
    },
    prevBtn: {
      type: String,
      required: false,
      default: function() {
        return this.$t('registration.prev_step')
      }
    }
  },
  methods: {
    ...mapActions('Registration', ['clearFormData']),
    ...mapActions('Transitions', ['setReversed']),

    onBackClick() {
      this.setReversed('Transitions/setReversed', true)
    },
    onHomeClick() {
      this.clearFormData()
      this.$router.replace({ name: 'Login' })
    }
  }
}
</script>
