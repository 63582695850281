import Vue from 'vue'
import Vuex from 'vuex'

import * as Transitions from './modules/Transitions.module'
import * as Registration from './modules/Registration.module'
import * as Auth from './modules/Auth.module'
import * as Panel from './modules/Panel.module'
import * as Users from './modules/Users.module'
import * as Settings from './modules/Settings.module'
import * as WorkAreas from './modules/WorkAreas.module'
import * as Templates from './modules/Templates.module'
import * as Planning from './modules/Planning.module'
import * as Navigation from './modules/Navigation.module'
import * as Breakpoints from './modules/Breakpoints.module'
import * as FAQ from './modules/FAQ.module'
import * as Bulletin from './modules/Bulletin.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Transitions,
    Registration,
    Auth,
    Panel,
    Users,
    Settings,
    WorkAreas,
    Templates,
    Planning,
    Navigation,
    Breakpoints,
    FAQ,
    Bulletin
  }
})
