<template>
  <button
    class="text-coal bg-track w-6 h-6 flex-center rounded-full transition-all duration-200 transform hover:rotate-90 hover:scale-105 hover:bg-eiffel hover:text-snow"
    @click="$emit('click')"
  >
    <component class="w-3 h-3" :is="require('@/assets/close.svg')" />
  </button>
</template>

<script>
export default {
  name: 'CloseButton'
}
</script>
