<template>
  <div class="flex list-overview" :style="height">
    <LetterFilter
      v-if="letterFilter"
      :clickable="getClickableLetters(items)"
      class="mt-10 overflow-y-auto max-h-screen pl-1 pr-4 mr-2"
      @letterClick="letterClick"
    />

    <div class="w-full overflow-y-auto">
      <!-- Headers -->
      <div class="grid grid-cols-12 mb-2 grid-flow-col">
        <div
          :class="[
            `grid grid-cols-${headers.length} px-2`,
            {
              'col-span-10': buttons.length,
              'col-span-12': !buttons.length
            }
          ]"
        >
          <p
            class="text-tire caption-1"
            v-for="(header, i) in headers"
            :key="i"
          >
            {{ header.text }}
          </p>
        </div>

        <p v-if="buttons.length" class="text-tire caption-1 col-span-2 px-2">
          {{ $t('general.list.actions') }}
        </p>
      </div>

      <ul>
        <!-- Items -->
        <li
          v-for="(item, i) in items"
          :key="i"
          class="grid grid-cols-12 rounded-sm overflow-hidden"
          :class="[
            valueToFilter
              ? `letter-${item[valueToFilter].charAt(0).toLowerCase()}`
              : '',
            {
              'bg-snow': i % 2 === 1,
              'bg-ice': i % 2 === 0,
              'bg-tire text-snow': get(item, idPath) === selectedId,
              archived: item.archived
            }
          ]"
        >
          <!-- Item -->
          <div
            v-ripple
            class="hover:bg-eiffelLift px-2 cursor-pointer flex items-center transition-all duration-200"
            :class="[
              `grid grid-cols-${headers.length}`,
              {
                'col-span-10': buttons.length,
                'col-span-12': !buttons.length,
                'py-5': !buttons.length,
                'py-3': buttons.length
              }
            ]"
            @click="itemClick(item)"
          >
            <div
              v-for="(header, i) in headers"
              :key="i"
              v-html="get(item, header.value)"
            />
          </div>

          <!-- Buttons -->
          <div
            v-if="buttons.length"
            class="col-span-2 py-3 px-2 flex space-x-3"
          >
            <Button
              v-for="(button, i) of buttons"
              :key="i"
              :disabled="!get(item, button.access)"
              class="min-w-8 h-8 flex-center transform hover:scale-105"
              :class="`button-${button.icon}`"
              px="0"
              py="0"
              pill
              @click="button.click(item)"
            >
              <component
                class="w-5 h-5"
                :is="require(`@/assets/${button.icon}.svg`)"
              />
            </Button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'
import LetterFilter from '@/components/LetterFilter'

export default {
  name: 'List',
  components: {
    LetterFilter
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    buttons: {
      type: Array,
      required: false,
      default: () => []
    },
    height: {
      type: String,
      required: false,
      default: () => ''
    },
    letterFilter: {
      type: Boolean,
      required: false,
      default: () => false
    },
    valueToFilter: {
      type: String,
      required: false,
      default: () => ''
    },
    selectedId: {
      type: [Number, null],
      reuired: false,
      default: () => null
    },
    idPath: {
      type: String,
      reuired: false,
      default: () => ''
    }
  },
  data: () => ({
    get
  }),
  methods: {
    itemClick(item) {
      this.$emit('itemClick', item)
    },
    getClickableLetters(arr) {
      return [
        ...new Set(
          arr.map(el => el[this.valueToFilter].charAt(0).toLowerCase())
        )
      ]
    },
    letterClick(letter) {
      const firstEl = document.querySelector(`.letter-${letter}`)

      if (firstEl) {
        firstEl.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-overview {
  li.archived {
    @apply text-dirt;

    button.button-archive {
      display: none;
    }
  }
}
</style>
