<template>
  <div>
    <h2 class="title-2 mb-3">
      {{ $t('pages.user.address.title') }}
    </h2>

    <div class="bg-snow px-0 py-6 md:p-6 rounded-lg space-y-3">
      <Input
        v-model="user.employeeData.address.street"
        :label="$t('pages.user.address.street.label')"
        :placeholder="$t('pages.user.address.street.placeholder')"
        :rules="addressChanged ? 'required' : ''"
        :submitted="submitted"
        name="street"
      />

      <div class="flex space-x-4">
        <Input
          v-model="user.employeeData.address.number"
          :label="$t('pages.user.address.number.label')"
          :placeholder="$t('pages.user.address.number.placeholder')"
          :rules="addressChanged ? 'required' : ''"
          :submitted="submitted"
          name="number"
        />
        <Input
          v-model="user.employeeData.address.addition"
          :label="$t('pages.user.address.addition.label')"
          :placeholder="$t('pages.user.address.addition.placeholder')"
          :submitted="submitted"
          name="postal"
        />
      </div>

      <div class="flex space-x-4">
        <Input
          v-model="user.employeeData.address.postalCode"
          :label="$t('pages.user.address.postal.label')"
          :placeholder="$t('pages.user.address.postal.placeholder')"
          :rules="addressChanged ? 'required' : ''"
          :submitted="submitted"
          name="postal"
          mask="####"
        />
        <Input
          v-model="user.employeeData.address.city"
          :label="$t('pages.user.address.place.label')"
          :placeholder="$t('pages.user.address.place.placeholder')"
          :rules="addressChanged ? 'required' : ''"
          :submitted="submitted"
          name="place"
        />
      </div>

      <Input
        v-model="user.employeeData.address.country"
        :label="$t('pages.user.address.country.label')"
        :placeholder="$t('pages.user.address.country.placeholder')"
        :rules="addressChanged ? 'required' : ''"
        :submitted="submitted"
        :items="$t('countries')"
        name="country"
        keyToShow="text"
        keyToBind="id"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/Input'

export default {
  name: 'Address',
  components: {
    Input
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    submitted: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    addressChanged: {
      get() {
        return this.$store.state.Users.addressChanged
      },
      set(val) {
        this.$store.dispatch('Users/setAddressChanged', val)
      }
    }
  },
  watch: {
    'user.employeeData.address': {
      handler() {
        this.addressChanged = true
      },
      deep: true
    }
  }
}
</script>
