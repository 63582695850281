// prettier-ignore
export default {
  auth: {
    /* GET */     permissions:            '/auth/permissions',
    /* POST */    login:                  'auth/login',
    /* POST */    register:               'auth/register',
    /* POST */    two_fa:                 'auth/2fa',
    /* POST */    jwt_refresh:            'auth/refresh',
    /* POST */    password_request:       'auth/password-reset/request',
    /* POST */    password_reset:         'auth/password-reset/reset'
  },
  user: {
    /* GET */     me:                     'users/me',
    /* GET */     list:                   'users/employees',
    /* GET */     availabilities:         'users/employees-availabilities/{date}',
    /* GET */     user:                   'users/{id}',
    /* GET */     statistics:             'users/{id}/statistics',
    /* GET */     planned_time:           'users/{id}/planned-time/{start}/{end}',
    /* POST */    create_and_invite:      'users/employees/invite',
    /* PATCH */   edit_user:              'users/{id}',
    /* POST */    archive:                'users/{id}/archive',
    /* POST */    unarchive:              'users/{id}/unarchive'
  },
  work_area: {
    /* GET */     list:                   'work-areas',
    /* GET */     single:                 'work-areas/{id}',
    /* POST */    create:                 'work-areas',
    /* PUT */     edit_full:              'work-areas/{id}',
    /* DELETE */  delete:                 'work-areas/{id}'
  },
  templates: {
    /* GET */     list:                   'planning/templates',
    /* GET */     single:                 'planning/templates/{id}',
    /* POST */    create_shift:           'planning/templates/{id}/shifts',
    /* POST */    create:                 'planning/templates',
    /* POST */    copy_to_planning:       'planning/templates/{id}/copy',
    /* PUT */     edit_full:              'planning/templates/{id}',
    /* PATCH */   edit_shift:             'planning/templates/{id}/shifts/{shift_id}',
    /* DELETE */  delete_shift:           'planning/templates/{id}/shifts/{shift_id}',
    /* DELETE */  delete:                 'planning/templates/{id}'
  },
  general: {
    /* GET */     settings:               'settings'
  },
  planning: {
    /* GET */     status:                 'planning/status/{from}/{to}',
    /* GET */     list:                   'planning/{date}/shifts',
    /* GET */     shift_availabilities:   'planning/{date}/shifts/{id}/availabilities',
    /* GET */     upcoming_shifts:        'planning/upcoming/{id}',
    /* GET */     empty_shifts:           'planning/status/empty-shifts',
    /* POST */    create_shift:           'planning/{date}/shifts',
    /* POST */    plan_shift:             'planning/{date}/shifts/{id}/plan',
    /* POST */    clear_shift:            'planning/{date}/shifts/{id}/clear',
    /* PATCH */   edit_shift:             'planning/{date}/shifts/{id}',
    /* DELETE */  delete_shift:           'planning/{date}/shifts/{id}'
  },
  availabilities: {
    /* GET */     date:                   'availability/date/{date}',
    /* GET */     list_dates:             'availability/date/{from}/{to}',
    /* GET */     day_of_week:            'availability/day-of-week/{day}',
    /* GET */     list_days_of_week:      'availability/day-of-week',
    /* POST */    create_date:            'availability/date/{date}',
    /* POST */    create_day_of_week:     'availability/day-of-week/{day}',
    /* PUT */     edit_date:              'availability/date/{date}',
    /* PUT */     edit_day_of_week:       'availability/day-of-week/{day}'
  },
  faq: {
    /* GET */     list_categories:        'faq/category',
    /* GET */     category:               'faq/category/{id}',
    /* GET */     item:                   'faq/{id}',
    /* POST */    create_category:        'faq/category',
    /* POST */    create_item:            'faq',
    /* PUT */     edit_category:          'faq/category/{id}',
    /* PUT */     edit_item:              'faq/{id}',
    /* DELETE */  delete_category:        'faq/category/{id}',
    /* DELETE */  delete_item:            'faq/{id}',
  },
  bulletin: {
    /* GET */     list:                   'message-board/item?count={count}',
    /* POST */    create:                 'message-board/item',
    /* PUT */     edit:                   'message-board/item/{id}',
    /* DELETE */  delete:                 'message-board/item/{id}'
  }
}
