<template>
  <div class="p-6 pt-14">
    <h2 class="title-2 mb-6">
      {{ $t('pages.faq_overview.add_category.title') }}
    </h2>
    <p>{{ $t('pages.faq_overview.add_category.desc') }}</p>

    <ValidationObserver
      ref="form"
      tag="form"
      v-slot="{ invalid }"
      @submit.prevent="formSubmit"
    >
      <Input
        v-model="newCategory"
        class="mt-5"
        name="category"
        rules="required"
        :submitted="submitted"
        :placeholder="$t('pages.faq_overview.add_category.input.placeholder')"
      />

      <Button
        class="py-2 mt-6"
        :class="invalid ? 'px-3' : 'px-4'"
        pill
        upper
        type="submit"
        :loading="loading"
        :disabled="invalid"
      >
        {{ $t('pages.faq_overview.add_category.confirm') }}
      </Button>
    </ValidationObserver>
  </div>
</template>

<script>
import Input from '@/components/Input'
import { mapActions } from 'vuex'

export default {
  name: 'AddFAQCategory',
  components: {
    Input
  },
  data: () => ({
    newCategory: '',
    submitted: false,
    loading: false
  }),
  methods: {
    ...mapActions('FAQ', ['fetchCategories']),

    formSubmit() {
      this.loading = true

      this.$http
        .post(this.$api.faq.create_category, {
          translations: {
            [this.$i18n.locale]: {
              name: this.newCategory
            }
          }
        })
        .then(() => {
          this.newCategory = ''
          this.$toasted.success(
            this.$t('pages.faq_overview.messages.success.category_add')
          )
          this.fetchCategories()
        })
        .catch(() => {
          this.$toasted.error(
            this.$t('pages.faq_overview.messages.error.category_add')
          )
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
