import store from '@/store'
import FAQOverview from '@/views/faq/FAQOverview'
import FAQCategory from '@/views/faq/FAQCategory'
import FAQItem from '@/views/faq/FAQItem'

export default [
  {
    path: '/faq',
    name: 'FAQOverview',
    component: FAQOverview,
    meta: {
      requiresAuth: true,
      access: () => true
    }
  },
  {
    path: '/faq/category/:id',
    name: 'FAQCategory',
    component: FAQCategory,
    meta: {
      requiresAuth: true,
      access: () => store.getters['Auth/getAccess']('faqCategory.create')
    }
  },
  {
    path: '/faq/item/:categoryId/:id',
    name: 'AddFAQItem',
    component: FAQItem,
    meta: {
      requiresAuth: true,
      access: () => store.getters['Auth/getAccess']('faqItem.create')
    }
  },
  {
    path: '/faq/item/:id',
    name: 'FAQItem',
    component: FAQItem,
    meta: {
      requiresAuth: true,
      access: () => store.getters['Auth/getAccess']('faqItem.create')
    }
  }
]
