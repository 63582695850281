<template>
  <div>
    <h2 class="title-2 mb-3">
      {{ $t('pages.dashboard.bulletins') }}
    </h2>

    <div
      v-if="bulletins.length"
      class="box bg-snow p-0 md:p-5 space-y-8 xs:space-y-4 rounded-b-xl"
    >
      <Bulletin
        v-for="(bulletin, i) in bulletins"
        :key="i"
        :bulletin="bulletin"
        @editBulletin="onEditBulletin"
        @deleteBulletin="onDeleteBulletin"
      />
    </div>

    <p v-else class="text-void body">
      {{ $t('pages.dashboard.no_bulletins') }}
    </p>

    <!-- Edit bulletin modal -->
    <BulletinModal
      v-if="editModal"
      :title="$t('modals.bulletin.edit.title')"
      :bulletin="selectedBulletin"
      :nbrToFetch="nbrToFetch"
      @close="editModal = false"
    />

    <!-- Delete category modal -->
    <DeleteConfirm
      v-if="deleteModal"
      :title="$t('modals.delete_bulletin_item.title')"
      :desc="$t('modals.delete_bulletin_item.desc')"
      :loading="deleteLoading"
      :cancel-text="$t('modals.delete_bulletin_item.cancel')"
      :confirm-text="$t('modals.delete_bulletin_item.confirm')"
      @close="deleteModal = false"
      @confirm="deleteBulletin"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Bulletin from '@/components/Bulletin'
import BulletinModal from '@/components/modals/Bulletin.modal'
import DeleteConfirm from '@/components/modals/DeleteConfirm.modal'

export default {
  name: 'BulletinOverview',
  components: {
    Bulletin,
    BulletinModal,
    DeleteConfirm
  },
  props: {
    nbrToFetch: {
      type: Number,
      required: false,
      default: () => 10
    }
  },
  computed: {
    ...mapState('Bulletin', ['bulletins'])
  },
  data: () => ({
    selectedBulletin: null,

    editModal: false,
    editLoading: false,

    deleteModal: false,
    deleteLoading: false
  }),
  created() {
    this.fetchBulletins(this.nbrToFetch)
  },
  methods: {
    ...mapActions('Bulletin', ['fetchBulletins']),

    onDeleteBulletin(bulletin) {
      this.selectedBulletin = bulletin
      this.deleteModal = true
    },
    onEditBulletin(bulletin) {
      this.selectedBulletin = bulletin
      this.editModal = true
    },

    deleteBulletin() {
      const url = this.$api.bulletin.delete.replace(
        '{id}',
        this.selectedBulletin.id
      )

      this.deleteLoading = true

      this.$http
        .delete(url)
        .then(() => {
          this.$toasted.success(
            this.$t('pages.dashboard.messages.success.delete_bulletin')
          )

          this.fetchBulletins(this.nbrToFetch)
        })
        .catch(() => {
          this.$toasted.error(
            this.$t('pages.dashboard.messages.error.delete_bulletin')
          )
        })
        .finally(() => {
          this.deleteLoading = false
          this.deleteModal = false
        })
    }
  }
}
</script>
