<template>
  <div class="bg-snow overflow-y-scroll" :class="{ 'md:bg-ice': isAuth }">
    <div :class="{ page: isAuth }">
      <TopNav v-if="isAuth" :title="$t('pages.terms_and_conditions.title')" />

      <div v-else>
        <BackButton bg="snow" hover="snow" back="Login" class="-ml-6">
          {{ $t('pages.terms_and_conditions.back_to_login') }}
        </BackButton>
        <h1 class="title-1-bold md:bold-title sm:mt-6 mb-2 text-left">
          {{ $t('pages.terms_and_conditions.title') }}
        </h1>
      </div>

      <div v-html="$t('terms.terms_and_conditions')"></div>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/TopNav'
import BackButton from '@/components/BackButton'

export default {
  name: 'TermsAndConditions',
  components: {
    TopNav,
    BackButton
  },
  computed: {
    isAuth() {
      return this.$route.name === 'TermsAndConditions'
    }
  }
}
</script>
