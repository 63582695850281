<template>
  <div>
    <ShadowBox>
      <div class="p-5 px-6 flex justify-between">
        <div>
          <h2 class="text-16 font-bold text-coal">
            {{ 'Beschikbaarheid' }}
          </h2>

          <p v-if="availability && !desc" class="caption-1 text-void">
            {{ availability.desc }}
          </p>

          <p v-if="desc" class="caption-1 text-void">{{ desc }}</p>
        </div>

        <h2
          v-if="availability && availability.state"
          :class="{
            'text-opal': availability.state === 'unavailable',
            'text-eiffel':
              availability.state === 'available' ||
              availability.state === 'partial_available'
          }"
        >
          <template v-if="availability.state !== 'partial_available'">
            {{ $t(`pages.availabilities.labels.${availability.state}`) }}
          </template>

          <template v-if="availability.state === 'partial_available'">
            <template v-if="availability.timeRanges.length">
              {{ timeRange }}
            </template>

            <template v-else>
              {{ $t(`pages.availabilities.labels.${availability.state}`) }}
            </template>
          </template>
        </h2>

        <h2 v-else class="text-void">
          {{ $t('pages.availabilities.not_filled') }}
        </h2>
      </div>

      <template #extra>
        <div class="p-6 space-y-3">
          <Checkbox
            v-model="filters.available"
            round
            :label="$t('pages.availabilities.checkboxes.whole_day')"
            @click="onCheckboxClick('available')"
          />

          <Checkbox
            v-model="filters.partial_available"
            round
            :label="$t('pages.availabilities.checkboxes.set_range')"
            @click="onCheckboxClick('partial_available')"
          />

          <Checkbox
            v-model="filters.unavailable"
            round
            :label="$t('pages.availabilities.checkboxes.not_available')"
            @click="onCheckboxClick('unavailable')"
          />

          <div v-if="filterSelected" class="flex-center md:justify-start pt-4">
            <!-- CreateForm -->
            <form @submit.prevent="formSubmit">
              <Button
                v-if="filters.available || filters.unavailable"
                type="submit"
                pill
                upper
                :loading="updateLoading"
              >
                {{ $t('pages.availabilities.default.save') }}
              </Button>

              <Button
                v-if="filters.partial_available"
                pill
                upper
                @click="timeRangeModal = true"
              >
                {{ $t('pages.availabilities.default.setup') }}
              </Button>
            </form>
          </div>
        </div>
      </template>
    </ShadowBox>

    <!-- Time range modal -->
    <TimeRangeModal
      v-if="timeRangeModal"
      :loading="partialLoading"
      @close="timeRangeModal = false"
      @confirm="onTimeRangeSelect"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import ShadowBox from '@/components/ShadowBox'
import Checkbox from '@/components/Checkbox'
import TimeRangeModal from '@/components/modals/TimeRangeModal'
import { reverseDate } from '@/scripts/dates'

export default {
  name: 'AvailabilityBox',
  components: {
    ShadowBox,
    Checkbox,
    TimeRangeModal
  },
  props: {
    availability: {
      type: Object,
      required: false,
      default: () => null
    },
    desc: {
      type: String,
      required: false,
      default: () => ''
    },
    createUrl: {
      type: String,
      required: true
    },
    updateUrl: {
      type: String,
      required: true
    },
    createSuccessMessage: {
      type: String,
      required: true
    },
    updateSuccessMessage: {
      type: String,
      required: true
    },
    createErrorMessage: {
      type: String,
      required: true
    },
    updateErrorMessage: {
      type: String,
      required: true
    }
  },
  data: () => ({
    reverseDate,
    updateLoading: false,
    partialLoading: false,
    timeRangeModal: false,
    timeRanges: [],
    selectedFilter: '',
    filters: {
      available: false,
      partial_available: false,
      unavailable: false
    }
  }),
  watch: {
    availability() {
      this.resetFilters()
      this.setFilter()
    }
  },
  computed: {
    timeRange() {
      return `
        ${dayjs(this.availability.timeRanges[0].start).format('HH:mm')} -
        ${dayjs(this.availability.timeRanges[0].end).format('HH:mm')}
      `
    },
    filterSelected() {
      return (
        this.filters.available ||
        this.filters.partial_available ||
        this.filters.unavailable
      )
    }
  },
  methods: {
    setFilter() {
      if (this.availability) {
        this.filters[this.availability.state] = true
        this.selectedFilter = this.availability.state
      }
    },
    onTimeRangeSelect(payload) {
      this.partialLoading = true
      this.timeRanges = payload.timeRanges
      this.formSubmit()
    },
    resetFilters() {
      this.filters.available = false
      this.filters.partial_available = false
      this.filters.unavailable = false
    },
    onCheckboxClick(type) {
      this.resetFilters()
      this.selectedFilter = type
    },
    formSubmit() {
      if (!this.availability) {
        this.updateAvailability('create')
      }

      if (this.availability) {
        this.updateAvailability('update')
      }
    },
    updateAvailability(type) {
      this.updateLoading = true

      const payload = {
        state: this.selectedFilter,
        timeRanges: this.timeRanges
      }
      const request =
        type === 'create'
          ? this.$http.post(this.createUrl, payload)
          : this.$http.put(this.updateUrl, payload)

      request
        .then(() => {
          this.$emit('availabilityUpdated')

          if (type === 'create') {
            this.$toasted.success(this.createSuccessMessage)
          }

          if (type === 'update') {
            this.$toasted.success(this.updateSuccessMessage)
          }
        })
        .catch(() => {
          if (type === 'create') {
            this.$toasted.error(this.createErrorMessage)
          }

          if (type === 'update') {
            this.$toasted.error(this.updateErrorMessage)
          }
        })
        .finally(() => {
          this.updateLoading = false
          this.timeRangeModal = false
          this.partialLoading = false
        })
    }
  }
}
</script>
