<template>
  <div class="flex flex-col flex-1 overflow-y-auto justify-between h-full">
    <!-- New user -->
    <div
      v-if="newUser"
      class="flex flex-col items-center text-center mb-10"
      :class="{ 'hidden-h-sm': !success }"
    >
      <div v-if="success">
        <h1 class="title-1-bold md:bold-title sm:mt-6 mb-2 text-center">
          {{ $t('password_reset.success.new_user.title') }}
        </h1>
        <p class="text-tire mt-1">
          {{ $t('password_reset.success.new_user.desc') }}
        </p>
      </div>

      <div v-else>
        <h1 class="title-1-bold md:bold-title sm:mt-6 mb-2 text-center">
          {{ $t('password_reset.new_user.title') }}
        </h1>
        <p class="text-tire mt-1">
          {{ $t('password_reset.new_user.desc') }}
        </p>
      </div>
    </div>

    <!-- Existing user -->
    <div
      v-if="!newUser"
      class="flex flex-col items-center text-center mb-10"
      :class="{ 'hidden-h-sm': !success }"
    >
      <div v-if="success">
        <h1 class="title-1-bold md:bold-title sm:mt-6 mb-2 text-center">
          {{ $t('password_reset.success.existing_user.title') }}
        </h1>
        <p class="text-tire mt-1">
          {{ $t('password_reset.success.existing_user.desc') }}
        </p>
      </div>

      <div v-else>
        <h1 class="title-1-bold md:bold-title sm:mt-6 mb-2 text-center">
          {{ $t('password_reset.existing_user.title') }}
        </h1>
      </div>
    </div>

    <!-- Change password form -->
    <ValidationObserver
      v-if="!success"
      tag="form"
      ref="form"
      class="flex flex-col flex-1 justify-between w-full mx-auto"
      v-slot="{ invalid }"
      @submit.prevent="formSubmit"
    >
      <div class="space-y-3">
        <Input
          v-model="formData.password"
          :label="$t('password_reset.password.label')"
          :placeholder="$t('password_reset.password.placeholder')"
          :type="type"
          :icon="type === 'password' ? 'eye' : 'eye-off'"
          :submitted="submitted"
          rules="required|min:8|max:72"
          name="password"
          tabindex="1"
          @iconClick="type = type === 'password' ? 'text' : 'password'"
        />

        <Input
          v-model="formData.passwordRepeat"
          :label="$t('password_reset.password_repeat.label')"
          :placeholder="$t('password_reset.password_repeat.placeholder')"
          :type="repeatType"
          :icon="repeatType === 'password' ? 'eye' : 'eye-off'"
          :submitted="submitted"
          rules="required|min:8|max:72|password:@password"
          name="passwordRepeat"
          tabindex="2"
          @iconClick="
            repeatType = repeatType === 'password' ? 'text' : 'password'
          "
        />

        <p class="text-opal">{{ error }}</p>
      </div>

      <!-- Submit button -->
      <Button
        v-if="!error"
        class="mt-10 self-center"
        pill
        upper
        type="submit"
        :loading="loading"
        :disabled="invalid"
      >
        {{ $t('password_reset.submit') }}
      </Button>
    </ValidationObserver>

    <!-- Back to login page button -->
    <Button
      v-if="error || success"
      upper
      pill
      class="mt-8 px-5 py-3 self-center"
      @click="$router.replace({ name: 'Login' })"
    >
      <span v-if="newUser || error">
        {{ $t('password_reset.success.new_user.cta') }}
      </span>
      <span v-else>
        {{ $t('password_reset.success.existing_user.cta') }}
      </span>
    </Button>
  </div>
</template>

<script>
import Input from '@/components/Input'

export default {
  name: 'PasswordReset',
  components: {
    Input
  },
  data: () => ({
    formData: {
      password: '',
      passwordRepeat: ''
    },
    submitted: false,
    loading: false,
    type: 'password',
    repeatType: 'password',
    success: false,
    newUser: false,
    error: ''
  }),
  created() {
    this.newUser = this.$route.meta.newUser
  },
  methods: {
    formSubmit() {
      this.submitted = true

      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading = true

          this.$http
            .post(this.$api.auth.password_reset, {
              token: this.$route.params.token,
              newPassword: this.formData.password
            })
            .then(() => {
              this.success = true
            })
            .catch(() => {
              this.error = this.$t('password_reset.error', {
                type: this.newUser
                  ? this.$t('password_reset.create').toLowerCase()
                  : this.$t('password_reset.edit').toLowerCase()
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
