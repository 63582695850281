<template>
  <div class="flex-1 h-full">
    <Planning v-if="!isMobile" :loading="loading" />
    <MobilePlanning v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Planning from '@/views/planning/Planning'
import MobilePlanning from '@/views/planning/MobilePlanning'

export default {
  name: 'RootPlanning',
  components: {
    Planning,
    MobilePlanning
  },
  data: () => ({
    loading: true
  }),
  computed: {
    ...mapState('Breakpoints', ['isMobile'])
  }
}
</script>
