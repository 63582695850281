export default {
  computed: {
    translations() {
      const keys = Object.keys(this.formData)

      return this.settings.locales
        .map(el => {
          el.keyToShow = el.labels[this.$i18n.locale]
          return el
        })
        .filter(el => !keys.includes(el.locale))
    }
  }
}
