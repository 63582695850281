<template>
  <ValidationObserver
    ref="form"
    tag="form"
    class="flex flex-col justify-between h-full"
    autocomplete="off"
    v-slot="{ invalid }"
    @submit.prevent="submit"
  >
    <RegistrationHeader
      :title="$t('registration.email.title')"
      :step="1"
      :steps="5"
      class="mb-8"
    />

    <div>
      <Input
        v-model="email"
        name="email"
        type="email"
        rules="required|email"
        :placeholder="$t('registration.email.placeholder')"
        :submitted="submitted"
        autoFocus
        mode="large"
      />

      <p v-if="error" class="text-opal">
        {{ error }}
      </p>
    </div>

    <Button
      class="mt-10 self-center"
      pill
      upper
      :disabled="invalid"
      type="submit"
    >
      {{ $t('registration.email.next') }}
    </Button>
  </ValidationObserver>
</template>

<script>
import Input from '@/components/Input'
import RegistrationHeader from '@/components/RegistrationHeader'

export default {
  name: 'Email',
  components: {
    Input,
    RegistrationHeader
  },
  data: () => ({
    submitted: false
  }),
  computed: {
    error() {
      return this.$store.state.Registration.error
    },
    email: {
      get() {
        return this.$store.state.Registration.formData.email
      },
      set(val) {
        this.$store.dispatch('Registration/setFormData', { email: val })
      }
    }
  },
  methods: {
    submit() {
      this.submitted = true

      this.$refs.form.validate().then(success => {
        if (success) {
          this.$router.push({ name: 'Registration.firstName' })
        }
      })
    }
  }
}
</script>
