<template>
  <div class="flex overflow-hidden">
    <Sidebar
      v-if="!$router.currentRoute.matched.some(el => el.meta.hideSidebar)"
    />

    <div class="flex-1 bg-snow md:bg-ice">
      <router-view />
    </div>

    <Panel />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Panel from '@/components/Panel'
import Sidebar from '@/components/Sidebar'

export default {
  name: 'App',
  components: {
    Panel,
    Sidebar
  },
  computed: {
    ...mapState('Auth', ['jwtToken']),
    ...mapState('Transitions', ['reversed']),
    ...mapState('Panel', ['md'])
  },
  async created() {
    try {
      await this.fetchSettings()
    } catch (err) {
      const url =
        'https://www.' +
        window.location.hostname
          .split('.')
          .slice(1)
          .join('.') +
        '/not-found'

      window.location.href = url
    }

    if (window.innerWidth < this.md) {
      this.setMobile(true)
    }

    if (window.innerWidth >= this.md) {
      this.setMobile(false)
      this.setNavigation(true)
    }

    if (this.$route.name !== 'Login' && this.jwtToken) {
      await this.fetchPermissions()
      await this.fetchMe()
    }
  },
  mounted() {
    this.topNav = document.querySelector('.top-nav')
    this.page = document.querySelector('.page')

    this.setPageHeight()

    window.addEventListener('resize', () => {
      if (window.innerWidth < this.md) {
        this.setMobile(true)
        this.setNavigation(false)
        this.closePanel()
      }

      if (window.innerWidth >= this.md) {
        this.setMobile(false)
        this.setNavigation(true)
        this.setPanel()
        this.setPageHeight()
      }
    })
  },
  watch: {
    $route() {
      this.setPageHeight()
    }
  },
  methods: {
    ...mapActions('Navigation', ['setNavigation']),
    ...mapActions('Breakpoints', ['setMobile']),
    ...mapActions('Panel', ['closePanel', 'setPanel']),
    ...mapActions('Settings', ['fetchSettings']),
    ...mapActions('Auth', ['fetchPermissions', 'fetchMe']),

    setPageHeight() {
      this.$nextTick(() => {
        const topNav = document.querySelector('.top-nav')
        const page = document.querySelector('.page')

        if (page && topNav) {
          page.setAttribute(
            'style',
            `height: calc(100vh - 1rem - ${topNav.clientHeight}px)`
          )
        }
      })
    }
  }
}
</script>

<style lang="scss">
html {
  height: -webkit-fill-available;
}
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
</style>
