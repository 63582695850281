<template>
  <div>
    <div class="lg:flex items-center">
      <SearchInput
        :value="value"
        :placeholder="$t('pages.users.search')"
        :bg="inputBg"
        @input="onInput"
      />

      <Button
        v-if="!hideAddUserButton"
        pill
        upper
        class="mt-4 lg:mt-0 lg:ml-4 w-full lg:w-auto"
        :disabled="disabled"
        @click="addUser"
      >
        {{ $t('pages.users.add_employee') }}
      </Button>
    </div>

    <div class="md:flex md:space-x-6 space-y-4 md:space-y-0">
      <Checkbox
        v-model="filters.students"
        :label="$t('pages.users.filters.students')"
      />
      <Checkbox
        v-model="filters.flexis"
        :label="$t('pages.users.filters.flexis')"
      />
      <Checkbox
        v-if="!hideArchivedFilter"
        v-model="filters.archived"
        :label="$t('pages.users.filters.arvhived')"
      />
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox'
import SearchInput from '@/components/SearchInput'

export default {
  name: 'SearchWithFilters',
  components: {
    Checkbox,
    SearchInput
  },
  props: {
    value: {
      type: String,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    hideAddUserButton: {
      type: Boolean,
      required: false
    },
    hideArchivedFilter: {
      type: Boolean,
      required: false
    },
    inputBg: {
      type: String,
      required: false,
      default: () => 'bg-snow'
    }
  },
  methods: {
    onInput(val) {
      this.$emit('input', val)
    },
    addUser() {
      this.$emit('addUser')
    }
  }
}
</script>
