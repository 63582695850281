<template>
  <div class="background">
    <ValidationObserver
      tag="form"
      ref="form"
      autocomplete="none"
      v-slot="{ invalid }"
      @submit.prevent="formSubmit"
    >
      <TopNav
        :title="username"
        :label="user && user.archived ? $t('pages.user.archived') : ''"
        :back-button="!isMePage"
      >
        <template v-if="user" #buttons>
          <div class="flex items-center h-10 space-x-3 mt-8 md:mt-0">
            <Button
              v-if="user.archived"
              pill
              upper
              px="4"
              @click="onUnarchiveClick"
            >
              {{ $t('pages.user.un_archive') }}
            </Button>

            <!-- Archive button -->
            <Button
              v-if="user.metadata.security.archive && !user.archived"
              class="flex-center"
              pill
              px="0"
              py="0"
              :tooltip="$t('tooltips.users.archive')"
              :disabled="user.archived"
              @click="onArchiveClick"
            >
              <component
                class="w-5 h-5"
                :is="require('@/assets/archive.svg')"
              />
            </Button>

            <!-- Save button -->
            <Button
              v-if="user.metadata.security.edit && !user.archived"
              pill
              upper
              py="0"
              type="submit"
              :loading="saving"
              :disabled="invalid || user.archived"
            >
              {{ $t('pages.user.save') }}
            </Button>

            <!-- Logout button -->
            <Button v-if="isMePage" py="0" pill upper @click="onLogoutClick">
              <component
                class="w-5 h-5 mr-3"
                :is="require('@/assets/logout.svg')"
              />

              {{ $t('pages.user.logout') }}
            </Button>
          </div>
        </template>
      </TopNav>

      <div class="page">
        <div>
          <div v-if="user" class="grid xl:grid-cols-2 gap-6">
            <Personalia :user="user" :submitted="submitted" />

            <div class="grid gap-6">
              <Address :user="user" :submitted="submitted" />
              <Administration :user="user" :submitted="submitted" />
            </div>

            <div class="md:hidden">
              <h2 class="title-2 mb-3">
                {{ $t('pages.user.statistics.title') }}
              </h2>

              <div class="bg-snow px-0 py-6 md:p-6 rounded-lg space-y-3">
                <UserStatistics />
              </div>
            </div>
          </div>

          <PageLoading
            v-else
            class="mt-8"
            :loading="loading"
            :noneFound="$t('pages.users.none_found')"
          />
        </div>
      </div>
    </ValidationObserver>

    <ArchiveUser
      v-if="archiveModal"
      :username="username"
      :loading="archiving"
      @close="archiveModal = false"
      @confirm="onArchiveUser"
    />

    <UnarchiveUser
      v-if="unarchiveModal"
      :username="username"
      :loading="unarchiving"
      @close="unarchiveModal = false"
      @confirm="onUnarchiveUser"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { mapActions, mapState } from 'vuex'
import Address from '@/components/forms/Address'
import PageLoading from '@/components/PageLoading'
import Personalia from '@/components/forms/Personalia'
import UserStatistics from '@/components/UserStatistics'
import Administration from '@/components/forms/Administration'
import ArchiveUser from '@/components/modals/ArchiveUser.modal'
import UnarchiveUser from '@/components/modals/UnarchiveUser.modal'
import WhiteFormBox from '@/components/WhiteFormBox'
import TopNav from '@/components/TopNav'
import BackButton from '@/components/BackButton'

export default {
  name: 'User',
  components: {
    Address,
    Personalia,
    PageLoading,
    ArchiveUser,
    UnarchiveUser,
    UserStatistics,
    Administration,
    WhiteFormBox,
    TopNav,
    BackButton
  },
  data: () => ({
    username: '',
    user: null,
    loading: true,
    submitted: false,
    saving: false,
    addressPrefilled: false,
    archiveModal: false,
    unarchiveModal: false,
    archiving: false,
    unarchiving: false
  }),
  watch: {
    $route: {
      deep: true,
      handler() {
        this.customCreated()
      }
    }
  },
  computed: {
    ...mapState('Users', ['addressChanged']),
    ...mapState('Auth', ['me']),

    isMePage() {
      return this.$route.name === 'MyProfile'
    }
  },
  created() {
    this.customCreated()
  },
  methods: {
    ...mapActions('Users', [
      'fetchUsers',
      'fetchUser',
      'archiveUser',
      'unarchiveUser',
      'setStatistics'
    ]),
    ...mapActions('Panel', ['setPanel']),
    ...mapActions('Auth', ['fetchMe']),

    onLogoutClick() {
      this.$router.replace({ name: 'Login' })
    },

    customCreated() {
      const id = parseInt(this.$route.params.id)

      if (this.isMePage) {
        if (this.me && this.me.id === id) {
          this.getUser()
          this.getUserStatistics()
        } else {
          this.$router.replace({ name: 'Dashboard' })
        }

        if (!this.me) {
          this.fetchMe()
            .then(res => {
              if (res.data.id !== id) {
                this.$router.replace({ name: 'Dashboard' })
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      }

      if (this.$route.name === 'User') {
        this.getUser()
        this.getUserStatistics()
      }
    },

    getUser(id) {
      const userId = id ? id : this.$route.params.id

      this.fetchUser(userId)
        .then(res => {
          const user = cloneDeep(res.data)

          if (user.firstName && user.lastName) {
            this.username = `${user.firstName} ${user.lastName}`
          }

          if (!user.employeeData) {
            user.employeeData = {}
          }

          if (!user.employeeData.address) {
            this.addressPrefilled = true
            user.employeeData.address = {
              addition: '',
              city: '',
              country: '',
              number: '',
              postalCode: '',
              street: ''
            }
          }

          if (user.employeeData.birthDate) {
            user.employeeData.birthDate = user.employeeData.birthDate
              .split('-')
              .reverse()
              .join('/')
          }

          this.user = user
          this.setPanel({ component: 'UserStatistics', closable: false })
        })
        .finally(() => {
          this.loading = false
        })
    },
    getUserStatistics() {
      this.$http
        .get(this.$api.user.statistics.replace('{id}', this.$route.params.id))
        .then(res => {
          this.setStatistics(res.data)
        })
    },
    onUnarchiveClick() {
      if (this.user.archived) {
        this.unarchiveModal = true
      }
    },
    onArchiveClick() {
      if (!this.user.archived) {
        this.archiveModal = true
      }
    },
    onArchiveUser() {
      this.archiving = true

      this.archiveUser(this.$route.params.id)
        .then(() => {
          this.$toasted.success(this.$t('pages.user.messages.archive.success'))
          this.fetchUsers()
          this.getUser()
        })
        .catch(() => {
          this.$toasted.error(this.$t('pages.user.messages.archive.error'))
        })
        .finally(() => {
          this.archiveModal = false
          this.archiving = false
        })
    },
    onUnarchiveUser() {
      this.unarchiving = true

      this.unarchiveUser(this.$route.params.id)
        .then(() => {
          this.$toasted.success(
            this.$t('pages.user.messages.unarchive.success')
          )
          this.fetchUsers()
          this.getUser()
        })
        .catch(() => {
          this.$toasted.error(this.$t('pages.user.messages.unarchive.error'))
        })
        .finally(() => {
          this.unarchiveModal = false
          this.unarchiving = false
        })
    },
    formSubmit() {
      if (!this.user.archived) {
        const user = cloneDeep(this.user)
        this.submitted = true

        if (user.employeeData.birthDate) {
          user.employeeData.birthDate = user.employeeData.birthDate
            .split('/')
            .reverse()
            .join('-')
        }

        if (!this.addressChanged && this.addressPrefilled) {
          delete user.employeeData.address
        }

        this.$refs.form.validate().then(success => {
          if (success) {
            this.saving = true

            this.$http
              .patch(
                this.$api.user.edit_user.replace('{id}', this.$route.params.id),
                user
              )
              .then(res => {
                this.username = `${user.firstName} ${user.lastName}`
                this.$toasted.success(
                  this.$t('pages.user.messages.user_edited.success', {
                    name: res.data.firstName
                  })
                )
                this.submitted = false
              })
              .catch(() => {
                this.$toasted.error(
                  this.$t('pages.user.messages.user_edited.error')
                )
              })
              .finally(() => {
                this.saving = false
              })
          }
        })
      }
    }
  }
}
</script>
