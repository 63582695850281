export const namespaced = true

export const state = {
  formData: {
    contractType: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordRepeat: ''
  }
}

export const mutations = {
  SET_FORM_DATA(state, formData) {
    state.formData = formData
  }
}

export const actions = {
  setFormData({ state, commit }, payload) {
    const formData = state.formData

    for (const value of Object.keys(payload)) {
      formData[value] = payload[value]
    }

    commit('SET_FORM_DATA', formData)
  },
  clearFormData({ state, commit }) {
    const formData = state.formData

    for (const value of Object.keys(state.formData)) {
      formData[value] = ''
    }

    commit('SET_FORM_DATA', formData)
  }
}
