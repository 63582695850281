<template>
  <div class="bg-snowball rounded-lg w-full overflow-hidden">
    <div
      class="flex cursor-pointer justify-between items-center p-3 px-5"
      :class="[
        titleClass,
        {
          'flex-row': !hasButtons,
          'flex-col-reverse sm:flex-row': hasButtons
        }
      ]"
    >
      <!-- Title -->
      <div class="w-full" @click="$emit('click')">
        {{ title }}
      </div>

      <!-- Buttons -->
      <div
        :class="{
          'flex items-center justify-between sm:justify-end w-full sm:w-auto sm:py-0 mb-3 sm:mb-0': hasButtons
        }"
      >
        <!-- Other buttons -->
        <slot v-if="hasButtons" name="buttons" />

        <!-- Arrow Buttons -->
        <button
          v-if="!hideArrow"
          class="w-10 h-10 rounded-full flex items-center justify-center"
          :class="{
            'hover:bg-track': !hideArrow
          }"
          @click="$emit('click')"
        >
          <component
            class="w-8 h-8 transform transition-all duration-200 ease-in-out"
            :class="{
              'rotate-180 text-coal': open,
              'text-void': !open
            }"
            :is="require('@/assets/chevron-down.svg')"
          />
        </button>
      </div>
    </div>

    <div
      v-show="open && dropdown"
      :id="title"
      class="dropdown px-5 pb-4"
      :class="dropdownClass"
    >
      <p v-if="isHtml" v-html="dropdown" />
      <p v-else>{{ dropdown }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownBox',
  props: {
    title: {
      type: String,
      required: false,
      default: () => ''
    },
    dropdown: {
      type: String,
      required: false,
      default: () => ''
    },
    hideArrow: {
      type: Boolean,
      required: false,
      default: () => false
    },
    isHtml: {
      type: Boolean,
      required: false,
      default: () => true
    },
    titleClass: {
      type: String,
      required: false,
      default: () => ''
    },
    dropdownClass: {
      type: String,
      required: false,
      default: () => ''
    },
    open: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    hasButtons() {
      return !!this.$slots.buttons
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  &::v-deep {
    ol,
    ul {
      padding: 0 0 0 1.5rem;
    }

    ol {
      list-style: decimal;
    }

    ul {
      list-style-type: disc;
    }

    a {
      @apply text-eiffel hover:text-tower transition-all duration-100 ease-in-out;
    }
  }
}
</style>
