<template>
  <div class="background">
    <TopNav :title="$t('pages.templates.title')">
      <template #buttons>
        <Button
          v-if="permissions && permissions.template.create"
          pill
          upper
          :disabled="open && component ? true : false"
          @click="onAddNewTemplate"
        >
          {{ $t('pages.templates.add_template') }}
        </Button>
      </template>
    </TopNav>

    <div class="page">
      <List
        v-if="templates.length && !loading"
        :headers="headers"
        :items="templates"
        :buttons="buttons"
        @itemClick="templateClick"
      />

      <PageLoading
        v-else
        class="mt-10"
        :loading="loading"
        :noneFound="$t('pages.templates.none_found')"
      />
    </div>

    <!-- Delete template modal -->
    <DeleteTemplate
      v-if="deleteModal"
      :loading="deleteLoading"
      :template="selectedTemplate"
      @close="deleteModal = false"
      @confirm="deleteTemplate"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import List from '@/components/List'
import PageLoading from '@/components/PageLoading'
import TopNav from '@/components/TopNav'
import DeleteTemplate from '@/components/modals/DeleteTemplate.modal'

export default {
  name: 'Templates',
  components: {
    List,
    PageLoading,
    TopNav,
    DeleteTemplate
  },
  data() {
    return {
      loading: true,
      deleteModal: false,
      selectedTemplate: false,
      deleteLoading: false,

      headers: [
        {
          text: this.$t('pages.templates.table.name'),
          value: 'name'
        }
      ],
      buttons: [
        {
          icon: 'pencil',
          access: 'metadata.security.edit',
          click: this.templateClick
        },
        {
          icon: 'trash',
          access: 'metadata.security.delete',
          click: this.openDeleteModal
        }
      ]
    }
  },
  computed: {
    ...mapState('Auth', ['permissions']),
    ...mapState('Panel', ['open', 'component']),
    ...mapState('Templates', ['templates'])
  },
  created() {
    this.fetchTemplates().finally(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('Templates', ['deleteTemplate', 'fetchTemplates']),
    ...mapActions('Panel', ['setPanel']),

    onAddNewTemplate() {
      if (this.permissions.template.create) {
        this.setPanel({ component: 'AddTemplate', closable: true })
      }
    },
    templateClick(template) {
      if (template.metadata.security.view) {
        this.$router.push({ name: 'Template', params: { id: template.id } })
      }
    },
    openDeleteModal(item) {
      if (item.metadata.security.delete) {
        this.selectedTemplate = item
        this.deleteModal = true
      }
    },
    deleteTemplate() {
      this.deleteLoading = true

      this.$store
        .dispatch('Templates/deleteTemplate', this.selectedTemplate.id)
        .then(() => {
          this.fetchTemplates()
          this.deleteModal = false
          this.deleteLoading = false
        })
    }
  }
}
</script>
