<template>
  <div class="background auth relative">
    <div
      class="page flex flex-col justify-start h-screen px-4"
      :class="{ 'sm:justify-center': this.verticallyCentered }"
    >
      <div class="h-full md:h-auto flex flex-col">
        <div
          class="flex flex-col bg-snow w-full sm:w-11/12 md:w-9/12 lg:w-6/12 xl:w-5/12 mx-auto sm:rounded-xl p-0 sm:py-10 flex-1 sm:h-auto overflow-hidden"
        >
          <!-- Polly icon -->
          <div class="flex-center mt-4 sm:mt-0 mb-8 hidden-h-sm">
            <transition
              appear
              appear-active-class="animate__animated animate__flipInY flex-center"
            >
              <component
                :is="require('@/assets/logo-blue-text.svg')"
                class="w-1/3 sm:w-32 animated"
              />
            </transition>
          </div>

          <hr class="text-snowball hidden sm:block" />

          <!-- Content -->
          <div class="sm:pb-0 sm:pt-5 flex-1">
            <transition
              v-bind:key="$route.name"
              :enter-active-class="
                `animate__animated animate__faster ${
                  reversed ? 'animate__slideInLeft' : 'animate__slideInRight'
                }`
              "
              :leave-active-class="
                `animate__animated animate__slideOutLeft animate__faster ${
                  reversed ? 'animate__slideOutRight' : 'animate__slideOutLeft'
                }`
              "
              mode="out-in"
            >
              <router-view class="sm:px-8" />
            </transition>
          </div>
        </div>

        <!-- Terms and Privacy -->
        <div
          class="space-y-2 self-center text-center mt-3 hidden-h-sm mb-1"
          :class="{
            'hidden sm:block': $route.name !== 'Login'
          }"
        >
          <router-link
            :to="{ name: 'TermsAndConditionsLogin' }"
            class="text-void hover:text-coal transition-all duration-200 ease-in-out footnote block"
          >
            {{ $t('sidebar.items.terms_and_conditions') }}
          </router-link>

          <router-link
            :to="{ name: 'PrivacyLogin' }"
            class="text-void hover:text-coal transition-all duration-200 ease-in-out footnote block"
          >
            {{ $t('sidebar.items.privacy') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Auth',
  computed: {
    verticallyCentered() {
      return ![
        'PrivacyLogin',
        'Privacy',
        'TermsAndConditionsLogin',
        'TermsAndConditions'
      ].includes(this.$route.name)
    },
    reversed() {
      return this.$store.state.Transitions.reversed
    }
  }
}
</script>

<style lang="scss">
.auth {
  --animate-duration: 700ms;
}
</style>
