import i18n from '@/locales'
export const namespaced = true

export const state = {
  workAreas: []
}

export const mutations = {
  SET_WORK_AREAS(state, workAreas) {
    state.workAreas = workAreas
  }
}

export const actions = {
  fetchWorkAreas({ commit }) {
    const { $http, $api } = this._vm

    return $http.get($api.work_area.list).then(res => {
      const items = res.data.map(el => {
        const trans = el.translations[i18n.locale]
        el.name = trans ? trans.name : i18n.t('general.translation_not_found')
        delete el.translations
        return el
      })

      commit('SET_WORK_AREAS', items)
    })
  },
  addWorkArea(_, payload) {
    const { $http, $api } = this._vm

    return $http.post($api.work_area.create, {
      translations: {
        [i18n.locale]: {
          name: payload
        }
      }
    })
  }
}
