<template>
  <div class="planning-info">
    <p
      v-if="permissions.planningShift.create"
      class="caption-1 text-void p-8"
      v-html="$t('pages.planning.panel.info')"
    />

    <div class="bg-ice w-full" style="height: 2px;" />

    <div class="p-6" :class="{ 'pt-16': !permissions.planningShift.create }">
      <DatePicker
        ref="datePicker"
        v-model="date"
        title-position="left"
        is-expanded
        class="borderless"
        :attributes="attrs"
        :first-day-of-week="2"
        :locale="{ id: 'nl', masks: { weekdays: 'WW' } }"
        is-required
        @update:to-page="e => (pageInfo = e)"
      />
    </div>

    <div
      v-if="emptyShiftsDayWarning"
      class="bg-ice w-full"
      style="height: 2px;"
    />

    <div v-if="emptyShiftsDayWarning" class="p-8 flex">
      <div class="w-3 h-3 bg-opal rounded-full mt-2 mr-3" />

      <p>
        {{ emptyShiftsDayWarning.message }}
      </p>
    </div>
  </div>
</template>

<script>
import { reverseDate } from '@/scripts/dates'
import { mapActions, mapGetters, mapState } from 'vuex'
import dayjs from 'dayjs'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'PlanningInfo',
  components: {
    DatePicker
  },
  data: () => ({
    pageInfo: null
  }),
  computed: {
    ...mapGetters('Planning', ['attrs', 'emptyShiftsDayWarning', 'date']),
    ...mapState('Auth', ['permissions']),

    date: {
      get() {
        return reverseDate(this.$store.state.Planning.date)
      },
      set(date) {
        if (date) {
          this.setDate(dayjs(date))
        }
      }
    }
  },
  watch: {
    date() {
      this.$refs.datePicker.move(this.date)
    }
  },
  mounted() {
    const leftArrow = this.$refs.datePicker.$children[0].$children[2].$el
    const rightArrow = this.$refs.datePicker.$children[0].$children[3].$el

    leftArrow.addEventListener('click', () => {
      this.pageChange()
    })

    rightArrow.addEventListener('click', () => {
      this.pageChange()
    })
  },
  methods: {
    ...mapActions('Planning', ['setDate', 'fetchPlanningStatuses']),
    ...mapActions('WorkAreas', ['fetchWorkAreas']),

    pageChange() {
      const timeout = setTimeout(() => {
        const date = dayjs()
          .set('month', this.pageInfo.month - 1)
          .set('year', this.pageInfo.year)

        const from = reverseDate(date.startOf('month'))
        const to = reverseDate(date.endOf('month'))

        this.fetchPlanningStatuses({ from, to })
        clearTimeout(timeout)
      })
    }
  }
}
</script>
