<template>
  <ValidationObserver
    ref="form"
    tag="form"
    v-slot="{ invalid }"
    autocomplete="off"
    @submit.prevent="formSubmit"
  >
    <Modal
      :title="$t(`components.planner.${shift.id ? 'edit' : 'add'}_shift.title`)"
      :desc="$t(`components.planner.${shift.id ? 'edit' : 'add'}_shift.desc`)"
      icon="clock"
    >
      <TemplateShiftForm class="mt-5" :shift="shift" :submitted="submitted" />

      <template #buttons>
        <!-- Submit -->
        <Button
          class="py-3 w-full sm:w-auto rounded-md ml-0 sm:ml-3"
          pill
          upper
          :loading="loading"
          :disabled="invalid"
          type="submit"
        >
          {{
            $t(`components.planner.${shift.id ? 'edit' : 'add'}_shift.confirm`)
          }}
        </Button>

        <!-- Cancel -->
        <Button
          class="py-3 w-full sm:w-auto rounded-md mt-3 sm:mt-0"
          pill
          upper
          text="tire"
          bg="snow"
          hover="ice"
          @click="$emit('close')"
        >
          {{
            $t(`components.planner.${shift.id ? 'edit' : 'add'}_shift.cancel`)
          }}
        </Button>
      </template>
    </Modal>
  </ValidationObserver>
</template>

<script>
import Modal from '@/components/Modal'
import TemplateShiftForm from '@/components/TemplateShiftForm'

export default {
  name: 'Shift',
  components: {
    Modal,
    TemplateShiftForm
  },
  props: {
    shift: {
      type: Object,
      required: true
    },
    loading: {
      Type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    submitted: false
  }),
  methods: {
    formSubmit() {
      this.submitted = true

      this.$refs.form.validate().then(success => {
        if (success) {
          this.$emit('confirm')
        }
      })
    }
  }
}
</script>
