module.exports = {
  darkMode: false, // or 'media' or 'class',
  theme: {
    screens: {
      xs: '364px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px'
    },
    container: {
      center: true
    },
    minWidth: {
      8: '2rem',
      72: '18rem'
    },
    colors: {
      transparent: 'transparent',

      // Greyvalues
      snow: '#ffffff',
      ice: '#f7f7f7',
      snowball: '#e0e0e0',
      track: '#d3d3d3',
      dirt: '#bdbdbd',
      void: '#828282',
      tire: '#4f4f4f',
      coal: '#333333',
      mine: '#161616',

      // Colors
      sun: '#ffbd3b',
      orange: '#df9436',
      lion: '#face9e',
      coral: '#f4845f',
      opal: '#f25c54',
      ruby: '#c94740',
      eiffelLift: '#C7DBF9',
      sky: '#C7DBF9',
      eiffel: '#0667F9',
      tower: '#0a52bf',
      grape: '#9263f8',
      eggplant: '#5d5183',
      space: '#171f45',
      apple: '#52d6a6'
    },
    fontSize: {
      34: '34px',
      28: '28px',
      24: '24px',
      22: '22px',
      17: '17px',
      16: '16px',
      15: '15px',
      14: '14px',
      13: '13px',
      12: '12px',
      11: '11px'
    },
    fontFamily: {
      sans: ['Lato', 'Sans-serif']
    }
  },
  variants: {
    extend: {
      backgroundColor: ['group-hover'],
      translate: ['group-hover']
    }
  },
  plugins: []
}
