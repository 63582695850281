import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const dateFromTime = time => {
  const hours = parseInt(time.split(':')[0])
  const minutes = parseInt(time.split(':')[1])

  return dayjs
    .utc()
    .hour(hours)
    .minute(minutes)
    .second(0)
}

export const reverseDate = date => {
  return dayjs(date).format('YYYY-MM-DD')
}
