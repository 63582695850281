<template>
  <Modal :title="title" :desc="desc" icon="exclamation" iconColor="opal">
    <Checkbox
      v-if="extraCheck"
      v-model="checked"
      class="mt-7"
      color="opal"
      :label="extraCheck"
    />

    <template #buttons>
      <!-- Submit -->
      <Button
        class="py-3 w-full sm:w-auto rounded-md ml-0 sm:ml-3"
        pill
        upper
        :loading="loading"
        bg="opal"
        hover="ruby"
        :disabled="extraCheck ? extraCheck && !checked : false"
        @click="onConfirmClick"
      >
        {{ confirmText }}
      </Button>

      <!-- Cancel -->
      <Button
        class="py-3 w-full sm:w-auto rounded-md mt-3 sm:mt-0"
        pill
        upper
        text="tire"
        bg="snow"
        hover="ice"
        @click="$emit('close')"
      >
        {{ cancelText }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import i18n from '@/locales'
import Modal from '@/components/Modal'
import Checkbox from '@/components/Checkbox'

export default {
  name: 'DeleteConfirm',
  components: {
    Modal,
    Checkbox
  },
  props: {
    title: {
      type: String,
      required: false,
      default: () => ''
    },
    desc: {
      type: String,
      required: false,
      default: () => ''
    },
    loading: {
      Type: Boolean,
      required: false,
      default: () => false
    },
    cancelText: {
      type: String,
      required: false,
      default: () => i18n.t('modals.templates.delete_confirm.cancel')
    },
    confirmText: {
      type: String,
      required: false,
      default: () => i18n.t('modals.templates.delete_confirm.confirm')
    },
    extraCheck: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  data: () => ({
    checked: false
  }),
  methods: {
    onConfirmClick() {
      if (this.extraCheck) {
        if (this.checked) {
          this.$emit('confirm')
        }
      } else {
        this.$emit('confirm')
      }
    }
  }
}
</script>
