<template>
  <ValidationObserver
    tag="form"
    ref="form"
    class="flex flex-col justify-between h-full"
    v-slot="{ invalid }"
    @submit.prevent="formSubmit"
  >
    <RegistrationHeader
      :title="$t('two_fa.title')"
      :desc="$t('two_fa.desc')"
      back="Login"
      class="mb-8"
    />

    <Input
      v-model="code"
      name="twoFA"
      rules="required|digits:6"
      :placeholder="$t('two_fa.input.placeholder')"
      mode="large"
    />

    <Button
      class="mt-10 self-center"
      pill
      upper
      :disabled="invalid"
      :loading="loading"
      type="submit"
    >
      {{ $t('two_fa.submit') }}
    </Button>
  </ValidationObserver>
</template>

<script>
import RegistrationHeader from '@/components/RegistrationHeader'
import Input from '@/components/Input'

export default {
  name: 'TwoFA',
  components: {
    RegistrationHeader,
    Input
  },
  data: () => ({
    submitted: false,
    error: '',
    code: '',
    loading: false
  }),
  methods: {
    formSubmit() {
      this.submitted = true

      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading = true

          this.$http
            .post(this.$api.auth.two_fa, { code: this.code })
            .then(res => {
              const { token, refreshToken } = res.data
              this.$store.dispatch('Auth/setJwtToken', token)
              this.$store.dispatch('Auth/setRefreshToken', refreshToken)

              this.$store
                .dispatch('Auth/fetchMe')
                .then(() => {
                  return this.$store.dispatch('Auth/fetchPermissions')
                })
                .then(() => {
                  this.$router.replace({ name: 'Dashboard' })
                })
                .finally(() => {
                  this.loading = false
                })
            })
            .catch(() => {
              this.$toasted.error(this.$t('validations.invalid_two_fa'))
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
