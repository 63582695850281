import Vue from 'vue'

// Scripts
import './scripts/vee-validate'
import './scripts/interceptors'
import './scripts/components'
import './scripts/directives'
import './registerServiceWorker'

import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './locales'
import Ripple from 'vue-ripple-directive'

// Tailwind
import '@/styles/tailwind.scss'
import 'animate.css/animate.min.css'

// Directives
Vue.directive('ripple', Ripple)

Vue.config.productionTip = false

fetch(process.env.BASE_URL + 'settings.json')
  .then(res => res.json())
  .then(settings => {
    store.dispatch('Settings/setSettings', settings)

    new Vue({
      i18n,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
