<template>
  <div class="flex items-center space-x-2">
    <Button
      class="rounded-sm shadow-lg text-void self-stretch"
      bg="snow"
      hover="ice"
      px="2"
      @click="$emit('prevDay')"
    >
      <component :is="require('@/assets/arrow-left.svg')" class="w-5" />
    </Button>

    <Button
      class="date rounded-sm shadow-lg text-void self-stretch"
      bg="snow"
      hover="ice"
      px="2"
      :tooltip="$t('components.planner.today')"
      @click="$emit('reset')"
    >
      {{ date.format('dddd D MMMM YYYY') }}
    </Button>

    <Button
      class="rounded-sm shadow-lg text-void self-stretch"
      bg="snow"
      hover="ice"
      px="2"
      @click="$emit('nextDay')"
    >
      <component :is="require('@/assets/arrow-right.svg')" class="w-5" />
    </Button>

    <Spinner v-if="loading" color="eiffel" class="pl-3" />
  </div>
</template>

<script>
import Spinner from '@/components/Spinner'

export default {
  name: 'DaySelect',
  components: {
    Spinner
  },
  props: {
    date: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.date {
  min-width: 14rem;
}
</style>
