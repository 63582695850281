import store from '@/store'
import Communication from '@/views/communication/Communication'

export default [
  {
    path: '/communication',
    name: 'Communication',
    component: Communication,
    meta: {
      requiresAuth: true,
      access: () => store.getters['Auth/getAccess']('messageBoardItem.create')
    }
  }
]
