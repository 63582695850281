import axios from 'axios'
import api from '@/api'

export const namespaced = true

export const state = {
  settings: JSON.parse(localStorage.getItem('SETTINGS')) || {},
  appSettings: false
}

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload
    localStorage.setItem('SETTINGS', JSON.stringify(payload))
  },
  SET_APP_SETTINGS(state, payload) {
    state.appSettings = payload
  }
}

export const actions = {
  fetchSettings({ commit }) {
    return axios.get(api.general.settings).then(res => {
      commit('SET_SETTINGS', res.data)
    })
  },
  setSettings({ commit }, payload) {
    commit('SET_APP_SETTINGS', payload)
  }
}
