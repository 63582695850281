import Availabilities from '@/views/availabilities/Availabilities'
import AvailabilitiesUser from '@/views/availabilities/AvailabilitiesUser'
import store from '@/store'

export default [
  {
    path: '/availabilities',
    name: 'Availabilities',
    component: Availabilities,
    meta: {
      requiresAuth: true,
      access: () => !store.getters['Auth/getAccess']('planningShift.create')
    }
  },
  {
    path: '/availabilities-user',
    name: 'AvailabilitiesUser',
    component: AvailabilitiesUser,
    meta: {
      requiresAuth: true,
      access: () => store.getters['Auth/getAccess']('planningShift.create')
    }
  }
]
