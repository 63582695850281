<template>
  <div class="background">
    <TopNav :title="$t('pages.work_areas.title')">
      <template #buttons>
        <Button
          v-if="permissions && permissions.workArea.create"
          pill
          upper
          :disabled="open && component ? true : false"
          @click="onAddNewWorkArea"
        >
          {{ $t('pages.work_areas.add_work_area') }}
        </Button>
      </template>
    </TopNav>

    <div class="page">
      <List
        v-if="workAreas.length && !loading"
        :headers="headers"
        :items="workAreas"
        :buttons="permissions.workArea.create ? buttons : []"
        @itemClick="workAreaClick"
      />

      <PageLoading
        v-else
        class="mt-10"
        :loading="loading"
        :noneFound="$t('pages.work_areas.none_found')"
      />
    </div>

    <!-- Delete workarea modal -->
    <DeleteConfirm
      v-if="deleteModal && selectedArea"
      :title="$t('modals.work_areas.delete_confirm.title')"
      :desc="
        $t('modals.work_areas.delete_confirm.desc', { n: selectedArea.name })
      "
      :loading="deleteLoading"
      :cancel-text="$t('modals.work_areas.delete_confirm.cancel')"
      :confirm-text="$t('modals.work_areas.delete_confirm.confirm')"
      @close="deleteModal = false"
      @confirm="deleteWorkArea"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import List from '@/components/List'
import TopNav from '@/components/TopNav'
import PageLoading from '@/components/PageLoading'
import DeleteConfirm from '@/components/modals/DeleteConfirm.modal'

export default {
  name: 'WorkAreas',
  components: {
    List,
    TopNav,
    PageLoading,
    DeleteConfirm
  },
  data() {
    return {
      selectedArea: null,
      deleteModal: false,
      deleteLoading: false,

      loading: true,
      headers: [
        {
          text: this.$t('pages.work_areas.table.name'),
          value: 'name'
        }
      ],
      buttons: [
        {
          icon: 'pencil',
          access: 'metadata.security.edit',
          click: this.workAreaClick
        },
        {
          icon: 'trash',
          access: 'metadata.security.delete',
          click: this.openDeleteModal
        }
      ]
    }
  },
  computed: {
    ...mapState('Auth', ['permissions']),
    ...mapState('WorkAreas', ['workAreas']),
    ...mapState('Panel', ['open', 'component'])
  },
  created() {
    this.$store.dispatch('WorkAreas/fetchWorkAreas').finally(() => {
      this.loading = false
    })
  },
  methods: {
    workAreaClick(area) {
      if (this.permissions.workArea.list) {
        this.$router.push({ name: 'WorkArea', params: { id: area.id } })
      }
    },
    onAddNewWorkArea() {
      if (this.permissions.workArea.create) {
        this.$store.dispatch('Panel/setPanel', {
          component: 'AddWorkArea',
          closable: true
        })
      }
    },
    openDeleteModal(area) {
      this.selectedArea = area
      this.deleteModal = true
    },
    deleteWorkArea() {
      if (this.selectedArea) {
        this.deleteLoading = true

        this.$http
          .delete(
            this.$api.work_area.delete.replace('{id}', this.selectedArea.id)
          )
          .then(() => {
            this.$toasted.success(this.$t('pages.work_areas.delete.success'))
            return this.$store.dispatch('WorkAreas/fetchWorkAreas')
          })
          .finally(() => {
            this.deleteLoading = false
            this.deleteModal = false
          })
      }
    }
  }
}
</script>
