import store from '@/store'
import Templates from '@/views/templates/Templates'
import Template from '@/views/templates/Template'

export default [
  {
    path: '/templates',
    name: 'Templates',
    component: Templates,
    meta: {
      requiresAuth: true,
      access: () => store.getters['Auth/getAccess']('template.list')
    }
  },
  {
    path: '/templates/:id',
    name: 'Template',
    component: Template,
    meta: {
      requiresAuth: true,
      access: () => store.getters['Auth/getAccess']('template.list')
    }
  }
]
