var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.category)?_c('div',{staticClass:"background"},[_c('TopNav',{attrs:{"title":_vm.category.translations[_vm.$i18n.locale]
        ? _vm.category.translations[_vm.$i18n.locale].name
        : _vm.$t('general.translation_not_found'),"back-button":""}}),_c('div',{staticClass:"page"},[(!_vm.loading && _vm.category)?_c('div',[_c('ValidationObserver',{ref:"form",attrs:{"tag":"form","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var invalid = ref.invalid;
return [_c('div',[(Object.keys(_vm.formData).length === 0)?_c('p',{staticClass:"text-opal",domProps:{"innerHTML":_vm._s(_vm.$t('pages.faq_category.form.warning'))}}):_vm._e(),_c('div',{staticClass:"space-y-4"},[_vm._l((_vm.formData),function(item,key,i){return _c('WhiteFormBox',{key:i,attrs:{"label":Object.keys(_vm.formData).length > 1
                  ? _vm.$t('general.translations')[key].label
                  : ''},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('Button',{attrs:{"px":"0","py":"0","pill":"","bg":"transparent","hover":"ice","text":"void"},on:{"click":function($event){return _vm.deleteTranslation(key)}}},[_c(require('@/assets/trash.svg'),{tag:"component",staticClass:"w-6 h-6"})],1)]},proxy:true}],null,true)},[_c('Input',{attrs:{"placeholder":_vm.$t('pages.faq_category.form.input.placeholder'),"name":("name-" + i),"submitted":_vm.submitted,"rules":"required","disabled":!_vm.permissions.faqItem.create},on:{"input":function($event){_vm.formChanged = true}},model:{value:(_vm.formData[key].name),callback:function ($$v) {_vm.$set(_vm.formData[key], "name", $$v)},expression:"formData[key].name"}})],1)}),_c('div',{staticClass:"space-x-4 mt-5"},[(_vm.permissions.faqItem.create)?_c('Button',{attrs:{"pill":"","upper":"","type":"submit","disabled":invalid || !_vm.formChanged,"loading":_vm.submitLoading}},[_vm._v(" "+_vm._s(_vm.$t('pages.faq_category.save'))+" ")]):_vm._e(),(
                  _vm.permissions.faqCategory.create && _vm.translations.length > 0
                )?_c('Button',{attrs:{"pill":"","bg":"transparent","hover":"snowball","text":"void"},on:{"click":function($event){_vm.languageModal = true}}},[_vm._v(" "+_vm._s(_vm.$t('pages.faq_category.form.add_translation'))+" ")]):_vm._e()],1)],2)])]}}],null,false,3914116374)})],1):_c('PageLoading',{staticClass:"h-80",attrs:{"loading":_vm.loading,"noneFound":_vm.$t('pages.faq_category.not_found')}})],1),(_vm.languageModal)?_c('LanguageModal',{attrs:{"items":_vm.translations},on:{"close":function($event){_vm.languageModal = false},"confirm":_vm.addTranslation}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }