<template>
  <ValidationObserver
    ref="form"
    tag="form"
    v-slot="{ invalid }"
    autocomplete="off"
    @submit.prevent="formSubmit"
  >
    <Modal
      :title="$t('modals.add_translation.title')"
      :desc="$t('modals.add_translation.desc')"
      icon="translate"
    >
      <Input
        v-model="selectedLanguage"
        :items="items"
        :placeholder="$t('modals.add_translation.dropdown.placeholder')"
        class="mt-5"
        keyToShow="keyToShow"
        keyToBind="locale"
        name="translation"
      />

      <template #buttons>
        <!-- Submit -->
        <Button
          class="py-3 w-full sm:w-auto rounded-md ml-0 sm:ml-3"
          pill
          upper
          :disabled="invalid"
          type="submit"
        >
          {{ $t('modals.add_translation.confirm') }}
        </Button>

        <!-- Cancel -->
        <Button
          class="px-4 py-3 w-full sm:w-auto rounded-md mt-3 sm:mt-0"
          pill
          upper
          text="tire"
          bg="snow"
          hover="ice"
          @click="$emit('close')"
        >
          {{ $t('modals.add_translation.cancel') }}
        </Button>
      </template>
    </Modal>
  </ValidationObserver>
</template>

<script>
import Modal from '@/components/Modal'
import Input from '@/components/Input'

export default {
  name: 'Language',
  components: {
    Modal,
    Input
  },
  props: {
    items: {
      Type: Array,
      required: true
    }
  },
  data: () => ({
    selectedLanguage: ''
  }),
  methods: {
    formSubmit() {
      this.$emit('confirm', this.selectedLanguage)
      this.selectedLanguage = ''
    }
  }
}
</script>
