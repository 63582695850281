import Vue from 'vue'
import axios from 'axios'
import api from '@/api'
// import jwtDecode from 'jwt-decode'
import { parseDomain } from 'parse-domain'
import store from '@/store'
import router from '@/router'

// Get subdomains
const subDomains = parseDomain(window.location.hostname).subDomains
const labels = parseDomain(window.location.hostname).labels
const subDomainsParsed = subDomains ? subDomains.join('.') : ''

fetch(process.env.BASE_URL + 'settings.json')
  .then(res => res.json())
  .then(settings => {
    let authTokenRequest

    const subdomainMatch = window.location.hostname.match(
      new RegExp(settings.subdomain_extraction_regex)
    )
    const matchedSubdomain =
      subdomainMatch && typeof subdomainMatch.groups.subdomain !== 'undefined'
        ? subdomainMatch.groups.subdomain
        : null
    const baseURL = settings.api_url.replace(
      '<SUB_DOMAIN>',
      matchedSubdomain
        ? matchedSubdomain
        : labels.length === 5
        ? subDomains[0]
        : subDomainsParsed
    )

    const getAuthToken = () => {
      if (!authTokenRequest) {
        authTokenRequest = store.dispatch('Auth/refreshToken')
        authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest)
      }

      return authTokenRequest
    }

    const resetAuthTokenRequest = () => {
      authTokenRequest = null
    }

    // Request interceptor
    axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem('JWT_TOKEN')

        if (token && config.url !== 'auth/register') {
          config.headers.Authorization = `Bearer ${token}`
        }

        config.baseURL = baseURL
        return config
      },
      err => {
        return Promise.reject(err)
      }
    )

    // Response interceptor
    axios.interceptors.response.use(
      response => {
        return Promise.resolve(response)
      },
      async error => {
        const response = error.response
        const config = response.config

        // Reject is usual error.
        if (response.status !== 401) {
          return Promise.reject(config)
        }

        // Log in when refresh call fails.
        if (config.url === 'auth/refresh') {
          router.push({ name: 'Login' })
          return Promise.reject(error)
        }

        // Try to refresh token
        if (!config.__isRetryRequest) {
          return getAuthToken()
            .then(() => axios(error.config))
            .catch(() => Promise.reject(error))
        }

        /* Return default error */
        return Promise.reject(error)
      }
    )

    Vue.prototype.$http = axios
    Vue.prototype.$api = api
  })
