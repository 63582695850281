import Vue from 'vue'
import VueI18n from 'vue-i18n'

// App translations
import nl from './app-translations/nl.json'
import fr from './app-translations/fr.json'

/* Content translations */
import contentNl from './content-translations/nl.js'
import contentFr from './content-translations/fr.js'

import dayjs from 'dayjs'
import nlBe from 'dayjs/locale/nl-be'

dayjs.locale(nlBe)

Vue.use(VueI18n)

const languages = {
  nl: { ...nl, ...contentNl },
  fr: { ...fr, ...contentFr }
}

const i18n = new VueI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages: Object.assign(languages)
})

export default i18n
