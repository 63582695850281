<template>
  <div class="p-6 pt-14">
    <ValidationObserver
      ref="form"
      tag="form"
      v-slot="{ invalid }"
      autocomplete="off"
      @submit.prevent="formSubmit"
    >
      <p
        v-if="Object.keys(formData).length === 0"
        class="text-opal"
        v-html="$t('pages.template.translation_form.warning')"
      />

      <div v-else class="space-y-3">
        <Input
          v-model="formData[key].name"
          v-for="(item, key, i) in formData"
          :key="i"
          :label="$t(`pages.template.translations.${key}.label`)"
          :placeholder="$t(`pages.template.translations.${key}.placeholder`)"
          :name="`name-${i}`"
          :submitted="submitted"
          icon="trash"
          rules="required"
          :disabled="!template.metadata.security.edit"
          @iconClick="deleteTranslation(key)"
          @input="formChanged = true"
        />
      </div>

      <div class="mt-6 space-y-3">
        <Button
          pill
          upper
          type="submit"
          :loading="submitLoading"
          :disabled="invalid || !formChanged"
        >
          {{ $t('pages.template.translation_form.save') }}
        </Button>

        <Button
          v-if="template.metadata.security.edit && translations.length"
          pill
          bg="transparent"
          hover="snowball"
          text="void"
          @click="languageModal = true"
        >
          {{ $t('pages.template.translation_form.add_translation') }}
        </Button>
      </div>
    </ValidationObserver>

    <LanguageModal
      v-if="languageModal"
      :items="translations"
      @close="languageModal = false"
      @confirm="addTranslation"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import Input from '@/components/Input'
import LanguageModal from '@/components/modals/Language.modal'
import translations from '@/mixins/translations'
import { mapState } from 'vuex'

export default {
  name: 'EditTemplateLanguage',
  mixins: [translations],
  components: {
    Input,
    LanguageModal
  },
  data: () => ({
    loading: false,
    selectedLanguage: '',
    languageModal: false,
    formChanged: false,
    submitLoading: false,
    submitted: false,
    formData: {}
  }),
  computed: {
    ...mapState('Templates', ['template']),
    ...mapState('Settings', ['settings'])
  },
  created() {
    if (this.template) {
      this.formData = cloneDeep(this.template.translations)
    }
  },
  methods: {
    addTranslation(lang) {
      this.languageModal = false

      if (!this.formData[this.selectedLanguage]) {
        this.$set(this.formData, lang, {})
      } else {
        this.$toasted.error(this.$t('pages.template.already_exists'))
      }
    },
    deleteTranslation(key) {
      this.formChanged = true
      this.$delete(this.formData, key)
    },
    formSubmit() {
      this.submitted = true

      this.$refs.form.validate().then(success => {
        if (success && this.formChanged) {
          this.submitLoading = true

          this.$store
            .dispatch('Templates/fullEditTemplate', {
              id: this.$route.params.id,
              translations: this.formData
            })
            .then(() => {
              this.$store
                .dispatch('Templates/fetchTemplate', this.$route.params.id)
                .then(res => {
                  this.formData = cloneDeep(res.data.translations)
                  this.$toasted.success(
                    this.$t('pages.template.translation_form.update_success')
                  )
                })
            })
            .catch(() => {
              this.$toasted.error(this.$t('general.error_occurred'))
            })
            .finally(() => {
              this.submitted = false
              this.formChanged = false
              this.submitLoading = false
            })
        }
      })
    }
  }
}
</script>
