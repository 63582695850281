<template>
  <div
    class="max-h-screen w-80 transition-all duration-200"
    :class="{ closed: !open || !component }"
  >
    <CloseButton class="ml-6 mt-6" v-if="closable" @click="closePanel" />
    <component :is="component" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CloseButton from '@/components/CloseButton'
import AddUser from '@/components/panels/AddUser.panel'
import AddWorkArea from '@/components/panels/AddWorkArea.panel'
import AddTemplate from '@/components/panels/AddTemplate.panel'
import PlanningInfo from '@/components/panels/PlanningInfo.panel'
import UserStatistics from '@/components/panels/UserStatistics.panel'
import EditTemplateLanguages from '@/components/panels/EditTemplateLanguages.panel'
import AddFAQCategory from '@/components/panels/AddFAQCategory.panel'
import DatepickerFilter from '@/components/panels/DatepickerFilter.panel'

export default {
  name: 'RightPanel',
  components: {
    AddUser,
    CloseButton,
    AddWorkArea,
    AddTemplate,
    PlanningInfo,
    UserStatistics,
    EditTemplateLanguages,
    AddFAQCategory,
    DatepickerFilter
  },
  computed: {
    ...mapState('Panel', ['open', 'component', 'closable'])
  },
  methods: {
    ...mapActions('Panel', ['closePanel'])
  }
}
</script>

<style lang="scss" scoped>
.closed {
  margin-right: -20rem;
}
</style>
