var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.dropdown = false); }),expression:"() => (dropdown = false)"}],staticClass:"relative"},[_c('input',{ref:"searchInput",staticClass:"relative px-4 py-2 pl-10 z-40 rounded-full outline-none border-2 focus:border-eiffel transition-all duration-200 placeholder-dirt text-coal w-full md:w-60 xl:w-72",class:[
      _vm.bg,
      {
        'border-ice': _vm.bg === 'bg-ice',
        'border-snow': _vm.bg === 'bg-snow',
        'pr-12': _vm.clearable
      }
    ],attrs:{"type":"text","placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"focus":_vm.focus}}),_c(require('@/assets/search.svg'),{tag:"component",staticClass:"w-4 absolute top-0 bottom-0 ml-4 m-auto z-40"}),(_vm.value.length > 0 && _vm.clearable)?_c(require('@/assets/close-circle.svg'),{tag:"component",staticClass:"w-6 cursor-pointer h-6 text-dirt absolute top-0 bottom-0 right-4 ml-4 m-auto z-40 hover:text-void transition-all duration-100 ease-in-out transform hover:scale-105",on:{"click":_vm.clearInput}}):_vm._e(),(_vm.dropdown && _vm.items.length)?_c('ul',{staticClass:"dropdown overflow-y-auto absolute p-0 pt-6 -mt-4 rounded-b-lg bg-snow border border-snowball border-t-0 z-30 shadow-xl w-full md:w-60 xl:w-72"},[(_vm.items.length)?_c('div',_vm._l((_vm.items),function(item,i){return _c('li',{key:i,staticClass:"border-b border-ice px-3 py-2 cursor-pointer hover:bg-eiffel hover:bg-opacity-10",on:{"click":function($event){return _vm.itemClick(item)}}},[(item)?_c('p',[_vm._v(_vm._s(item[_vm.keyToShow]))]):_vm._e()])}),0):_c('li',{staticClass:"border-b border-ice px-3 py-2",on:{"click":function($event){_vm.dropdown = false}}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.no_options'))+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }