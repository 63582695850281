import i18n from '@/locales'

export const namespaced = true

export const state = {
  templates: [],
  template: null
}

export const mutations = {
  SET_TEMPLATES(state, templates) {
    state.templates = templates
  },
  SET_TEMPLATE(state, template) {
    state.template = template
  }
}

export const actions = {
  fetchTemplates({ commit }) {
    const { $http, $api } = this._vm
    return $http.get($api.templates.list).then(res => {
      const items = res.data.map(el => {
        if (el.translations) {
          const trans = el.translations[i18n.locale]
          el.name = trans ? trans.name : i18n.t('general.translation_not_found')
          delete el.translations
        }
        return el
      })

      commit('SET_TEMPLATES', items)
    })
  },
  fetchTemplate({ commit }, id) {
    const { $http, $api } = this._vm
    return $http.get($api.templates.single.replace('{id}', id)).then(res => {
      commit('SET_TEMPLATE', res.data)
      return res
    })
  },
  addNewTemplate(_, payload) {
    const { $http, $api } = this._vm
    return $http.post($api.templates.create, {
      translations: {
        [i18n.locale]: {
          name: payload
        }
      }
    })
  },
  fullEditTemplate(_, { id, translations }) {
    const { $http, $api } = this._vm
    return $http.put($api.templates.edit_full.replace('{id}', id), {
      translations
    })
  },
  deleteTemplate(_, id) {
    const { $http, $api } = this._vm
    return $http.delete($api.templates.delete.replace('{id}', id))
  },
  addShift(_, { id, shift }) {
    const { $http, $api } = this._vm
    return $http.post($api.templates.create_shift.replace('{id}', id), shift)
  },
  editShift(_, { id, shift }) {
    const { $http, $api } = this._vm
    return $http.patch(
      $api.templates.edit_shift
        .replace('{id}', id)
        .replace('{shift_id}', shift.id),
      shift
    )
  },
  deleteShift(_, { id, shiftId }) {
    const { $http, $api } = this._vm
    return $http.delete(
      $api.templates.delete_shift
        .replace('{id}', id)
        .replace('{shift_id}', shiftId)
    )
  }
}
