<template>
  <div class="flex flex-col justify-between overflow-y-auto h-full">
    <h1 class="title-1-bold sm:bold-title mb-6 text-center">
      {{ $t('login.title') }}
    </h1>

    <ValidationObserver
      tag="form"
      ref="form"
      class="flex flex-col flex-1 justify-between w-full mx-auto"
      v-slot="{ invalid }"
      @submit.prevent="formSubmit"
    >
      <div class="space-y-3">
        <Input
          v-model="formData.username"
          :label="$t('login.email.label')"
          :placeholder="$t('login.email.placeholder')"
          :submitted="submitted"
          rules="required|email"
          name="email"
        />

        <div>
          <Input
            v-model="formData.password"
            :label="$t('login.password.label')"
            :placeholder="$t('login.password.placeholder')"
            :submitted="submitted"
            rules="required"
            name="password"
            type="password"
          />

          <router-link
            :to="{ name: 'ForgotPassword' }"
            class="text-12 text-void underline block mt-2 ml-1"
          >
            {{ $t('login.forgot_password') }}
          </router-link>
        </div>

        <p v-if="error" class="text-opal">
          {{ error }}
        </p>
      </div>

      <div class="flex flex-col items-center mt-10 mb-3">
        <Button pill upper type="submit" :disabled="invalid" :loading="loading">
          {{ $t('login.submit') }}
        </Button>

        <router-link
          class="text-12 text-void mt-5 underline"
          :to="{ name: 'Registration.contractType' }"
        >
          {{ $t('login.no_account_register_here') }}
        </router-link>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Input from '@/components/Input'

export default {
  name: 'Login',
  components: {
    Input
  },
  data: () => ({
    loading: false,
    submitted: false,
    error: '',
    formData: {
      username: '',
      password: ''
    }
  }),
  created() {
    this.$store.dispatch('Auth/logout')
  },
  computed: {
    jwtData() {
      return this.$store.state.Auth.jwtData
    }
  },
  methods: {
    formSubmit() {
      this.submitted = true

      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading = true

          this.$http
            .post(this.$api.auth.login, this.formData)
            .then(res => {
              const { token, refreshToken } = res.data

              this.$store.dispatch('Auth/setJwtToken', token)
              this.$store.dispatch('Auth/setRefreshToken', refreshToken)

              if (this.jwtData.two_fa_in_progress) {
                this.$router.push({ name: 'TwoFA' })
              } else {
                this.$store
                  .dispatch('Auth/fetchMe')
                  .then(() => {
                    return this.$store.dispatch('Auth/fetchPermissions')
                  })
                  .then(permissions => {
                    if (permissions.planningShift.managePlanning) {
                      this.$router.push({ name: 'Planning' })
                    } else {
                      this.$router.push({ name: 'Dashboard' })
                    }
                  })
                  .finally(() => {
                    this.loading = false
                  })
              }
            })
            .catch(err => {
              if (err) {
                this.error = err.response.data.message
              }

              this.loading = false
            })
        }
      })
    }
  }
}
</script>
