<template>
  <div v-if="statistics" class="space-y-6">
    <!-- Planned hours previous month -->
    <div class="space-y-1">
      <p
        class="subhead text-mine"
        v-html="$t('pages.user.statistics.planned_hours_previous_month')"
      />
      <p class="footnote text-void">
        {{
          $tc(
            'pages.user.statistics.n_hours',
            statistics.plannedHoursPreviousMonth
          )
        }}
      </p>
    </div>

    <!-- Planned hours of this month -->
    <div class="space-y-1">
      <p
        class="subhead text-mine"
        v-html="$t('pages.user.statistics.planned_hours_of_this_month')"
      />
      <p class="footnote text-void">
        {{
          $tc('pages.user.statistics.n_hours', statistics.plannedHoursThisMonth)
        }}
      </p>
    </div>

    <!-- Planned hours of this year -->
    <div class="space-y-1">
      <p
        class="subhead text-mine"
        v-html="$t('pages.user.statistics.planned_hours_of_this_year')"
      />
      <p class="footnote text-void">
        {{
          $tc('pages.user.statistics.n_hours', statistics.plannedHoursThisYear)
        }}
      </p>
    </div>

    <!-- Canceled shifts of this year -->
    <div class="space-y-1">
      <p
        class="subhead text-mine"
        v-html="$t('pages.user.statistics.cancelled_shifts_of_this_year')"
      />
      <p class="footnote text-void">
        {{
          $tc(
            'pages.user.statistics.n_shifts',
            statistics.cancelledShiftsThisYear
          )
        }}
      </p>
    </div>

    <!-- Last activity -->
    <div class="space-y-1">
      <p class="subhead text-mine">
        {{ $t('pages.user.statistics.last_activity') }}
      </p>
      <p class="footnote text-void">
        <template v-if="statistics.lastActivity">
          {{ dayjs(statistics.lastActivity).format('HH:mm - DD/MM/YYYY') }}
        </template>

        <template v-else>
          {{ $t('pages.user.statistics.not_known') }}
        </template>
      </p>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'

export default {
  name: 'UserStatistics',
  computed: {
    ...mapState('Users', ['statistics'])
  },
  data: () => ({
    dayjs
  })
}
</script>
